export const validateLengthCnpj = (cnpj) =>
    cnpj.replace(/[^\d]+/g, "").length === 14;

export const formatCnpj = (value) => {
    return value
        .replace(/\D+/g, "") // não deixa ser digitado nenhuma letra
        .replace(/(\d{2})(\d)/, "$1.$2") // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2") // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1"); // captura os dois últimos 2 números, com um - antes dos dois números
};

export const validateCnpj = (c) => {
    var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

    if ((c = c.replace(/[^\d]/g, "")).length != 14) return false;

    if (/0{14}/.test(c)) return false;

    for (var j = 0; j < 2; ++j) {
        for (var i = 0, n = 0; i < 12 + j; n += c[i] * b[++i - j]);

        if (c[12 + j] != (n %= 11) < 2 ? 0 : 11 - n) return false;
    }

    return true;
};
