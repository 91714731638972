import styles from "./PacoteCard.module.scss";
import { Text } from "../../../ui/Text";
import P from "prop-types";
import { IconCheckSimple } from "../../../icons/IconCheckSimple";
import { IconX } from "../../../icons/IconX";

export const PacoteCard = ({
    active,
    installmentValue,
    totalValue,
    benefits,
    name,
    isAnual,
}) => {
    const textColor = "secondary";

    const formatValue = (value, mult) => {
        const number = parseFloat(value.replace(",", "."));
        const total = number * mult;
        return String(total.toFixed(2)).replace(".", ",");
    };

    return (
        <div
            className={`${styles["plan-card"]} ${active ? styles["plan-selected"] : ""}`}
        >
            <div className={styles["plan-card-header"]}>
                <Text
                    color={textColor}
                    tag="p"
                    type="text"
                    size="1"
                    className={styles["plan-name"]}
                >
                    {name}
                </Text>
                <div className={styles["plan-price"]}>
                    <Text color={textColor} tag="small" type="text" size="5">
                        {isAnual ? "6" : "12"}x<span>{installmentValue}</span>
                        /vida
                    </Text>
                    <Text color={textColor} tag="small" type="text" size="5">
                        Total anual: R$
                        {isAnual
                            ? formatValue(installmentValue, 6)
                            : formatValue(installmentValue, 12)}
                    </Text>
                </div>
            </div>
            <div className={styles["plan-details"]}>
                <Text
                    color={textColor}
                    tag="small"
                    type="text"
                    size="3"
                    className={styles["plan-funcionalidade-text"]}
                >
                    Funcionalidades base +
                </Text>
                <ul className="nav">
                    {benefits.map((benefit, index) => (
                        <li
                            key={index}
                            className={`nav-item ${benefit.available ? "" : styles["not-included"]}`}
                        >
                            {benefit.available ? (
                                <IconCheckSimple></IconCheckSimple>
                            ) : (
                                <IconX></IconX>
                            )}
                            <Text
                                tag="small"
                                size="5"
                                type="text"
                                color={textColor}
                            >
                                {benefit.name}
                            </Text>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

PacoteCard.propTypes = {
    active: P.bool,
    installmentValue: P.string,
    totalValue: P.number,
    benefits: P.arrayOf(
        P.shape({
            available: P.bool,
            name: P.string,
        }),
    ),
    name: P.string,
    isAnual: P.bool,
};
