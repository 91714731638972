import React from 'react';

import styles from './MiniLoader.module.scss';

export const MiniLoader = ({ 
    loading,
    bar = false
}) => {
    const styleContainerMiniLoader = [
        styles.container,
        bar ? styles.bar : '' 
    ].join(' ');
    
    const styleMiniLoader = [
        styles.loader,
        bar ? styles.bar : '' 
    ].join(' ');

    return (
        loading ? 
            <div className={styleContainerMiniLoader}>
                <div className={styleMiniLoader}></div> 
            </div>
        : null
    );
};
