import React from "react";
import SectionDivider from "../SectionDivider";
import { Text } from "../../ui";

function SectionRHDivider() {
    return (
        <SectionDivider>
            <Text style={{ maxWidth: "790px" }}>
                <strong>RH Inteligente:</strong> Um novo universo para a gestão
                de diferentes serviços em um só lugar.
            </Text>
            <Text style={{ marginTop: "32px" }}>
                <strong>
                    #Mais<span>Tecnologia</span>
                </strong>
            </Text>
            <Text>
                <strong>
                    #Mais<span>Humanizado</span>
                </strong>
            </Text>
            <Text>
                <strong>
                    #Mais<span>Completo</span>
                </strong>
            </Text>
            <Text>
                <strong>;)</strong>
            </Text>
        </SectionDivider>
    );
}

export default SectionRHDivider;
