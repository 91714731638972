import React from "react";
import styles from "./SectionFuncionalidades.module.scss";
import { Text } from "../../ui";

function Funcionalidade(props) {
    return (
        <li>
            <img src={props.icon} alt={"Icon " + props.key} />
            <Text tag="p" color="secondary" type="text">
                {props.children}
            </Text>
        </li>
    );
}

function SectionFuncionalidades() {
    const funcionalidades = [
        {
            id: 1,
            key: "Planos",
            icon: "/icons/icon-feats-01.svg",
            description:
                "Crie bases para cada nível profissional e gerencie diferentes planos;",
        },
        {
            id: 2,
            key: "Usuários",
            icon: "/icons/icon-feats-02.svg",
            description: "Acompanhe os usuários ativos;",
        },
        {
            id: 3,
            key: "Índices",
            icon: "/icons/icon-feats-03.svg",
            description: "Analise os índices de utilização;",
        },
        {
            id: 4,
            key: "Licenças",
            icon: "/icons/icon-feats-04.svg",
            description:
                "Realize upgrades de novos serviços às licenças contratadas.",
        },
    ];

    return (
        <section className={styles["section-funcionalidades"]}>
            <img src="images/img-notebook.png" alt="Demonstração do Sistema" />
            <div className={styles["section-funcionalidades__container"]}>
                <div
                    className={
                        styles["section-funcionalidades__container__text"]
                    }
                >
                    <Text
                        color="secondary"
                        className={
                            styles[
                                "section-funcionalidades__container__text__resultado"
                            ]
                        }
                    >
                        O bem-estar do seu time é <span>resultado</span> pro seu
                        negócio.
                    </Text>
                    <Text
                        color="secondary"
                        className={
                            styles[
                                "section-funcionalidades__container__text__pratica"
                            ]
                        }
                    >
                        Na prática, a teoria é a mesma:
                    </Text>
                </div>
                <ul
                    className={
                        styles["section-funcionalidades__container__list"]
                    }
                >
                    {funcionalidades.map((funcionalidade) => (
                        <React.Fragment key={funcionalidade.id}>
                            <Funcionalidade
                                id={funcionalidade.id}
                                icon={funcionalidade.icon}
                            >
                                {funcionalidade.description}
                            </Funcionalidade>
                        </React.Fragment>
                    ))}
                </ul>
            </div>
        </section>
    );
}

export default SectionFuncionalidades;
