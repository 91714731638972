import styles from "./SectionNossosParceiros.module.scss";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Heading } from "../../ui/Heading";

export const SectionNossosParceiros = () => {
    const slides = [
        {
            id: 1,
            image: "/images/img-tempo.png",
            className: "section-nossos-parceiros--container__img-tempo",
        },
        {
            id: 2,
            image: "/images/img-gympass.png",
            className: "section-nossos-parceiros--container__img-gympass",
        },
        {
            id: 3,
            image: "/images/img-drogasil.png",
            className: "section-nossos-parceiros--container__img-drogasil",
        },
        {
            id: 4,
            image: "/images/img-droga-raia.png",
            className: "section-nossos-parceiros--container__img-droga-raia",
        },
        {
            id: 5,
            image: "/images/img-labi-exames.png",
            className: "section-nossos-parceiros--container__img-labi-exames",
        },
        {
            id: 6,
            image: "/images/img-queima-diaria.png",
            className: "section-nossos-parceiros--container__img-queima-diaria",
        },
    ];

    return (
        <section className={`${styles["section-nossos-parceiros"]} container`}>
            <div
                className={
                    styles["section-nossos-parceiros--container-heading"]
                }
            >
                <Heading tag="h4" color="secondary">
                    Nossos parceiros:
                </Heading>
            </div>

            <div className={styles["section-nossos-parceiros--container"]}>
                {slides.map((slide) => (
                    <img
                        key={slide.id}
                        src={slide.image}
                        className={styles[slide.className]}
                    />
                ))}
            </div>
        </section>
    );
};
