import styles from "./SectionBaixeApp.module.scss";
import { Heading } from "../../ui/Heading";
import { Text } from "../../ui/Text";
import { Divider } from "../../ui/Divider";
import { appData } from "./data";

export const SectionBaixeApp = () => (
    <section className={`${styles["section-baixe-app"]} container`}>
        <Heading
            tag="h3"
            color="secondary"
            className={
                styles["section-oque-oferecemos_container-title_heading"]
            }
        >
            Baixe o app e comece a usar:
        </Heading>
        <div className={`${styles["container-app-options"]} container-fluid`}>
            {appData.map((data, index) => (
                <div
                    className={styles["section-baixe_app-options"]}
                    key={index}
                >
                    {data.icon}
                    <div>
                        <Heading
                            tag="h6"
                            color="secondary"
                            className={`${styles["section-gerencionamento--container__content_sub-title_heading"]} m-0`}
                        >
                            {data.title}
                        </Heading>
                        <Text
                            tag="p"
                            size="1"
                            type="text"
                            color="secondary"
                            className="m-0"
                        >
                            {data.description}
                        </Text>
                    </div>
                </div>
            ))}
        </div>
        <Divider></Divider>
    </section>
);
