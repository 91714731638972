import styles from "./Profissionais.module.scss";

import {
    SectionMain,
    SectionConecteSe,
    SectionTecnologia,
    SectionFacaParte,
} from "../../components/profissionais";

export const Profissionais = ({
    origin
}) => {
    return (
        <main className={styles.container_main_profissionais}>
            <SectionMain />
            <SectionConecteSe />
            <SectionTecnologia />
            <SectionFacaParte origin={origin}  />
        </main>
    );
};

export default Profissionais;
