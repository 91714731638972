import styles from "./FigureImageCapa.module.scss";
import P from "prop-types";

export const FigureImageCapa = ({ className }) => (
    <figure className={`${styles["figure__image-capa"]} ${className}`}>
        <img
            alt="doutor123"
            src="/images/saude-pra-voce/img-section-main.png"
        ></img>
    </figure>
);

FigureImageCapa.defaultProps = {
    className: "",
};

FigureImageCapa.propTypes = {
    className: P.string,
};
