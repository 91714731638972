export const data = [
    {
        name: "Orientação 24h",
        installmentValue: "7,69",
        active: false,
        benefits: [
            {
                available: true,
                name: "Acesso à orientação de saúde 24h pelo WhatsApp",
            },
            {
                available: false,
                name: "Pronto atendimento virtual ilimitado",
            },
            {
                available: false,
                name: "1 consulta com médico de família",
            },
            {
                available: false,
                name: "Acompanhamento contínuo",
            },
        ],
    },
    {
        name: "Pronto atendimento",
        installmentValue: "11,90",
        active: true,
        benefits: [
            {
                available: true,
                name: "Acesso à orientação de saúde 24h pelo WhatsApp",
            },
            {
                available: true,
                name: "Pronto atendimento virtual ilimitado",
            },
            {
                available: false,
                name: "1 consulta com médico de família",
            },
            {
                available: false,
                name: "Acompanhamento contínuo",
            },
        ],
    },
    {
        name: "Médico de família",
        installmentValue: "23,26",
        active: false,
        benefits: [
            {
                available: true,
                name: "Acesso à orientação de saúde 24h pelo WhatsApp",
            },
            {
                available: false,
                name: "Pronto atendimento virtual ilimitado",
            },
            {
                available: true,
                name: "1 consulta com médico de família",
            },
            {
                available: false,
                name: "Acompanhamento contínuo",
            },
        ],
    },
    {
        name: "Cuidado contínuo",
        installmentValue: "54,55",
        active: false,
        benefits: [
            {
                available: true,
                name: "Acesso à orientação de saúde 24h pelo WhatsApp",
            },
            {
                available: true,
                name: "Pronto atendimento virtual ilimitado",
            },
            {
                available: true,
                name: "1 consulta com médico de família",
            },
            {
                available: true,
                name: "Acompanhamento contínuo: teleconsultas ilimitadas com médico de família para monitorar e coordenar sua saúde",
            },
            {
                available: true,
                name: "Equipe de saúde dedicada",
            },
            {
                available: true,
                name: "Telemonitoramento de sintomas, sinais e exames",
            },
        ],
    },
];
