import React from "react";
import { Carousel } from "react-bootstrap";

import styles from "./SectionRecomendacoes.module.scss";
import { SlideRecomendacao } from "./SlideRecomendacao";

function SectionRecomendacoes() {
    const recommendations = [
        {
            id: 1,
            icon: "icons/icon-chat-heart.svg",
            text: "A parceria com a Doutor123 é de grande valia para nós, gerando resultados sólidos e duradouros. Atendemos milhares de empresas de todos os portes e os serviços do Doutor123 nos ajudam a difundir e a democratizar o acesso à rede privada de saúde junto aos nossos clientes, levando tranquilidade e acolhimento para colaboradores em todo o território nacional. ",
            client: {
                name: "Claudio Amadio",
                occupation: "",
                company: "VB",
            },
        },
        {
            id: 2,
            icon: "icons/icon-chat-heart.svg",
            text: "O Doutor123 nos permite a tranquilidade de que nossos colaboradores e seus familiares estarão bem assistidos, tanto com o apoio necessário para momentos delicados de saúde como também na prevenção, tudo isso ainda aliado com a qualidade da rede disponível e baixo custo",
            client: {
                name: "Luciane Silva",
                occupation: "",
                company: "Avivatec",
            },
        },
        {
            id: 3,
            icon: "icons/icon-chat-heart.svg",
            text: "Nossos colaboradores se sentem seguros em poder contar com os serviços da Dr123, isso porque sabem que terão um atendimento personalizado e de ótimos profissionais. Além disso, a rapidez com que as necessidades são atendidas é algo que os diferencia das demais plataformas.",
            client: {
                name: "Giovanna Lyra",
                occupation: "",
                company: "Money Money",
            },
        },
        {
            id: 4,
            icon: "icons/icon-chat-heart.svg",
            text: "A parceria entre Sem Parar e Doutor123, além de agregar valor ao produto de pagamento automático de pedágio, trouxe uma nova visão de preocupação com a saúde do motorista de frota que fica desassistido nas viagens pelo Brasil. Além disso também tem grande valor para o pequeno empresário, que eventualmente não possui condição de ter e fornecer um plano de saúde para seus funcionários.",
            client: {
                name: "Fábio Garbin",
                occupation: "",
                company: "Sem Parar",
            },
        },
    ];
    return (
        <section className={styles["section-recomendacoes"]}>
            <Carousel
                indicators={false}
                nextIcon={<img src="icons/icon-arrow-right.svg" alt="Voltar" />}
                prevIcon={<img src="icons/icon-arrow-left.svg" alt="Avançar" />}
            >
                {recommendations.map((recommendation) => {
                    return (
                        <Carousel.Item key={recommendation.id}>
                            <SlideRecomendacao {...recommendation} />
                        </Carousel.Item>
                    );
                })}
            </Carousel>
        </section>
    );
}

export default SectionRecomendacoes;
