import styles from "./Icons.module.scss";

export const IconCheckSimple = () => (
    <i className={styles["icons-"]}>
        <svg
            width="10"
            height="9"
            viewBox="0 0 10 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.80667 8.84424C3.50245 8.84424 3.21432 8.72823 3.0297 8.52821L0.530939 5.83024C0.453684 5.74692 0.397284 5.65198 0.364961 5.55083C0.332639 5.44969 0.325028 5.34433 0.342564 5.24078C0.360101 5.13723 0.40244 5.03752 0.467162 4.94736C0.531884 4.85719 0.61772 4.77833 0.719762 4.71528C0.821767 4.65197 0.938058 4.60574 1.06197 4.57923C1.18588 4.55272 1.31498 4.54645 1.44186 4.56078C1.56875 4.57512 1.69092 4.60977 1.80138 4.66276C1.91184 4.71575 2.00842 4.78603 2.08558 4.86958L3.72974 6.64346L7.86357 1.21896C8.00066 1.03985 8.21909 0.912496 8.47095 0.864822C8.7228 0.817147 8.98751 0.853047 9.20701 0.964646C9.66368 1.19667 9.80425 1.68814 9.51892 2.06189L4.63469 8.4682C4.55132 8.57805 4.43639 8.66962 4.2999 8.73495C4.1634 8.80028 4.00946 8.8374 3.85143 8.8431C3.83604 8.84424 3.82205 8.84424 3.80667 8.84424Z"
                fill="#4C2450"
            />
        </svg>
    </i>
);
