import styles from "./SectionOhQueOferecemos.module.scss";

import { Heading } from "../../ui/Heading";
import { Text } from "../../ui/Text";
import { IconCheck } from "../../icons/IconCheck";
import { FigureIconServicoSaude } from "../../ui/Figures/FigureIconServicoSaude";
import { FigureIconSaudeFisica } from "../../ui/Figures/FigureIconSaudeFisica";
import { FigureIconMental } from "../../ui/Figures/FigureIconMental";

export const SectionOhQueOferecemos = () => (
    <section className={`${styles["section-oque-oferecemos"]} container`}>
        <div className={styles["container-section-oque-oferecemos"]}>
            <div
                className={styles["container-section-oque-oferecemos__content"]}
            >
                <div
                    className={
                        styles["section-oque-oferecemos_container-title"]
                    }
                >
                    <Heading
                        tag="h2"
                        color="secondary"
                        className={
                            styles[
                                "section-oque-oferecemos_container-title_heading"
                            ]
                        }
                    >
                        <span>{">>> "}</span>O que oferecemos?
                    </Heading>
                </div>

                <div
                    className={
                        styles["section-oque-oferecemos_container-sub-title"]
                    }
                >
                    <Text
                        tag="p"
                        size="1"
                        type="text"
                        color="secondary"
                        className={
                            styles[
                                "section-oque-oferecemos_container-sub-title_heading"
                            ]
                        }
                    >
                        O que você pode incluir na sua assinatura? <br />{" "}
                        <b>
                            Funcionalidades e benefícios que fazem toda a
                            diferença:
                        </b>
                    </Text>
                </div>

                <div
                    className={
                        styles[
                            "section-oque-oferecemos_container-lista-checking"
                        ]
                    }
                >
                    <div
                        className={
                            styles[
                                "section-oque-oferecemos_container-lista-checking__content"
                            ]
                        }
                    >
                        <IconCheck></IconCheck>
                        <Text
                            tag="p"
                            size="1"
                            type="text"
                            color="secondary"
                            className="m-0"
                        >
                            Inteligência artificial para análise de sintomas;
                        </Text>
                    </div>
                    <div
                        className={
                            styles[
                                "section-oque-oferecemos_container-lista-checking__content"
                            ]
                        }
                    >
                        <IconCheck></IconCheck>
                        <Text
                            tag="p"
                            size="1"
                            type="text"
                            color="secondary"
                            className="m-0"
                        >
                            Rede com <b>mais de 1200 pontos de atendimento </b>
                            para especialidades por vídeo ou presencial com
                            preços exclusivos;
                        </Text>
                    </div>
                    <div
                        className={
                            styles[
                                "section-oque-oferecemos_container-lista-checking__content"
                            ]
                        }
                    >
                        <IconCheck></IconCheck>
                        <Text
                            tag="p"
                            size="1"
                            type="text"
                            color="secondary"
                            className="m-0"
                        >
                            <b>Descontos</b> na compra de medicamentos tarjados.
                        </Text>
                    </div>
                </div>
            </div>

            <figure className={styles["section-oque-oferecemos_figure"]}>
                <img
                    alt="resultados doutor123"
                    src="/images/img-resultados.png"
                ></img>
            </figure>
        </div>

        <div className={styles["container-cards_section-oque-oferecemos"]}>
            <div
                className={
                    styles["container-cards_section-oque-oferecemos__card"]
                }
            >
                <div className="col-lg-6 col-12">
                    <FigureIconServicoSaude column={false}>
                        Serviços de saúde:
                    </FigureIconServicoSaude>
                </div>
                <div className="col-lg-6 col-12">
                    <ul
                        className={
                            styles[
                                "container-cards_section-oque-oferecemos__card--ul-support-purple"
                            ]
                        }
                    >
                        <li>
                            <Text
                                tag="small"
                                size="2"
                                type="text"
                                color="secondary"
                            >
                                Orientação de saúde 24h;
                            </Text>
                        </li>
                        <li>
                            <Text
                                tag="small"
                                size="2"
                                type="text"
                                color="secondary"
                            >
                                Pronto atendimento médico virtual ilimitado;
                            </Text>
                        </li>
                        <li>
                            <Text
                                tag="small"
                                size="2"
                                type="text"
                                color="secondary"
                            >
                                Linhas de cuidado e tele-monitoramento de
                                pacientes;
                            </Text>
                        </li>
                    </ul>
                </div>
            </div>

            <div
                className={
                    styles["container-cards_section-oque-oferecemos__card"]
                }
            >
                <div className="col-lg-6 col-12">
                    <FigureIconSaudeFisica column={false}>
                        Atividade física e bem-estar:
                    </FigureIconSaudeFisica>
                </div>
                <div className="col-lg-6 col-12">
                    <ul
                        className={
                            styles[
                                "container-cards_section-oque-oferecemos__card--ul-secondary"
                            ]
                        }
                    >
                        <li>
                            <Text
                                tag="small"
                                size="2"
                                type="text"
                                color="secondary"
                            >
                                Assinatura de serviços de acesso à academias;
                            </Text>
                        </li>
                        <li>
                            <Text
                                tag="small"
                                size="2"
                                type="text"
                                color="secondary"
                            >
                                Assinatura de plataforma de treino online;
                            </Text>
                        </li>
                        <li>
                            <Text
                                tag="small"
                                size="2"
                                type="text"
                                color="secondary"
                            >
                                Rede de nutricionistas com preços exclusivos;
                            </Text>
                        </li>
                    </ul>
                </div>
            </div>

            <div
                className={
                    styles["container-cards_section-oque-oferecemos__card"]
                }
            >
                <div className="col-lg-6 col-12">
                    <FigureIconMental column={false}>
                        Saúde mental:
                    </FigureIconMental>
                </div>
                <div className="col-lg-6 col-12">
                    <ul
                        className={
                            styles[
                                "container-cards_section-oque-oferecemos__card--ul-primary"
                            ]
                        }
                    >
                        <li>
                            <Text
                                tag="small"
                                size="2"
                                type="text"
                                color="primary"
                            >
                                Rede de psicólogos com preços exclusivos;
                            </Text>
                        </li>
                        <li>
                            <Text
                                tag="small"
                                size="2"
                                type="text"
                                color="primary"
                            >
                                Pacotes de consultas para acompanhamento;
                            </Text>
                        </li>
                        <li>
                            <Text
                                tag="small"
                                size="2"
                                type="text"
                                color="primary"
                            >
                                Assinatura de programas de saúde mental com
                                parceiros especializados no mundo corporativo;
                            </Text>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
);
