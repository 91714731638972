import styled from "./Institutional.module.scss";

export function Politics() {
    return (
        <div className={styled["container"]}>
            <main className={styled["content"]}>
                <h1>Política de privacidade</h1>

                <h2>SEJA BEM-VINDO(A) AO DOUTOR123!</h2>

                <p>
                    Esta Política de Privacidade explica como o Doutor123
                    realiza a coleta, armazenamento e tratamento de determinados
                    Dados Pessoais que você poderá compatilhar conosco quando
                    utiliza a Plataforma. A proteção de dados e privacidade dos
                    Usuários, bem como a transparência são fundamentos de nossa
                    operação. Recomendamos a leitura da Política de Privacidade
                    antes de iniciar o uso da Plataforma.
                </p>
                <p>
                    O ACEITE DA PRESENTE POLÍTICA DE PRIVACIDADE É ABSOLUTAMENTE
                    INDISPENSÁVEL PARA UTILIZAÇÃO DA PLATAFORMA E DEVE SER LIDA
                    EM CONJUNTO COM OS TERMOS CONDIÇÕES DE USO DA PLATAFORMA.
                </p>

                <h2>Premissas</h2>
                <p>
                    Os seus dados são seus. Respeitamos a sua privacidade e
                    tratamos os seus dados como parte da nossa missão de
                    proporcionar um serviço cada vez melhor. A presente Política
                    de Privacidade descreve como coletamos, armazenamos,
                    tratamos e compartilhamos as suas informações.
                </p>
                <p>
                    A proteção dos seus dados e da sua privacidade são muito
                    importantes para o Doutor123. Recomendamos que você conheça
                    as nossas práticas como um todo, mas aqui estão alguns
                    pontos importantes para que você entenda como seus dados são
                    tratados no Doutor123:
                </p>
                <div>
                    <ul>
                        <li>
                            <p>
                                os dados que você cadastra em nossas plataformas
                                são tratados de forma segura e limitamos o
                                tratamento para suas devidas finalidades.
                            </p>
                        </li>
                        <li>
                            <p>
                                - informações relacionadas à pagamentos
                                incluindo, sem limitação, dados bancários e
                                cartão de crédito, as quais somente serão
                                armazenadas de forma mascarada pelo provedor de
                                meio de pagamento e/ou Doutor123, conforme o
                                caso.
                            </p>
                        </li>
                        <li>
                            <p>
                                - você pode alterar suas preferências de
                                comunicação no próprio aplicativo, mas ainda
                                poderemos contatá-lo para informações sobre sua
                                conta de Usuário e/ou eventuais Serviços de
                                Saúde contratados.
                            </p>
                        </li>
                        <li>
                            <p>
                                - você poderá tirar suas dúvidas e solicitar
                                informações a qualquer momento através dos
                                canais de atendimento indicados em nossa
                                Plataforma.
                            </p>
                        </li>
                    </ul>
                </div>

                <p>
                    Nós não controlamos, aprovamos ou damos quaisquer garantias
                    a respeito das práticas de privacidade de terceiros, as
                    quais podem ser diferentes das nossas. Nesse sentido, nós
                    incentivamos que você leia com atenção as políticas de
                    privacidade de terceiros com os quais você interaja antes de
                    permitir a colta e o uso de seus Dados Pessoais.
                </p>
                <p>
                    Esta Política de Privacidade não se aplica à sua relação
                    direta com seu médico, hospital, plano de saúde, outros
                    prestadores de serviço de saúde ou outras farmácias
                    constantes da nossa Plataforma online de venda de
                    medicamentos.
                </p>
                <p>
                    Essa Política de Privacidade se aplica a todos os nossos
                    negócios e serviços. Contudo, outros Avisos de Privacidade
                    podem também se aplicar de forma específica a determinados
                    serviços. Em caso de aparente conflito entre esta Política e
                    qualquer dos Avisos de Privacidade, pedimos que você
                    considere o que dispõe no Aviso de Privacidade específico do
                    serviço que você estiver utilizando Os termos iniciados em
                    letra maiúscula, estejam no singular ou no plural, têm o
                    significado atribuídos a eles na presente Política de
                    Privacidade ou no item “7.7. Termos Definidos” dos Termos e
                    Condições de Uso, conforme o caso.
                </p>

                <h2>1 A quem se aplica esta Política de Privacidade?</h2>
                <p>
                    Esta Política de Privacidade é aplicável a todas entidades
                    que acessarem ou se cadastrarem em nossa Plataforma, ou que
                    façam uso de quaisquer serviços oferecidos pelo Doutor123
                    incluindo, sem limitação, os Usuários e parceiros da Rede
                    Credenciada.
                </p>

                <h2>
                    2 Quais Dados são Coletados quando você Utiliza a
                    Plataforma?
                </h2>
                <p>
                    Nesta seção explicamos com mais detalhes os Dados Pessoais
                    coletados pelo Doutor123 quando você utiliza a Plataforma.
                </p>
                <div>
                    <h3>2.1 Dados que você nos Fornece</h3>
                    <ul>
                        <li>
                            <p>
                                - Dados do seu perfil Incluem os seus Dados
                                Pessoais necessários para criar a sua conta de
                                Usuário na Plataforma incluindo seu nome, CPF,
                                e-mail, número de telefone e outras informações
                                que possam vir a serem solicitadas de acordo com
                                os Termos e Condições de Uso.Tais Dados Pessoais
                                poderão ser coletados mediante o preenchimento
                                de formulários ou campos de informação pelos
                                Usuários quando utilizada a Plataforma, ou
                                quando entrarem em contato com o Doutor123 por
                                quaisquer meios.{" "}
                            </p>
                        </li>
                        <li>
                            <p>
                                - Dados de pagamento Ao realizar a compra de um
                                Serviço de Saúde através da Plataforma do
                                Doutor123, você poderá nos fornecer dados de sua
                                titularidade necessárias para processar o
                                pagamento como, por exemplo, do seu cartão de
                                crédito e de meios de pagamento parceiros.Os
                                seus Dados Pessoais utilizados para processar
                                compras efetuadas por meio da Plataforma são
                                armazenadas somente de forma criptografada, de
                                modo que não temos acesso aos seus dados
                                financeiros completos.
                            </p>
                        </li>
                        <li>
                            <p>
                                -Dados de localização Para viabilizar a oferta
                                de Serviços de Saúde próximo a você, nós
                                precisamos que você nos informe a sua
                                localização.Essa localização pode ser fornecida
                                pelo endereço que você inserir manualmente na
                                Plataforma, ou através da localização obtida do
                                seu dispositivo via GPS ou rede móveis, desde
                                que confirmadas por você.Para fins da Legislação
                                de Proteção de Dados, a localização fornecida
                                será considerada como dado cadastral.{" "}
                            </p>
                        </li>

                        <p>
                            Essa localização pode ser fornecida pelo endereço
                            que você inserir manualmente na Plataforma, ou
                            através da localização obtida do seu dispositivo via
                            GPS ou rede móveis, desde que confirmadas por você.
                        </p>

                        <p>
                            Para fins da Legislação de Proteção de Dados, a
                            localização fornecida será considerada como dado
                            cadastral.
                        </p>
                    </ul>

                    <h3>
                        2.2 Dados Gerados Durante a Utilização da Plataforma
                    </h3>
                    <p>
                        Embora não sejam utilizados para identificação dos
                        Usuários, o Doutor123 poderá coletar os seguintes dados
                        dos Usuários em decorrência da utilização da Plataforma:
                    </p>

                    <ul>
                        <li>
                            Dados do seu dispositivo Nós podemos coletar
                            informações automaticamente sobre os dispositivos a
                            partir dos quais você acessa a Plataforma como:
                            endereços IP, tipo de navegador e idioma, provedor
                            de Serviços de Internet (ISP), páginas de consulta e
                            saída, sistema operacional, informações sobre data e
                            horário, dados sobre a sequência de cliques,
                            fabricante do dispositivo, operadora, modelo, versão
                            do aparelho, versão do aplicativo, versão do sistema
                            operacional, identificador de publicidade do
                            aparelho (IDFA), informações de acessibilidade do
                            aparelho e redes Wi-Fi.
                        </li>
                        <li>
                            Dados transacionais e dados sobre sua utilização
                            Coletamos dados sobre suas interações em nossa
                            Plataforma, incluindo data e horário de acessos,
                            buscas e visualizações na Plataforma. Também podemos
                            coletar dados transacionais relacionados aos usos
                            dos nossos serviços como detalhes do Serviço de
                            Saúde, data e hora do Serviço de Saúde contratado,
                            valor cobrado, distância entre o estabelecimento do
                            prestador da Rede Credenciada e o Usuário e métodos
                            de pagamento.Os eventuais dados coletados pelo
                            Doutor123 nos termos acima serão utilizados para as
                            finalidades descritas na seção 3 abaixo, bem como
                            para detectar, evitar ou remediar violações à
                            presente Política de Privacidade ou aos Termos e
                            Condições de Uso; aprimorar os serviços oferecidos
                            pelo Doutor123 por meio da personalização de sua
                            experiência de Usuário; medir o desempenho dos
                            serviços prestados pelo Doutor123 e aprimorar seu
                            conteúdo e layout; e gerir e proteger a
                            infraestrutura de tecnologia da informação de
                            propriedade do Doutor123.
                        </li>
                        <li>
                            Dados de comunicação Intermediamos a comunicação
                            entre os Usuários, parceiros da Rede Credenciada e
                            profissionais que prestam os Serviços de Saúde
                            através da nossa Plataforma. Para prestar este
                            serviço, o Doutor123 pode receber dados relativos a
                            chamadas, textos ou outras comunicações, incluindo a
                            data e hora das comunicações e o conteúdo das
                            comunicações realizadas visando a intermediação dos
                            Serviços de Saúde para os Usuários.
                        </li>
                    </ul>

                    <h3>2.3 Informações Não-Pessoais</h3>
                    <p>
                        Nós também podemos obter informações não-pessoais, ou
                        seja, dados que não permitem a associação direta com
                        qualquer pessoa especificamente. São exemplos de dados
                        não-pessoais o agrupamento de pedidos por região.
                    </p>
                </div>

                <h2>3 Como Utilizamos os seus Dados?</h2>
                <p>
                    Esta seção descreve as finalidades associadas à utilização
                    dos seus dados coletados conforme explicado na Seção 2
                    acima.
                </p>

                <div>
                    <h3>3.1 Prover os Serviços Oferecidos pelo Doutor123</h3>
                    <p>
                        Podemos utilizar os seus dados coletados na forma
                        descrita acima para prover, manter, melhorar e
                        personalizar nossos produtos e serviços destinados a
                        vocês, existente ou a serem desenvolvidos.
                    </p>
                    <p>
                        Neste contexto, o tratamento dos seus inclui as
                        seguintes finalidades: criar e atualizar a sua conta de
                        Usuário; viabilizar a contratação e realização dos
                        Serviços de Saúde por meio da Plataforma; personalizar a
                        sua conta de Usuário; e manter as operações da
                        Plataforma.
                    </p>
                    <p>
                        Também poderemos utilizar os seus Dados Pessoais para
                        fins internos do Doutor123, incluindo auditorias,
                        análises de dados e pesquisas para aprimorar nossos
                        produtos, serviços e comunicações, bem como para gerar
                        análises estatísticas quanto à utilização dos nossos
                        serviços (e.g., tendências de consumo).
                    </p>

                    <h3>3.2 Avaliar os parceiros da Rede Credenciada</h3>
                    <p>
                        Ao nos enviar comentários e feedback sobre a Plataforma,
                        determinados Serviços de Saúde ou parceiro da nossa Rede
                        Credenciada, você nos autoriza a publicar e utilizar
                        tais comentários e feedback na Plataforma, bem como
                        analisar, processar e tratar esses dados de forma
                        isolada ou agregada. Nestes casos, apenas
                        identificaremos vocês através do seu nome completo
                        cadastrado na sua conta de Usuário.
                    </p>

                    <h3>3.3 Comunicação Não-Comercial</h3>
                    <p>
                        Periodicamente, podemos usar os seus Dados Pessoais para
                        enviar avisos e notificações importantes, como
                        comunicados sobre compras, alterações de agenda,
                        condições e políticas. Como estas informações são
                        essenciais para garantir uma melhor experiência de
                        utilização da Plataforma, não recomendamos que você opte
                        por não receber este tipo de comunicação, justamente por
                        serem inerentes à sua relação com o Doutor123.
                    </p>

                    <h3>3.4 Suporte</h3>
                    <p>
                        Nós poderemos acessar as suas informações e/ou Dados
                        Pessoais quando você solicitar suporte à nossa equipe de
                        atendimento para resolver problemas com Serviços de
                        Saúde ou uso geral da Plataforma.
                    </p>
                    <p>
                        Nestes casos, poderemos utilizar determinadas
                        informações como, por exemplo, os seus dados cadastrais,
                        para: certificar que você é o Usuário que formulou a
                        solicitação de suporte; direcionar suas dúvidas para o
                        profissional responsável da nossa equipe de atendimento;
                        investigar e direcionar o seu problema; e monitorar e
                        melhorar nossos procedimentos de suporte.
                    </p>

                    <h3>3.5 Gestão de Pagamentos Online</h3>
                    <p>
                        A segurança e privacidade de suas informações são
                        pilares de nossa operação. Para viabilizar uma
                        experiência diferenciada e mais segura, o Doutor123
                        integra à Plataforma solução de pagamento desenvolvidas
                        pela “Pagar.ME”, responsável pela captura, transmissão e
                        roteamento de transações com meio de pagamento, bem como
                        liquidação financeira do valor relacionado à contratação
                        dos Serviços de Saúde por meio da Plataforma.
                    </p>
                    <p>
                        Neste sentido, os seus dados financeiros pessoais
                        somente serão tratados para a finalidade específica de
                        permitir a compra de Serviços de Saúde por meio da
                        Plataforma. E não se preocupe: qualquer eventual
                        tratamento de seus Dados Pessoais financeiros pelo
                        Doutor123 será sempre realizado em observância à
                        Legislação de Proteção de Dados e demais leis
                        aplicáveis. Se você tiver quaisquer dúvidas sobre a
                        forma como a Pagar.ME realiza a coleta e tratamento de
                        seus Dados Pessoais, recomendamos a leitura de sua
                        Política de Privacidade disponível na rede mundial de
                        computadores através do https://pagar.me/.
                    </p>

                    <h3>3.6 Segurança e Prevenção de Fraudes</h3>
                    <p>
                        Nós também poderemos utilizar os seus Dados Pessoais
                        para fortalecer e melhorar o sistema de segurança da
                        Plataforma. Dessa forma, continuamente atuamos na
                        análise e solução de eventuais falhas e erros para
                        identificar e coibir cenários de fraude na utilização da
                        Plataforma.
                    </p>

                    <h3>3.7 Marketing</h3>
                    <p>
                        O Doutor123 pode processar os seus Dados Pessoas para
                        enviar publicidades e outros materiais promocionais de
                        marketing de nossos serviços e novidades aos seus
                        cuidados, incluindo marketing direcionado em redes
                        sociais e notificações push.
                    </p>
                    <p>
                        Você poderá optar por não receber mais notificações de
                        marketing a qualquer momento, alterando as suas
                        preferências por meio da Plataforma.
                    </p>
                    <p>
                        Neste contexto, esclarecemos que poderemos compartilhar
                        com parceiros do Doutor123 determinadas informações
                        estritamente conforme necessário para o desenvolvimento
                        de campanhas de marketing mais relevante para
                        interessados em nossos serviços como, por exemplo,
                        telefone e e-mail. O Doutor123 somente compartilhará
                        Dados Pessoais com parceiros que possuírem “política de
                        privacidade” que assegure níveis de segurança
                        compatíveis com os termos da presente Política de
                        Privacidade.
                    </p>

                    <h3>3.8 Promoções</h3>
                    <p>
                        Se você participar de um concurso, competição ou
                        qualquer outra modalidade de promoção, poderemos usar as
                        informações que você fornecer para administrar esses
                        programas.
                    </p>

                    <h3>3.9 Pesquisas Diversas</h3>
                    <p>
                        Podemos utilizar seus dados para entrar em contato
                        diretamente com você a fim de coletar feedbacks
                        importantes para aprimoramento de nossos serviços. Nesta
                        hipótese, você será questionado se deseja participar da
                        pesquisa em questão, sempre podendo se recusar a nos
                        ajudar em nossa melhoria diária.
                    </p>

                    <h3>3.10 Requisições Legais e Regulatórias</h3>
                    <p>
                        Por fim, os seus Dados Pessoais poderão ser utilizados
                        para atender determinações jurídica ou regulatórias
                        formuladas por autoridades competentes e/ou reconhecidas
                        no Brasil, relacionadas aos nossos serviços.
                    </p>
                </div>

                <h2>4 Como os Dados são Armazenados?</h2>
                <p>
                    Nesta seção trazemos mais detalhes sobre onde e por quanto
                    tempo armazenamos os seus Dados Pessoais.
                </p>

                <div>
                    <h3>4.1 Onde os seus Dados são Armazenados?</h3>
                    <p>
                        Os Dados Pessoais que o Doutor123 coleta são armazenados
                        em serviços de nuvem confiáveis de parceiros que podem
                        estar localizados no Brasil ou em outros países que
                        ofereçam serviço de armazenamento de nuvem confiáveis e
                        usualmente utilizados por empresas de tecnologia, tais
                        como nos Estados Unidos da America, em países europeus
                        ou da América Latina.
                    </p>

                    <h3>4.2 Por quanto tempo os seus Dados são Armazenados?</h3>
                    <p>
                        O Doutor123 armazena as suas informações e Dados
                        Pessoais durante o período necessário para atingimento
                        das finalidades descritas na Seção 3 desta Política de
                        Privacidade, bem como nos Termos e Condições de Uso,
                        sempre respeitando os períodos autorizados e/ou
                        obrigatórios de retenção de dados previstos na
                        Legislação de Proteção de Dados e outras, conforme
                        aplicáveis.
                    </p>
                    <p>
                        Caso você solicite a exclusão da sua conta de Usuário,
                        os seus Dados Pessoais fornecidos no cadastramento e
                        utilização da Plataforma serão excluídos, salvo para as
                        finalidades permitidas pela Legislação de Proteção de
                        Dados.
                    </p>
                    <p>
                        Em alguns casos, podemos reter determinadas informações
                        mesmo que você solicite a exclusão de sua conta de
                        Usuários como, por exemplo, nas hipóteses de guarda
                        obrigatória de registros previstas na legislação
                        aplicável, de haver uma questão não resolvida
                        relacionada à sua conta de Usuário, de exercício regular
                        de nossos direitos ou, ainda, caso necessário para
                        defender nossos interesses legítimos como prevenção de
                        fraudes e aprimoramento dos sistemas de segurança da
                        Plataforma.
                    </p>
                </div>

                <h2>5 Como os Dados são Compartilhados?</h2>
                <p>
                    Nesta seção, trazemos mais detalhes sobre as formas como
                    seus dados poderão ser compartilhados.
                </p>

                <div>
                    <h3>5.1 Provedores de Serviços Tecnológicos</h3>
                    <p>
                        Para oferecer a estrutura e funcionalidades
                        tecnológicasda da Plataforma, o Doutor123 utiliza
                        tecnologias desenvolvidas por terceiros prestadores de
                        serviços. Dessa forma, seus dados poderão ser
                        compartilhados com parceiros da Doutor123 para
                        viabilizar a utilização de determinadas funcionalidades
                        da Plataforma por vocês como, por exemplo, a realização
                        de consultas na modalidade de Telemedicina, a
                        contratação de Serviços de Saúde ou para gerar um
                        relatório pré-diagnóstico a partir de nossa ferramenta
                        de inteligência artificial (symptom checker). Nesses
                        casos, os dados serão tratados de forma a garantir a
                        privacidade das suas informações, sendo que terceiros
                        prestadores de serviços têm o dever contratual de
                        proteção compatível com a Legislação de Proteção de
                        Dados e com o disposto na presente Política de
                        Privacidade.
                    </p>
                    <p>
                        Sem prejuízo do disposto acima, esclarecemos que podemos
                        utilizar serviços de terceiros para incrementar a sua
                        experiência na Plataforma, bem como para prover nossos
                        serviços, realizar a manutençãoda Plataforma, fazer a
                        gestão de bancos de dados, processar o armazenamento de
                        dados em sistemas computacionais de armazenamento em
                        nuvem, realizar a manutenção de sistema central de
                        atendimento aos Usuários e desenvolver marketing junto a
                        agências de publicidade.
                    </p>
                    <p>
                        Em qualquer caso, o compartilhamento de informações será
                        restrito ao necessário para execução das atividades
                        desenvolvidas pelo Doutor123, sendo os terceiros
                        provedores de serviços obrigados a não usar ou divulgar
                        tais informações para nenhuma outra finalidade, exceto
                        conforme expressamente previsto em suas respectivas
                        políticas de privacidade.
                    </p>

                    <h3>5.2 Parceiros da Rede Credenciada</h3>
                    <p>
                        Ao efetivar a contratação de um Serviço de Saúde por
                        meio da Plataforma, nós poderemos compartilhar algumas
                        informações com o parceiro da Rede Credenciada
                        responsável pelo atendimento, bem como fornecer dados
                        específicos sobre a contratação.
                    </p>

                    <h3>5.3 Parceiros Comerciais</h3>
                    <p>
                        Nós poderemos compartilhar os seus dados com empresas de
                        atividades financeiras ou comerciais com as quais o
                        Doutor123 mantém um relacionado de colaboração ou
                        parceira, a fim de oferecer promoções, produtos e
                        serviços dessas empresas em conjunto. Qualquer parceria
                        comercial do Doutor123 é formalizada via acordos ou
                        contratos que preveem cláusulas específicas quanto a
                        proteção e segurança da privacidade e Dados Pessoais dos
                        Usuários, bem como relacionadas ao cumprimento da
                        legislação aplicável para assegurar a adoção de técnicas
                        de segurança e confidencialidade alinhadas com as
                        melhores práticas de mercado.
                    </p>

                    <h3>5.4 Empresas do Grupo Doutor123</h3>
                    <p>
                        As empresas do grupo do Doutor123 seguem o mesmo padrão
                        de proteção de Dados Pessoais descritos nesta Política
                        de Privacidade e observam as finalidades aqui previstas.
                        Ao compartilharmos os seus Dados Pessoais com essas
                        empresas, as mesmas e garantias e cuidados que o
                        Doutor123 possui com seus dados serão replicados pelas
                        empresas do nosso grupo. O compartilhamento de dados com
                        empresas do grupo do Doutor123 terá como finalidades:
                        viabilizar determinadas etapas ou processamento de dados
                        quando o Usuário utiliza a Plataforma; o desenvolvimento
                        de novos produtos, funcionalidades e serviços, bem como
                        sua melhoria e aperfeiçoamento; a oferta de produtos e
                        serviços que melhor atendam aos seus interesses; a
                        geração de dados estatísticos e agregados acerca do uso
                        de nossos produtos e serviços e perfis dos Usuários;
                        marketing, prospecção, pesquisas de mercado, de opinião
                        e promoção de produtos e serviços; e investigações e
                        medidas de prevenção a combate a ilícitos fraudes.
                    </p>

                    <h3>
                        5.5 No caso de Alteração do Controle Societário do
                        Doutor123
                    </h3>
                    <p>
                        No caso de operações societárias, como reestruturação,
                        fusão, incorporação ou venda de ativos do Doutor123, do
                        seu grupo economico ou parte dele, seus dados poderão
                        ser transferido, desde que respeitados os termos da
                        presente Política de Privacidade.
                    </p>

                    <h3>5.6 Publicidade e Serviços de Análises</h3>
                    <p>
                        Para viabilizar a entrega de publicidades e promoções
                        que melhor atendam as suas necessidades, bem com a
                        melhora contínua dos serviços que oferecemos por meio da
                        Plataforma, nós poderemos compartilhar os seus dados
                        anonimizados com empresas especializadas em marketing e
                        de análise de dados digitais, desde que assegurem um
                        nível de proteção de dados compatível com os termos
                        desta Política de Privacidade.
                    </p>

                    <h3>5.7 Autoridades Competentes</h3>
                    <p>
                        O Doutor123 também poderá compartilhar suas informações
                        com autoridades policiais, administrativas, judiciais,
                        autoridades públicas competentes ou outros terceiros,
                        dentro e fora do país em que você reside, caso seja
                        requerido pela legislação aplicável, por decisão
                        judicial e por requisição de autoridades competentes, ou
                        conforme estritamente necessário para responder a
                        processos judiciais ou para participar em eventuais
                        litígios ou disputas de qualquer natureza.
                    </p>

                    <p>
                        Nestas situações, o Doutor123 cooperará com as
                        autoridades competentes na medida do estipulado por lei.
                        Além disso, o Doutor123 reserva a prerrogativa de
                        compartilhar informações com terceiros quando houver
                        motivos suficientes para considerar que a atividade de
                        um Usuário é suspeita, ilegal ou prejudicial ao
                        Doutor123 ou a terceiros. Nosso objetivo é garantir a
                        integridade e a segurança do seu serviço, bem como o
                        cumprimento dos Termos e Condições de Uso, para o
                        exercício regular de seus direitos e com o intuito de
                        cooperar com a execução e o cumprimento da lei,
                        independentemente de existir ou não uma ordem judicial
                        ou administrativa para tanto.
                    </p>
                </div>

                <h2>6 Como utilizamos cookies e outras tecnologias?</h2>
                <p>
                    O Doutor123 utiliza tecnologias como cookies, pixel tags,
                    armazenamento local ou outros identificadores, tanto de
                    dispositivos móveis ou não, ou tecnologias semelhantes, para
                    uma variedade de funções. Essas tecnologias nos ajudam a
                    autenticar a sua conta, promover e aperfeiçoar os serviços
                    do Doutor123, personalizar a sua experiência e avaliar a
                    eficácia da nossa comunicação e publicidade.
                </p>
                <p>
                    Os Usuários são livres para recusar cookies se seu navegador
                    ou add-on permitir, a menos que sejam necessários para
                    evitar fraude ou garantir a segurança da Plataforma. A
                    recusa dos Cookies pode interferir no uso da Plataforma e
                    serviços correlatos.
                </p>

                <div>
                    <h3>6.1 O que são essas Tecnologias?</h3>
                    <p>
                        Cookies são pequenos arquivos armazenados no seu
                        navegador, celular ou outro dispositivo. Pixel tags (ou
                        GIFs limpos, web beacons ou pixels) são pequenos blocos
                        de código em uma página da web que permitem que elas
                        realizem ações como ler e armazenar cookies e transmitir
                        informações para o Doutor123. A conexão resultante pode
                        incluir informações como o endereço de IP de um
                        dispositivo, a hora em que uma pessoa visualizou o
                        pixel, um identificador associado ao navegador ou
                        dispositivo e o tipo de navegador em uso.
                    </p>
                    <p>
                        Identificadores de dispositivo mobile são códigos que
                        possibilitam a identificação do seu dispositivo mobile,
                        seja de maneira persistente ou transitória, tais como o
                        ID de Publicidade ou o ID de seu sistema operacional.
                    </p>

                    <h3>6.2 Promover uma Experiência Personalizada e Segura</h3>
                    <p>
                        O Doutor123 e seus parceiros usam tais tecnologias para
                        coletar determinadas informações pessoais quando você
                        utiliza a Plataforma. Tais informações são utilizadas
                        para reconhecer os Usuários; customizar nossos serviços,
                        conteúdos e publicidade; medir a eficácia de nossas
                        promoções; ajudar a certificar que a segurança da conta
                        de determinado Usuário não foi comprometida; mitigar
                        riscos e prevenir fraudes; e promover confiança e
                        segurança na Plataforma.
                    </p>

                    <h3>
                        6.3 Avaliar a Eficácia da nossa Comunicação e
                        Publicidade
                    </h3>
                    <p>
                        Nós usamos essas informações também para entender e
                        analisar tendências, administrar nossos serviços,
                        aprender sobre o comportamento do Usuário e obter
                        informações demográficas sobre a nossa base de Usuários
                        de maneira geral. Em algumas de nossas mensagens de
                        e-mail, nós usamos uma &quot;URL click-through&quot;
                        (endereço externo) vinculada ao conteúdo do Doutor123.
                        Quando vocês clicam em uma dessas URLs, os Usuários são
                        enviados para um servidor diferente antes de chegarem à
                        página de destino no nosso serviço.
                    </p>
                    <p>
                        Nós monitoramos esses dados de click-through para
                        entender o interesse em determinados tópicos e avaliar a
                        eficácia das comunicações com os nossos Usuários. Se
                        você preferir não ser monitorado dessa maneira, não
                        clique em texto ou links contidos em mensagens de e-mail
                        enviadas pelo Doutor123.
                    </p>
                    <p>
                        Os pixels tags nos permitem enviar mensagens de e-mail
                        em formatos que os usuários possam ler e nos dizer se o
                        e-mail foi aberto ou não. Nós podemos usar essas
                        informações para reduzir ou eliminar as mensagens
                        enviadas aos usuários.
                    </p>

                    <h3>6.4 Tecnologia de Terceiros</h3>
                    <p>
                        Além dos cookies e outras tecnologias do Doutor123, nós
                        podemos permitir que terceiros contratados pelo
                        Doutor123 utilizem cookies e outras tecnologias de sua
                        propriedade para identificar seu navegador e
                        dispositivo, de modo a lhe oferecer publicidade
                        direcionada do Doutor123 quando você acessa websites ou
                        aplicativos de terceiros.
                    </p>
                    <p>
                        Esses terceiros podem também fornecer ao Doutor123
                        informações acerca do desempenho das campanhas de
                        marketing desenvolvidas por meio do compartilhamento de
                        dados conosco.
                    </p>
                    <p>
                        A título de exemplo, algumas dessas empresas podem
                        utilizar cookies e outras tecnologias próprias nos
                        serviços do Doutor123, tais como o Google Analytics.
                    </p>
                </div>

                <h2>7 Como Protegemos os seus Dados?</h2>
                <p>
                    O Doutor123 adota medidas de segurança, técnicas e
                    administrativas aptas a proteger os dados pessoais de
                    acessos não autorizados e de situações acidentais ou
                    ilícitas de destruição, perda, alteração, comunicação ou
                    qualquer forma de tratamento inadequado ou ilícito.
                </p>
                <p>
                    Utilizamos os princípios estipulados por lei, respeitando a
                    sua privacidade e protegendo seus dados em nossos processos
                    internos como um todo.
                </p>
                <p>
                    Só tratamos os seus dados mediante alto grau de segurança,
                    implementando as melhores práticas em uso na indústria para
                    a proteção de dados, tais como técnicas de criptografia,
                    monitoramento e testes de segurança periódicos.
                </p>

                <h3>8 Direitos dos Usuários</h3>
                <p>
                    Vocês têm o direito a obter, em relação aos seus dados
                    pessoais, a qualquer momento e mediante requisição ao
                    Doutor123:
                </p>

                <div>
                    <ul>
                        <li>
                            confirmação da existência de tratamento de Dados
                            Pessoais;
                        </li>
                        <li>
                            acesso aos dados pessoais armazenados na base de
                            dados da Doutor123;
                        </li>
                        <li>
                            correção de dados incompletos, inexatos ou
                            desatualizados;
                        </li>
                        <li>
                            anonimização, bloqueio ou eliminação de dados
                            desnecessários, excessivos ou tratados em
                            desconformidade com o disposto na Legislação de
                            Proteção de Dados;
                        </li>
                        <li>
                            portabilidade dos dados a outro fornecedor de
                            serviço ou produto, mediante requisição expressa, de
                            acordo com a regulamentação da autoridade nacional
                            competente, observados os segredos comercial e
                            industrial;
                        </li>
                        <li>
                            eliminação dos dados pessoais tratados com o
                            consentimento do Usuário, exceto nas hipóteses
                            previstas no artigo 16 da Lei Geral de Proteção de
                            Dados;
                        </li>
                        <li>
                            informação das entidades públicas e privadas com as
                            quais o Doutor123 realizou uso compartilhado de
                            dados;
                        </li>
                        <li>
                            informação sobre a possibilidade de não fornecer
                            consentimento e sobre as consequências da negativa;
                            e
                        </li>
                        <li>
                            revogação do consentimento para tratamento de dados
                            pelo Doutor123.
                        </li>
                    </ul>
                </div>
                <p>
                    O exercício dos direitos acima pelo Usuário deverá ser
                    realizado mediante o envio de requerimento ao Doutor123r no
                    e-mail atendimento@doutor123.com.br.
                </p>
                <p>
                    Na hipótese de dados incompletos ou inexatos, o Usuário
                    deverá inicialmente utilizar as ferramentas de edição ou
                    exclusão disponibilizadas na Plataforma para correção. Caso
                    não seja possível, deverá ser formulado requerimento ao
                    Doutor123 no e-mail atendimento@doutor123.com.br.
                </p>
                <p>
                    LIMITAÇÕES AOS DIREITOS DOS USUÁRIOS: EM ALGUNS CASOS, O
                    DOUTOR123 PODE TER MOTIVOS LEGÍTIMOS PARA DEIXAR DE ATENDER
                    A UMA SOLICITAÇÃO DE EXERCÍCIO DE DIREITOS. ESSAS SITUAÇÕES
                    INCLUEM, POR EXEMPLO, CASOS EM QUE UMA REVELAÇÃO DE
                    INFORMAÇÕES ESPECÍFICAS PODERIA VIOLAR DIREITOS DE
                    PROPRIEDADE INTELECTUAL OU SEGREDOS DE NEGÓCIO DO DOUTOR123
                    OU DE TERCEIROS, BEM COMO CASOS EM QUE PEDIDOS DE
                    ANONIMIZAÇÃO, BLOQUEIO OU ELIMINAÇÃO DE DADOS NÃO POSSAM SER
                    ATENDIDOS EM RAZÃO DA EXISTÊNCIA DE OBRIGAÇÃO DO DOUTOR123DE
                    RETER DADOS, SEJA PARA CUMPRIR OBRIGAÇÕES LEGAIS,
                    REGULATÓRIAS OU PARA POSSIBILITAR A DEFESA DE DIREITOS DO
                    DOUTOR123OU DE TERCEIROS, INCLUSIVE EM DISPUTAS DE QUALQUER
                    NATUREZA.
                </p>
                <h2>9 O que mais você precisa saber?</h2>
                <div>
                    <h3>
                        9.1 Como iremos notificá-lo(a) em caso de mudanças à
                        essa Política de Privacidade?
                    </h3>
                    <p>
                        O Doutor123 poderá atualizar essa Política de
                        Privacidade periodicamente, sendo que a versão em vigor
                        será sempre a mais recente. Se fizermos alguma alteração
                        material nesta Política de Privacidade, colocaremos um
                        aviso na Plataforma e/ou te enviar um e-mail, juntamente
                        com a Política de Privacidade atualizada. Para verificar
                        a data da versão em vigor, verifique a “Data de
                        atualização” no início desse documento.
                    </p>

                    <h3>9.2 Nossos Canais de Atendimento</h3>
                    <p>
                        Em caso de problemas durante a utilização da Plataforma
                        ou se precisar de qualquer ajuda ou esclarecimento, você
                        poderá acessar a nossa equipe de atendimento no e-mail
                        atendimento@doutor123.com.br, por telefone no número
                        (11) 4200-1140 ou nosso WhatsApp (11) 98488-7198.
                        Demandas direcionadas para outros canais não serão
                        avaliadas.
                    </p>
                    <p>
                        Nossa equipe está disponível das 9h às 18h durante dias
                        úteis, pronta para atender todos os Usuários em qualquer
                        contratempo que possar surgir em decorrência do uso da
                        Plataforma. O nosso suporte mira não somente solucionar
                        eventuais problemas de forma ágil e eficaz, mas também
                        promover um ambiente de comunicação transparente e
                        amigável de resolução.
                    </p>
                    <p>
                        Em caso de dúvidas sobre a presente Política de
                        Privacidade ou específicas sobre o exercício dos seus
                        direitos não solucionadas pela nossa equipe de
                        atendimento serão direcionadas para nosso encarregado
                        legal pela área de proteção de dados, o Sr. Lucas
                        Baptistella Henriques, por meio do e-mail
                        legal@doutor123.com.br.
                    </p>

                    <h3>
                        9.3 Aceitação Integral desta Política de Privacidade
                    </h3>
                    <p>
                        VOCÊ USUÁRIO DECLARA TER A CAPACIDADE LEGAL PARA LER E
                        COMPREENDER A PRESENTE POLÍTICA DE PRIVACIDADE E, AO
                        CONCLUIR O CADASTRAMENTO NA PLATAFORMA E/OU UTILIZAR OS
                        NOSSOS SERVIÇOS, VOCÊ MANIFESTA ESTAR PLENAMENTE CIENTE
                        E CONSENTE AOS TERMOS E CONDIÇÕES EXPRESSOS NESTA
                        POLÍTICA DE PRIVACIDADE.
                    </p>

                    <h3>
                        9.4 Legislação Aplicável, Resolução de Conflitos e
                        Disposições Finais
                    </h3>
                    <p>
                        Esta Política de Privacidade é regida e interpretada de
                        acordo com a legislação da República Federativa do
                        Brasil.Brasil.
                    </p>
                </div>
                <p>
                    O Doutor123 trabalha constantemente oferecer o melhor
                    serviço possível a você, fazendo milhares de pessoas
                    felizes. Por isso, em caso de divergências ou conflitos
                    entre você e o Doutor123, sugerimos que entre em contato com
                    nossos canais de atendimento indicados em nossa Plataforma
                    para solucionarmos a demanda de forma consensual e amigável.
                    Fica eleito o foro da Comarca da Capital do Estado de São
                    Paulo como competente para dirimir eventuais dúvidas,
                    controvérsias e conflitos decorrentes da interpretação e
                    cumprimento desta Política de Privacidade.
                </p>

                <p>
                    Por enquanto é só! Chegamos ao fim da nossa Política de
                    Privacidade. Obrigado pela leitura! Nossa equipe está
                    inteiramente à disposição para esclarecer eventuais dúvidas.
                </p>
            </main>
            <div className={styled["images_right"]}>
                <img
                    src="/images/compartilhamento.png"
                    alt="Cliente e infermeiras compartilhado informações pela internet"
                />
                <img
                    src="/images/Enfermeira-amarela.png"
                    alt="Cliente e infermeiras compartilhado informações pela internet"
                />
            </div>
        </div>
    );
}
