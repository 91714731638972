import SectionFuncionalidades from "../../components/companies/SectionFuncionalidades";
import SectionMainEmpresas from "../../components/companies/SectionMainEmpresas";
import SectionRHDivider from "../../components/companies/SectionRHDivider";
import SectionRecomendacoes from "../../components/companies/SectionRecomendacoes";
import SectionSimulacao from "../../components/companies/SectionSimulacao";
import SectionTriade from "../../components/companies/SectionTriade";
import SectionVantagens from "../../components/companies/SectionVantagens";
import SectionAtivosDivider from "../../components/companies/SectionnAtivosDivider";
import React from "react";

export default function Company() {
    const simulationSection = React.createRef();

    function scrollToSimulationSection(e) {
        simulationSection.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "center",
        });
    }

    return (
        <div className="container">
            <SectionMainEmpresas simulate={scrollToSimulationSection} />
            <SectionAtivosDivider />
            <div ref={simulationSection}></div>
            <SectionSimulacao />
            <SectionFuncionalidades />
            <SectionVantagens />
            <SectionRHDivider />
            <SectionTriade />
            <SectionRecomendacoes />
        </div>
    );
}
