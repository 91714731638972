import { useState } from "react";
import styles from "./SectionSimulePacote.module.scss";
import { Heading, Text, Button, Input, Checkbox } from "../../ui";

import ModalSimulacao from "./ModalSimulacao";
import ModalSimulacaoResultado from "./ModalSimulacaoResultado";
import sendSimulationData from "../../../service";
import { useEffect } from "react";

export const SectionSimulePacote = ({ page }) => {
    const [showModal, setShowModal] = useState(false);
    const [showModalResultado, setShowModalResultado] = useState(false);
    const [colaborators, setColaborators] = useState("");
    const [colaboratorError, setColaboratorError] = useState("");
    const [totalAmount, setTotalAmount] = useState("R$ 0,00");
    const [loading, setLoading] = useState(false);

    const selectItem = (id) => {
        if (id === 1) return;
        const list = [...simulacaoData];
        list[id - 1].selected = !list[id - 1].selected;
        setSimulacaoData(list);
    };

    const validateSelected = (item) => {
        return item.selected;
    }

    const handleEventInput = (event) => {
        const inputValue = event.target.value;

        if (/^\d*$/.test(inputValue)) {
            setColaborators(inputValue);
            setColaboratorError("");
        }
    };
    
    const [simulacaoData, setSimulacaoData] = useState([
        {
            id: 1,
            title: "Acesso ao Doutor123",
            selected: true,
            description:
                "Consultas e exames a preços acessíveis e pagamento facilitado, desconto em medicamentos, análise de sintomas IA.",
        },
        {
            id: 2,
            title: "Cuidados com a saúde",
            selected: false,
            description:
                "Pronto atendimento virtual, orientação 24h, linha de cuidado e telemonitoramento.",
        },
        {
            id: 3,
            title: "Atividade física e bem-estar",
            selected: false,
            description: "Academias, videoaulas e atividades físicas em geral.",
        },
        {
            id: 4,
            title: "Saúde mental",
            selected: false,
            description: "Suporte emocional 24h e terapia online",
        },
        {
            id: 5,
            title: "Planos de saúde e seguros",
            selected: false,
            description:
                "Seguro de vida, assistência funeral e planos de saúde customizados com parceiros Doutor123.",
        },
    ]);

    const servicoError = !simulacaoData.some((item) => item.selected);

    const handlerModal = () => {
        if (servicoError) {
            return;
        }

        if (colaborators.length >= 0 && colaborators <= 0) {
            return setColaboratorError("Informe a quantidade de colaboradores");
        }

        setShowModal(true);
    };

    const handlerSimulacao = async (value) => {
        const selectedItems = simulacaoData.filter((item) => item.selected);
        const selectedIds = selectedItems.map((item) => item.id);

        const data = {
            licenses: colaborators,
            services: selectedIds,
            phoneNumber: value.phone,
            email: value.email,
            contactName: value.name,
            companyName: value.company,
            requestContact: value.check,
            origin: `simulacao-${page || "page"}`,
        };

        setLoading(true);
        const result = await sendSimulationData(data);
        if (result) {
            setTotalAmount(result.total_amount);
            setShowModalResultado(true);
            setShowModal(false);
        }
        setLoading(false);
    };

    useEffect(() => {

    }, [simulacaoData])

    return (
        <section className={`${styles["section-simule-pacote"]} container`} id="simule">
            <div className={styles["section-simule-pacote_container"]}>
                <div
                    className={`${styles["section-simule-pacote_container__title"]}`}
                >
                    <div
                        className={
                            styles[
                            "section-simule-pacote_container__title__heading"
                            ]
                        }
                    >
                        <Heading tag="h2" color="secondary">
                            Simule o seu pacote <span>{">>>"}</span>
                        </Heading>
                    </div>

                    <Text tag="small" type="text" size="2" color="secondary">
                        <b>
                            Selecione os benefícios que deseja incluir em seu
                            pacote, digite o número de colaboradores e clique em
                            “simular” para ver o preço.
                        </b>
                    </Text>

                    <img
                        src="/images/img-teleconsulta.png"
                        className={
                            styles["section-simule-pacote_container__image"]
                        }
                    ></img>
                </div>

                <div
                    className={`${styles["section-simule-pacote_container__content-card"]}`}
                >
                    {simulacaoData.map((item) => (
                        <div
                            key={item.id}
                            onClick={(e) => selectItem(item.id)}
                            onChange={(e) => selectItem(item.id)}
                            data-active={0}
                            className={`
                                ${styles["section-simule-pacote_container__content-card__card"]} 
                                ${validateSelected(item)  
                                    ? styles["section-simule-pacote_container__content-card__card--selected"]
                                    : ""
                                }`
                            }
                        >
                            <div>
                                <Heading tag="h6" color="secondary">
                                    {item.title}
                                </Heading>
                                <Text
                                    tag="small"
                                    color="secondary"
                                    type="text"
                                    size="2"
                                >
                                    {item.description}
                                </Text>
                            </div>
                            <Checkbox
                                onChange={(e) => selectItem(item.id)}
                                checked={validateSelected(item)}
                            />
                        </div>
                    ))}

                    <div
                        className={
                            styles[
                            "section-simule-pacote_container__content-card__card-form"
                            ]
                        }
                    >
                        <Heading
                            tag="h4"
                            color="secondary"
                            className={styles["heading"]}
                        >
                            Quantos colaboradores tem na sua empresa?
                        </Heading>

                        <div
                            className={
                                styles[
                                "section-simule-pacote_container__content-card__card-form__forms"
                                ]
                            }
                        >
                            <fieldset>
                                <Input
                                    name="numero-colaboradores"
                                    id="numero-colaboradores"
                                    value={colaborators}
                                    type="text"
                                    onChange={handleEventInput}
                                    placeholder="Digite aqui o número de colaboradores"
                                />

                                <span className={styles["span-error"]}>
                                    {colaboratorError}
                                </span>

                                {servicoError && (
                                    <Text
                                        tag="small"
                                        className={styles["span-error"]}
                                    >
                                        Selecione pelo menos um dos serviços
                                        acima!
                                    </Text>
                                )}
                            </fieldset>

                            <Button
                                color="purple"
                                text="Simular"
                                small={true}
                                onClick={handlerModal}
                            ></Button>
                        </div>

                        <ModalSimulacao
                            show={showModal}
                            onClose={() => setShowModal(false)}
                            onOk={(value) => handlerSimulacao(value)}
                            loading={loading}
                        />
                        {showModalResultado && (
                            <ModalSimulacaoResultado
                                show={showModalResultado}
                                colaborators={colaborators}
                                totalAmount={totalAmount}
                                onClose={() => setShowModalResultado(false)}
                                onOk={(value) => handlerSimulacao(value)}
                            />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};
