import styles from "./FigureDownload.module.scss";
import P from "prop-types";

export const FigureDownload = ({ column }) => {
    const _column = !column
        ? "figure-download__row"
        : "figure-download__column";

    return (
        <figure className={`${styles["figure-download"]} ${styles[_column]}`}>
            <a
                href="https://play.google.com/store/apps/details?id=br.com.flutter.doutor123"
                target="_blank"
                rel="noreferrer"
            >
                <img src="/images/img-android.png"></img>
            </a>
            <a
                href="https://apps.apple.com/br/app/doutor123-seu-app-de-sa%C3%BAde/id6443489191?l=en"
                target="_blank"
                rel="noreferrer"
            >
                <img src="/images/img-ios.png"></img>
            </a>
        </figure>
    );
};

FigureDownload.propTypes = {
    column: P.bool,
};

FigureDownload.defaultProps = {
    column: false,
};
