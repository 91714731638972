import React from "react";
import styles from "./ModalSimulacaoResultado.module.scss";
import { Modal, Heading, Text } from "../../../ui";

function ModalSimulacaoResultado(props) {
    const closeModal = () => {
        props.onClose();
    };

    const colaborators = `${props.colaborators} ${
        props.colaborators > 1 ? "colaboradores" : "colaborador"
    }`;

    return (
        <Modal show={props.show} 
            className={styles['modal']}
            size='small'
            onClose={() => closeModal()}>
            <div className={styles["result-modal"]}>
                <div className={styles["result-modal__body"]}>
                    <div className={styles["result-modal__body__title"]}>
                        <Heading tag="h4">
                            Saúdavel do <u>seu</u> jeito!
                        </Heading>

                        <Heading tag="h4">
                            Saúdavel do <u>seu</u> jeito!
                        </Heading>
                        <Heading tag="h4">
                            Saúdavel do <u>seu</u> jeito!
                        </Heading>

                    </div>
                    <Text color={"white"} size="1" type="title">
                        No <span>Doutor123</span> você encontra pacotes a partir
                        de {props.totalAmount} por vida/mês.
                    </Text>
                    <Text color={"white"} size="1" tag="small">
                        * Valor estimado para uma empresa com {colaborators}. O
                        preço varia de acordo com os serviços selecionados e
                        volume de vidas ativas.
                    </Text> 
                </div>
                <img
                    src="images/img-consultaPresencial.png"
                    alt="Formulario de Simulação"
                />
            </div>
        </Modal>
    );
}

export default ModalSimulacaoResultado;
