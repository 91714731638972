import React from "react";
import SectionDivider from "../SectionDivider";

function SectionAtivosDivider() {
    return (
        <SectionDivider>
            <p>
                <strong>
                    O seu ativo mais valioso são as <span>pessoas</span>.
                    Invista nelas com saúde oferecendo <span>benefícios</span>{" "}
                    que traduzem uma dinâmica de trabalho{" "}
                    <span>equilibrada</span> com saúde e bem-estar.
                </strong>
            </p>
        </SectionDivider>
    );
}

export default SectionAtivosDivider;
