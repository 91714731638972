import styles from "./SectionBeneficios.module.scss";
import { Text } from "../../ui/Text";
import { Divider } from "../../ui/Divider";
import { benefitList } from "./data";

export const SectionBeneficios = () => {
    return (
        <section className={`${styles["section-beneficios"]} container`}>
            {benefitList.map((benefit, index) => (
                <div
                    className={`${styles["section-beneficio_container__content-card"]} ${styles[benefit.theme]}`}
                    key={index}
                >
                    <Text tag="p" type="text" size="1" className="text-white">
                        {benefit.text}
                    </Text>
                </div>
            ))}
            <Text
                color="secondary"
                tag="p"
                type="text"
                size="1"
                className={styles["nossos-planos"]}
            >
                E muito mais! Veja abaixo <span>nossos planos</span> para
                conferir todas as funcionalidades.
            </Text>
            <Divider></Divider>
        </section>
    );
};
