import { IconTelemedicina } from "../../icons/IconTelemedicina";
import { IconUsuarios } from "../../icons/IconUsuarios";
import { IconDadosConsulta } from "../../icons/IconDadosConsulta";
import { IconHistorico } from "../../icons/IconHistorico";
import { IconTriagem } from "../../icons/IconTriagem";

export const appData = [
    {
        icon: <IconTelemedicina></IconTelemedicina>,
        title: "Telemedicina in App",
        description:
            "Realize suas consultas on-line, tudo dentro da plataforma.",
    },
    {
        icon: <IconUsuarios></IconUsuarios>,
        title: "Acesso à Rede Doutor123",
        description:
            "Realize buscas, cotações e agendamentos junto a nossos parceiros.",
    },
    {
        icon: <IconDadosConsulta></IconDadosConsulta>,
        title: "Dados de consulta",
        description:
            "Acesso a prontuários e prescrições emitidos em seus atendimentos.",
    },
    {
        icon: <IconHistorico></IconHistorico>,
        title: "Histórico de saúde",
        description:
            "Armazene e acesso o seu histórico de saúde sempre que precisar! Adicione exames, imagens, tratamentos, sintomas, sinais vitais e muito mais.",
    },
    {
        icon: <IconTriagem></IconTriagem>,
        title: "Triagem de sintomas com base em inteligência artificial",
        description:
            "Cansado de recorrer ao Google? Use nossa ferramenta de triagem de sintomas baseada em inteligência artificial para ter um norte de como avançar na sua jornada de saúde.",
    },
];
