import React from "react";
import { Heading } from "../../ui";
import { SectionSimulePacote } from "../../home/SectionSimulePacote";
import styles from "./SectionSimulacao.module.scss";

function SectionSimulacao() {
    return (
        <section className={styles["section-simulacao"]}>
            <Heading color="secondary" tag={"h2"}>
                Acesso customizado para sua empresa.
            </Heading>
            <SectionSimulePacote page="pagina empresas" />
        </section>
    );
}

export default SectionSimulacao;
