import styles from "./SectionSaude.module.scss";

import { Heading } from "../../ui/Heading";
import { Text } from "../../ui/Text";
import { useState } from "react";
import sendSimulationData from "../../../service";
import ModalSimulacao from "../SectionSimulePacote/ModalSimulacao";
import { Button, Modal } from "../../ui";


export const SectionSaude = () => {
    const [showModal, setShowModal] = useState(false);
    const [showModalSubmit, setShowModalSubmit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handlerSimulacao = async (value) => {
        const data = {
            licenses: 0,
            services: [1],
            phoneNumber: value.phone,
            email: value.email,
            contactName: value.name,
            companyName: value.company,
            requestContact: value.check,
            origin: `contato-comercial-home`,
        };

        setLoading(true);
        
        const result = await sendSimulationData(data);
        if (result) {
            setShowModal(false);
            setShowModalSubmit(true);
        }
        setLoading(false);
    };

    return (
        <section className={styles["section-saude"]}>
            <div className={`${styles["section-saude__container"]} container`}>
                <div className={`${styles["section-saude__container--content"]}`}>
                    <div className={styles["section-saude__container-title"]}>
                        <Heading tag="h3" color="secondary">
                            A saúde do seu colaborador
                        </Heading>

                        <Heading tag="h1" color="secondary" className="mt-3">
                            importa & impacta.
                        </Heading>
                    </div>

                    <div className={`${styles["section-saude__container-body"]}`}>
                        <Text
                            tag="span"
                            type="title"
                            size="1"
                            color="secondary"
                            className="col-lg-6 col-6"
                        >
                            É sobre proporcionar qualidade de vida. Afinal, pessoas
                            saudáveis são também mais <b>motivadas</b> e{" "}
                            <b>produzem melhor</b>.
                        </Text>

                        <figure className="col-lg-6 col-6">
                            <img
                                alt="saude douto123"
                                src="/images/img-saude.png"
                            ></img>
                        </figure>
                    </div>

                    <div
                        className={`${styles["section-saude__container--button"]} col-lg-6 col-12`}
                    >
                        <a onClick={() => setShowModal(true)}>Quero uma proposta para a minha empresa</a>
                        <a onClick={() => setShowModal(true)}>Quero uma proposta</a>
                    </div>
                </div>
            </div>

            <ModalSimulacao
                show={showModal}
                onClose={() => setShowModal(false)}
                onOk={(value) => handlerSimulacao(value)}
                textButton={'Solicitar contato'}
                loading={loading}
            />

            <Modal
                className={styles.modal_small}
                show={showModalSubmit}
                size="small"
                onClose={() => setShowModalSubmit(false)}>
                <div className={styles.modal}>

                    <article className={styles.modal__article_submit}>
                        <figure>
                            <img
                                src="/images/img-mundo-tecnologia.png"
                                alt="doutor123 tecnologias"
                            />
                        </figure>

                        <div className={styles.modal_vem_fazer_parte__article_submit__title}>
                            <Heading tag="h3" color="primary">
                                Muito obrigado!
                            </Heading>

                            <Text tag="span" color="white" size="1" type="text">
                                Em breve retornaremos o seu contato.
                            </Text>
                        </div>

                        <div className={styles.modal_vem_fazer_parte__article_submit__footer}>
                            <Button
                                text="Fechar"
                                color="primary"
                                type="submit"
                                small={false}
                                onClick={() => setShowModalSubmit(false)}
                                className={styles.modal_vem_fazer_parte__article_submit__footer__button}
                            />
                        </div>
                    </article>
                </div>
            </Modal>
        </section >
    );
}
