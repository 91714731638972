import React from "react";

import styles from "./Modal.module.scss";

export function Modal(props) {
    const closeModal = () => {
        props.onClose();
    };

    const styleModal = [
        styles["custom-modal"],
        props.show ? "" : styles["modal-hidden"],
    ].join(' ');
    
    const styleModalContent = [
        styles["custom-modal__content"],
        props.className
    ].join(' ');

    return (
        <div className={styleModal}>
            <section className={styleModalContent}>
                <div
                    className={styles["custom-modal__content__close"]}
                    onClick={() => closeModal()}
                >
                    X
                </div>
                {props.children}
            </section>
        </div>
    );
}
