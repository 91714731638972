import styles from "./SectionConecteSe.module.scss";

import { Heading, Text } from "../../ui";

export const SectionConecteSe = () => {
    const data = [
        {
            id: 1,
            title: "Sem risco de glosas",
            body: (
                <>
                    Prazo para recebimento de 5 a 30 dias corridos, <br /> ainda
                    que o pagamento seja parcelado pelo usuário.
                </>
            ),
            icon: (
                <img
                    alt="doutor123 cach back"
                    src="/icons/profissionais/icon-cash.svg"
                />
            ),
        },
        {
            id: 2,
            title: "Repasse semanal",
            body: (
                <>
                    Seu pagamento com repasse antecipado, incluindo <br />{" "}
                    no-show, e sem nenhuma taxa.
                </>
            ),
            icon: (
                <img
                    alt="doutor123 candelar"
                    src="/icons/profissionais/icon-calendar.svg"
                />
            ),
        },
        {
            id: 3,
            title: "Pagamento facilitado para pacientes",
            body: (
                <>
                    O pagamento de consultas pode ser parcelado em até <br />
                    3x e exames/vacinas em até 10x.
                </>
            ),
            icon: (
                <img
                    alt="doutor123 cart"
                    src="/icons/profissionais/icon-cart.svg"
                />
            ),
        },
        {
            id: 4,
            title: "Automatize a gestão de pacientes",
            body: (
                <>
                    Mais recursos pro seu dia a dia! O atendimento aos usuários{" "}
                    <br /> Doutor123 podem ser realizados através da nossa
                    plataforma <br /> gratuitamente, contando com todas as
                    nossas funcionalidades <br /> integradas.
                </>
            ),
            icon: (
                <img
                    alt="doutor123 monitor"
                    src="/icons/profissionais/icon-monitor.svg"
                />
            ),
        },
    ];

    return (
        <section className={styles.section_conecte_se}>
            <article className={styles.section_conecte_se__article}>
                <Heading tag="h4" color="secondary">
                    Conecte-se com pacientes no{" "}
                    <Text color="secondary-support" tag="span" size="0">
                        {" "}
                        país inteiro{" "}
                    </Text>
                    através da nossa plataforma e se aproxime de quem mais
                    precisa de você.
                </Heading>
            </article>

            <article className={styles.section_conecte_se__article_vidas}>
                <div
                    className={
                        styles.section_conecte_se__article_vidas__container
                    }
                >
                    <Heading
                        tag="h3"
                        color="white"
                        className={
                            styles.section_conecte_se__article_vidas__container__title
                        }
                    >
                        + de 400.000
                    </Heading>

                    <Text
                        tag="span"
                        color="white"
                        size="1"
                        type="title"
                        className={
                            styles.section_conecte_se__article_vidas__container__sub_title
                        }
                    >
                        titulares e 1,5MM de licenças ativas
                    </Text>
                </div>
            </article>

            <article className={styles.section_conecte_se__article_lista}>
                <div
                    className={
                        styles.section_conecte_se__article_lista__container
                    }
                >
                    {data.map((item) => (
                        <div
                            key={item.id}
                            className={
                                styles.section_conecte_se__article_lista__container__card
                            }
                        >
                            <div
                                className={
                                    styles.section_conecte_se__article_lista__container__card__title
                                }
                            >
                                {item.icon}
                            </div>

                            <div
                                className={
                                    styles.section_conecte_se__article_lista__container__card__body
                                }
                            >
                                <Heading tag="h4" color="secondary">
                                    {item.title}
                                </Heading>
                                <Text
                                    tag="span"
                                    color="secondary"
                                    type="title"
                                    size="1"
                                >
                                    {item.body}
                                </Text>
                            </div>
                        </div>
                    ))}
                </div>
            </article>
        </section>
    );
};
