import styles from "./Icons.module.scss";

export const IconSaudeMental = () => (
    <i className={styles["icons"]}>
        <img
            alt="doutor123 atividade fisica"
            src="/icons/icon-saude-mental.svg"
        ></img>
    </i>
);
