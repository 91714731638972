import styles from "./Input.module.scss";
import P from "prop-types";
import { bool } from "yup";

export const Input = ({
    id,
    name,
    type,
    placeholder,
    required,
    disabled,
    onChange,
    onBlur,
    className,
    isValid,
    isInvalid,
    value,
}) => {
    const valid = isValid ? styles["is-valid"] : "";
    const invalid = isInvalid ? styles["is-invalid"] : "";

    const defaultClassName = [
        "form-control",
        styles.input,
        valid,
        invalid,
    ].join(" ");

    return (
        <div className={styles["input-container"]}>
            <input
                id={id}
                name={name}
                type={type}
                required={required}
                disabled={disabled}
                placeholder={placeholder}
                className={`${defaultClassName} ${className}`}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
            />
        </div>
    );
};

Input.defaultProps = {
    placeholder: "Digite",
    type: "text",
    disabled: false,
    required: false,
    className: "",
    isValid: false,
    isInvalid: false,
};

Input.propTypes = {
    id: P.string.isRequired,
    name: P.string.isRequired,
    type: P.string,
    className: P.string,
    placeholder: P.string,
    disabled: P.bool,
    required: P.bool,
    onChange: P.func,
    onBlur: P.func,
    isValid: P.bool,
    isInvalid: P.bool,
};
