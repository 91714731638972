import styles from "./SectionHistorico.module.scss";
import { Text } from "../../ui/Text";
import { Heading } from "../../ui/Heading";
import { FigureImageColaborador } from "./FigureImageColaborador";
import { Divider } from "../../ui/Divider";

export const SectionHistorico = () => (
    <section className={`${styles["section-gerencionamento"]}`}>
        <div
            className={`${styles["seja-protagonista"]} container-fluid text-center`}
        >
            <Heading tag="h4" color="secondary" className="mb-3">
                Seja <span>protagonista</span> da sua jornada de saúde.
            </Heading>
            <Text
                type="title"
                tag="p"
                size="3"
                color="secondary"
                className={
                    styles[
                        "section-gerencionamento--container__content_sub-title_paragraph"
                    ]
                }
            >
                Saiba mais
            </Text>
        </div>

        <div className={styles["section-historico--container"]}>
            <div
                className={
                    styles[
                        "section-gerencionamento--container__content_sub-title"
                    ]
                }
            >
                <Heading
                    tag="h4"
                    color="secondary"
                    className={`${styles["section-gerencionamento--container__content_sub-title_heading"]} m-0`}
                >
                    Seu histórico de saúde na palma da mão.
                </Heading>
                <Text
                    type="title"
                    tag="span"
                    size="1"
                    color="secondary"
                    className={
                        styles[
                            "section-gerencionamento--container__content_sub-title_paragraph"
                        ]
                    }
                >
                    Armazene suas prescrições, resultados de exames e
                    prontuários, e compartilhe seus dados com segurança.
                </Text>
            </div>
            <FigureImageColaborador
                className={styles["img-moca"]}
            ></FigureImageColaborador>
        </div>

        <div
            className={`${styles["section-historico--container"]} ${styles["img-left"]}`}
        >
            <FigureImageColaborador
                className={styles["img-colaboradores"]}
                imageName="img-colaboradores-startup"
            ></FigureImageColaborador>
            <div
                className={
                    styles[
                        "section-gerencionamento--container__content_sub-title"
                    ]
                }
            >
                <Heading
                    tag="h4"
                    color="secondary"
                    className={`${styles["section-gerencionamento--container__content_sub-title_heading"]} m-0`}
                >
                    Você escolhe: saúde para todo tipo de rotina.
                </Heading>
                <Text
                    type="title"
                    tag="p"
                    size="2"
                    color="secondary"
                    className={
                        styles[
                            "section-gerencionamento--container__content_sub-title_paragraph"
                        ]
                    }
                >
                    Selecione quais serviços de saúde mais combinam com o seu
                    estilo de vida e comece a sua revolução.
                </Text>
            </div>
        </div>

        <div className={styles["section-historico--container"]}>
            <div
                className={
                    styles[
                        "section-gerencionamento--container__content_sub-title"
                    ]
                }
            >
                <Heading
                    tag="h4"
                    color="secondary"
                    className={`${styles["section-gerencionamento--container__content_sub-title_heading"]} m-0`}
                >
                    Descontos em consultas, exames e em medicamentos.
                </Heading>
                <Text
                    type="title"
                    tag="p"
                    size="2"
                    color="secondary"
                    className={
                        styles[
                            "section-gerencionamento--container__content_sub-title_paragraph"
                        ]
                    }
                >
                    Maior acessibilidade a serviços e produtos de saúde com
                    descontos que cabem no seu bolso.
                </Text>
            </div>
            <FigureImageColaborador
                className={styles["img-consulta-presencial"]}
                imageName="img-consulta-presencial"
            ></FigureImageColaborador>
        </div>
        <Divider></Divider>
    </section>
);
