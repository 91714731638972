import styles from "./SectionFacaParte.module.scss";

import { useState } from "react";

import { Button, Heading } from "../../ui";
import { ModalVemFazerParte } from "../ModalVemFazerParte";

export const SectionFacaParte = ({
    origin
}) => {
    const [showModal, setShowModal] = useState(false);

    const handleClickModal = () => {
        setShowModal(true);
    };

    const arrow = (
        <img
            src="/icons/profissionais/icon-arrows-down-color_secondary.png"
            alt="doutor123 profissionais"
        />
    );

    const data = [
        {
            id: 1,
            title: "Abaixo você encontra o nosso formulário de interesse. Preencha com todas as informações e aguarde o contato do nosso time de credenciamento.",
            img: arrow,
        },
        {
            id: 2,
            title: "Nesta etapa, realizaremos a sua triagem e validação dos dados enviados.",
            img: arrow,
        },
        {
            id: 3,
            title: "Caso seu cadastro seja aprovado, você receberá o contrato para assinatura e o dados para acesso a nossa plataforma, já com todas as funcionalidades disponíveis para utilização.",
        },
    ];

    return (
        <section className={styles.section_faca_parte}>
            <div className={styles.section_faca_parte__container}>
                <Heading
                    tag="h3"
                    color="secondary"
                    className={styles.section_faca_parte__container__title}
                >
                    Que tal entrar nessa e ajudar a alcançar pessoas que buscam
                    por saúde de qualidade? <br />A gente te mostra o caminho:
                </Heading>

                <article
                    className={styles.section_faca_parte__container__article}
                >
                    {data.map((item) => (
                        <div
                            className={
                                styles.section_faca_parte__container__article__content
                            }
                            key={item.id}
                        >
                            <div
                                className={
                                    styles.section_faca_parte__container__article__content__card
                                }
                            >
                                <div
                                    className={
                                        styles.section_faca_parte__container__article__content__card__container
                                    }
                                >
                                    <Heading tag="h4" color="secondary">
                                        {item.title}
                                    </Heading>
                                </div>
                            </div>

                            {item.img}
                        </div>
                    ))}
                </article>

                <figure>
                    <img
                        src="/images/img-resultados.png"
                        alt="doutor123 resultados"
                    />
                </figure>

                <article
                    id="article_faca_parte"
                    className={
                        styles.section_faca_parte__container__article_form_modal
                    }
                >
                    <Heading tag="h2" color="secondary">
                        Faça parte da nossa rede sem nenhum custo!
                    </Heading>

                    <Button
                        text="Tenho interesse"
                        color="purple"
                        small={true}
                        onClick={handleClickModal}
                        type="button"
                    />

                    <ModalVemFazerParte
                        origin={origin}
                        show={showModal}
                        onClose={() => {
                            setShowModal(false);
                        }}
                    />
                </article>
            </div>
        </section>
    );
};
