import styles from "./Icons.module.scss";

export const IconDadosConsulta = () => (
    <i className={styles["icons-dado-consulta"]}>
        <img
            alt="doutor123 dados consulta"
            src="/icons/icon-dados-consulta.png"
        ></img>
    </i>
);
