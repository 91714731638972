import styles from "./Navbar.module.scss";
import P from "prop-types";
import { Logo } from "../../ui/Logo";
import { Button } from "../../ui/Button";
import { DropdownMenu } from "./DropdownMenu";
import { useEffect, useState } from "react";
import { appConfig } from "../../../config";
import { Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const Navbar = ({ className }) => {
    const [showDropdownMenu, setShowDropdownMenu] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        setShowDropdownMenu(false);
    }, [pathname]);

    return (
        <nav
            className={`navbar d-flex flex-nowrap align-items-center ${styles["nav"]} ${className}`}
        >
            <Link to="/" className="navbar-brand">
                <Logo></Logo>
            </Link>
            <ul className={`navbar-nav ${styles["nav-actions"]}`}>
                <li className="nav-item">
                    <NavLink to="/saude-pra-voce" href="#">
                        Saúde Pra Você
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/para-empresas" href="#">
                        Para Empresas
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/para-profissionais" href="#">
                        Para Profissionais da Saúde
                    </NavLink>
                </li>
                <li>
                    <Button
                        color="secondary-purple"
                        className={`nav-item ${styles["acess-app-button"]}`}
                        text="Acessar aplicativo"
                        small={false}
                        onClick={() => (window.location = appConfig.appUrl)}
                    ></Button>
                </li>
            </ul>
            <button
                className={styles["dropdown-menu-button"]}
                onClick={() => setShowDropdownMenu(!showDropdownMenu)}
            >
                <img src="/images/img-rectangle.png" alt="" />
                <img src="/images/img-rectangle.png" alt="" />
                <img src="/images/img-rectangle.png" alt="" />
            </button>
            {showDropdownMenu && <DropdownMenu></DropdownMenu>}
        </nav>
    );
};

Navbar.defaultProps = {
    className: "",
};

Navbar.propTypes = {
    className: P.string,
};
