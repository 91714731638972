import React from "react";
import styles from "./SectionTriade.module.scss";
import { Heading } from "../../ui";

function SectionTriade() {
    return (
        <section className={styles["section-triade"]}>
            <img src="images/img-triade.png" alt="" />
            <Heading tag="h4">Saúde física, mental e social.</Heading>
        </section>
    );
}

export default SectionTriade;
