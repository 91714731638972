import styles from "./Icons.module.scss";

export const IconAtividadeFisica = () => (
    <i className={styles["icons"]}>
        <img
            alt="doutor123 atividade fisica"
            src="/icons/icon-atividade-fisica.svg"
        ></img>
    </i>
);
