import styles from "./FigureIconServicoSaude.module.scss";
import P from "prop-types";

import { IconServicoSaude } from "../../../icons/IconServicoSaude";
import { Text } from "../../Text";

export const FigureIconServicoSaude = ({ children, className, column }) => (
    <figure
        className={`${styles["figure-icon-saude"]} ${className} ${styles[ValidColumn(column)]}`}
    >
        <IconServicoSaude></IconServicoSaude>
        <Text tag="small" type="text" size="2" color="primary">
            <b>{children}</b>
        </Text>
    </figure>
);

FigureIconServicoSaude.propTypes = {
    className: P.string,
    children: P.string,
    column: P.bool,
};

FigureIconServicoSaude.defaultProps = {
    className: "",
    column: true,
};

const ValidColumn = (column) => (column ? "column" : "row");
