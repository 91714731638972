import "./styles/global.scss";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import App from "./App";
import { MainLayout } from "./layouts/Main";
import { Layout } from "./components/common/Layout";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Layout>
                <MainLayout>
                    <App />
                    <ToastContainer />
                </MainLayout>
            </Layout>
        </BrowserRouter>
    </React.StrictMode>,
);
