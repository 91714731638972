import styles from "./FigureColaboradoresStartup.module.scss";

export const FigureColaboradoresStartup = () => (
    <figure className={styles["figure__image-capa"]}>
        <img
            alt="colacoradores startup"
            src="/images/img-colaboradores-startup.png"
        ></img>
    </figure>
);
