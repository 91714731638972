import styles from "./SectionTecnologia.module.scss";

import { Heading, Text } from "../../ui";

export const SectionTecnologia = () => {
    const textArrow = (
        <Text tag="span" size="0" color="secondary-support">
            {">>>"}
        </Text>
    );

    const data = [
        {
            id: 1,
            title: <>Nosso app {textArrow}</>,
            body: (
                <>
                    A melhor experiência para o seu paciente realizar o
                    agendamentos na palma da mão, já disponível nas loja Apple e
                    PlayStore.
                </>
            ),
        },
        {
            id: 2,
            title: (
                <>Acesse o histórico de saúde de seus pacientes {textArrow}</>
            ),
            body: (
                <>
                    Quando autorizado pelo paciente, basta digitar o código
                    informado por ele durante a consulta para visualizar
                    atendimentos anteriores, prescrições, tratamentos,
                    resultados de exames e alergias.
                </>
            ),
        },
        {
            id: 3,
            title: <>Mais tecnologia pra você! {textArrow}</>,
            body: (
                <>
                    Otimize o atendimento com todos os recursos da nossa
                    plataforma: Telemedicina, receituário, atestado e pedido de
                    exame.
                </>
            ),
        },
    ];

    return (
        <section className={styles.section_tecnologia}>
            <div className={styles.section_tecnologia__container}>
                <figure></figure>

                <article
                    className={styles.section_tecnologia__container__title}
                >
                    <Heading tag="h3" color="secondary">
                        A tecnologia que permite que você acompanhe a jornada de
                        saúde dos seus pacientes por completo.
                    </Heading>
                </article>

                <article
                    className={styles.section_tecnologia__container__lista}
                >
                    {data.map((item) => (
                        <div
                            key={item.id}
                            className={
                                styles.section_tecnologia__container__lista__card
                            }
                        >
                            <div
                                className={
                                    styles.section_tecnologia__container__lista__card__content
                                }
                            >
                                <Heading tag="h4" color="secondary">
                                    {item.title}
                                </Heading>

                                <Text
                                    tag="span"
                                    color="secondary"
                                    type="title"
                                    size="1"
                                >
                                    {item.body}
                                </Text>
                            </div>
                        </div>
                    ))}
                </article>
            </div>
        </section>
    );
};
