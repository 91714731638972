
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import styles from './Rating.module.scss';
import api from '../../service/axios';
import Question from '../../components/ratings/Question';
import Successful from '../../components/ratings/Successful';
import { MiniLoader } from '../../components/ui';
import Answered from '../../components/ratings/Answered';

const RatingPage = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [renderComponent, setRenderComponent] = useState('submit' | 'form' | 'answered');
    const [product, setProduct] = useState([]);
    const [consultation, setConsultation] = useState(null);
    const [service, setService] = useState({
        types: [''],
        specialty: [''],
        services: [''],
        consultation:
        {
            service_patient: {
                name: ''
            }
        }
    });
    const [patientValue, setPatient] = useState({
        user: '',
        patient: '',
        name: ''
    });

    const getAsync = async () => {
        if (!id) return;

        await api.get(`/rating/${id}`).then((response) => {
            let {
                status,
                data
            } = response;
            
            status = status
                || response.response
                || null;

            if (status !== 200 && !data) return;

            let products = [];
            const {
                user,
                patient,
                consultation,
                cart
            } = data;
            const { items } = cart || [];

            if (cart) {
                products = [
                    ...items.map(item => {
                        const {
                            service,
                            doctor
                        } = item;

                        return {
                            type: cart?.type,
                            name: doctor?.specialty?.name
                                || service?.specialty?.name
                                || null
                        }
                    })
                ];
            }

            if (consultation) {
                const { doctor: { specialty } } = consultation || null;
                const productConsultation = {
                    type: 'consultation',
                    name: specialty?.name || null
                };
                products = [...products, productConsultation];
            }
            
            // products.push(consultation
            //     ? [consultation]
            //     : items
            //     || []);
            const name = products[0]?.service_patient?.name;

            setConsultation(consultation || null);
            setPatient({
                user: user,
                patient: patient,
                name: name
            });
            setProduct(products?.map(item => {
                let name = '';
                const {
                    service,
                    doctor
                } = item;

                name = doctor?.specialty?.name
                    || service?.specialty?.name
                    || null;

                return !name ? '' : name;
            }));

            setService(products);
        }).catch(responseError => {
            const {
                status,
                data: { message }
            } = responseError?.response || '';

            if (status === 406
                && message?.includes('Essa avaliação já foi respondida')) {
                setRenderComponent('answered');
            }
        }).finally(() => setLoading(false));
    }

    const createComponents = (validate) => {
        switch (validate) {
            case 'submit': return <Successful />;
            case 'answered': return <Answered />;

            case 'form':
            default: return <Question
                //consultation={consultation}
                service={service}
                setRenderComponent={setRenderComponent}
                id={id}
                itemsCart={product}
                patient={patientValue || {}}
            />
        }
    }
    useEffect(() => {
        getAsync();
    }, []);

    if (!id) return <></>;

    const Commponent = createComponents(renderComponent);

    const stylesScss = [
        styles.container,
        "container-fluid"
    ].join(' ');

    return (
        <div className={stylesScss}>
            {loading ? <MiniLoader loading={loading} /> : Commponent}
        </div>
    )
}

export default RatingPage;
