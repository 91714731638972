export const formatPhone = (value) => {
    const onlyNumbers = value.replace(/[^\d]/g, "");
    let formattedValue = "";

    if (onlyNumbers.length > 0) {
        if (onlyNumbers.length > 2) {
            formattedValue = `(${onlyNumbers.slice(0, 2)}) `;

            if (onlyNumbers.length === 11 && onlyNumbers.charAt(2) === "9") {
                formattedValue += `${onlyNumbers.slice(3, 7)}-${onlyNumbers.slice(7)}`;
            } else {
                formattedValue += `${onlyNumbers.slice(2, 6)}-${onlyNumbers.slice(6)}`;
            }
        } else {
            formattedValue = `(${onlyNumbers.slice(0, 2)}`;
        }
    }

    return formattedValue;
};

export const validatePhone = (value) => {
    const phoneRegex = /^\(\d{2}\) (?:9\s?)?\d{4}-\d{4}$/;
    return phoneRegex.test(value);
};
