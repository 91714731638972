import styles from "./FigureImageColaborador.module.scss";
import P from "prop-types";

export const FigureImageColaborador = ({ className, imageName }) => (
    <figure className={`${styles["figure__image-capa"]} ${className}`}>
        <img alt="moça" src={`/images/saude-pra-voce/${imageName}.png`}></img>
    </figure>
);

FigureImageColaborador.defaultProps = {
    className: "",
    imageName: "img-moca",
};

FigureImageColaborador.propTypes = {
    className: P.string,
    imageName: P.string,
};
