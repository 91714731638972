export const formatCpf = (cpf) => {
    if (!cpf) return;

    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/^(\d{3})(\d)/g, "$1.$2");
    cpf = cpf.replace(/^(\d{3})\.(\d{3})(\d)/g, "$1.$2.$3");
    cpf = cpf.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d{1,2})/g, "$1.$2.$3-$4");

    return cpf;
};

export const validateLengthCpf = (value) =>
    value.length === 11 && /^\d{11}$/.test(value);

export const validateCpf = (value) => {
    let cpfValido = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2})|([0-9]{11}))$/;

    return cpfValido.test(value);
};
