import styles from "./Icons.module.scss";

export const IconX = () => (
    <i className={styles["icons-"]}>
        <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.06058 4.84432L9.02933 1.87557C9.17023 1.73492 9.24948 1.54406 9.24966 1.34498C9.24983 1.1459 9.17092 0.954902 9.03027 0.814006C8.88962 0.67311 8.69876 0.593856 8.49968 0.593681C8.3006 0.593505 8.1096 0.672421 7.96871 0.813069L4.99996 3.78182L2.03121 0.813069C1.89031 0.672172 1.69922 0.593018 1.49996 0.593018C1.3007 0.593018 1.1096 0.672172 0.968708 0.813069C0.827812 0.953965 0.748657 1.14506 0.748657 1.34432C0.748657 1.54358 0.827812 1.73467 0.968708 1.87557L3.93746 4.84432L0.968708 7.81307C0.827812 7.95396 0.748657 8.14506 0.748657 8.34432C0.748657 8.54358 0.827812 8.73467 0.968708 8.87557C1.1096 9.01646 1.3007 9.09562 1.49996 9.09562C1.69922 9.09562 1.89031 9.01646 2.03121 8.87557L4.99996 5.90682L7.96871 8.87557C8.1096 9.01646 8.3007 9.09562 8.49996 9.09562C8.69922 9.09562 8.89031 9.01646 9.03121 8.87557C9.1721 8.73467 9.25126 8.54358 9.25126 8.34432C9.25126 8.14506 9.1721 7.95396 9.03121 7.81307L6.06058 4.84432Z"
                fill="#4C2450"
            />
        </svg>
    </i>
);
