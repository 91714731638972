import { SectionHistorico } from "../../components/saude-pra-voce/SectionHistorico";
import { SectionMain } from "../../components/saude-pra-voce/SectionMain";
import { SectionBaixeApp } from "../../components/saude-pra-voce/SectionBaixeApp";
import { SectionBeneficios } from "../../components/saude-pra-voce/SectionBeneficios";
import { SectionPacotes } from "../../components/saude-pra-voce/SectionPacotes";
import { SectionComeceJornada } from "../../components/saude-pra-voce/SectionComeceJornada";

export default function SaudePraVoce() {
    return (
        <>
            <SectionMain></SectionMain>
            <SectionHistorico></SectionHistorico>
            <SectionBaixeApp></SectionBaixeApp>
            <SectionBeneficios></SectionBeneficios>
            <SectionPacotes></SectionPacotes>
            <SectionComeceJornada></SectionComeceJornada>
        </>
    );
}
