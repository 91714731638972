import styles from "./Logo.module.scss";
import P from "prop-types";

export const Logo = ({
    className,
    purple
}) => {

    const stylesScss = [
        styles["widget-logo"],
        className
    ].join(' ');

    return (
        <div className={stylesScss}>
            <img
                alt="logo doutor123"
                src={!purple ? "/images/logo-doutor123.png" : "/images/doutor123--purple 2.png"}
                onClick={() => (window.location = "/#")}
            />
        </div>
    );
}

Logo.propTypes = {
    className: P.string,
    purple: P.bool
};

Logo.defaultProps = {
    className: "",
    purple: false
};
