import styles from "./ModalVemFazerParte.module.scss";
import React, { useState } from "react";

import P from "prop-types";

import { Button, Heading, Modal, Text } from "../../ui";
import { FormularioVemFazerParte } from "../FormularioVemFazerParte/FormularioVemFazerParte";

export const ModalVemFazerParte = ({ 
    show, 
    onClose,
    origin
}) => {
    const [submit, setSubmit] = useState(false);

    const handlerSubmit = () => {
        setSubmit(true);
    };

    const figure = (
        <figure>
            <img
                src="/images/img-mundo-tecnologia.png"
                alt="doutor123 tecnologias"
            />
        </figure>
    );

    return (
        <Modal
            show={show}
            size="xlarge"
            onClose={() => {
                onClose();
            }}
        >
            <div
                className={`${styles.modal_vem_fazer_parte} ${!submit || styles.modal_vem_fazer_parte_submit}`}
            >
                {!submit ? (
                    <>
                        <article
                            className={
                                styles.modal_vem_fazer_parte__article_title
                            }
                        >
                            <Heading tag="h3" color="primary">
                                Vem fazer parte da nossa rede!
                            </Heading>

                            <Heading tag="h6" color="white">
                                Basta preencher os dados e aguardar, o nosso
                                time entrará em contato.
                            </Heading>

                            {figure}
                        </article>

                        <FormularioVemFazerParte
                            origin={origin}
                            handlerSubmit={handlerSubmit}
                        />
                    </>
                ) : (
                    <article
                        className={styles.modal_vem_fazer_parte__article_submit}
                    >
                        {figure}

                        <div
                            className={
                                styles.modal_vem_fazer_parte__article_submit__title
                            }
                        >
                            <Heading tag="h3" color="primary">
                                Muito obrigado!
                            </Heading>

                            <Text tag="span" color="white" size="1" type="text">
                                Em breve retornaremos o seu contato.
                            </Text>
                        </div>

                        <div
                            className={
                                styles.modal_vem_fazer_parte__article_submit__footer
                            }
                        >
                            <Button
                                text="Fechar"
                                color="primary"
                                type="submit"
                                small={false}
                                onClick={() => onClose()}
                                className={
                                    styles.modal_vem_fazer_parte__article_submit__footer__button
                                }
                            />
                        </div>
                    </article>
                )}
            </div>
        </Modal>
    );
};

ModalVemFazerParte.propTypes = {
    show: P.bool,
    onClose: P.func,
};
