import { Text } from "../../../ui";
import style from "./SlideRecomendacao.module.scss";

export function SlideRecomendacao(props) {
    return (
        <div className={style["slide-item"]}>
            <section className={style["slide-item__container"]}>
                <img
                    src={props.icon}
                    alt="Recomendação"
                    className={style["slide-item__container__figure"]}
                />

                <Text
                    color="secondary"
                    className={style["slide-item__container__text"]}
                >
                    {props.text}
                </Text>

                <div className={style["slide-item__container__client"]}>
                    <p>{props.client.name}</p>
                    <p>{props.client.occupation}</p>
                    <p>{props.client.company}</p>
                </div>
            </section>
        </div>
    );
}
