import styled from "./Institutional.module.scss";

export function TermsAndConditions() {
    return (
        <div className={styled["container"]}>
            <main className={styled["content"]}>
                <h1>Termos e condições Usuários finais</h1>

                <h2>Condições gerais de cadastramento e contratação</h2>

                <p>
                    1. Para acessar e utilizar este site (www.doutor123.com.br)
                    por qualquer meio, inclusive por meio de aplicativo em
                    dispositivos móveis (o “Site”), seu usuário (o “Usuário”)
                    deverá ter no mínimo 18 (dezoito) anos de idade, ou ter
                    autorização por escrito de seus responsáveis legais para
                    efetuar seu respectivo cadastro. Atendendo a esses
                    requisitos e acessando o Site, o Usuário automaticamente
                    manifesta plena aceitação em relação a todos os termos e as
                    condições ora estabelecidos, e concorda que estes consistem
                    no acordo integral (o “Acordo”) entre ele e a sociedade
                    DOUTOR123 TECNOLOGIA EM AGENDAMENTO DE CONSULTAS E EXAMES
                    S.A., inscrita no CNPJ sob nº 26.056.055/0001-07, com sede
                    na Rua Cubatão, 86, conj. 1501, na cidade de São Paulo,
                    Estado de São Paulo, CEP 01452-002, doravante denominada
                    simplesmente “ DOUTOR123”, o qual consiste no detentor de
                    todos os direitos sobre o Site
                </p>

                <div>
                    <p>
                        1.1. Para o cadastro no Site, o Usuário deverá fornecer
                        alguns dados pessoais e cadastrar uma senha pessoal.
                        Para ativação desse cadastro, o Usuário deverá inserir
                        um código enviado por e-mail e/ou SMS.
                    </p>
                </div>

                <p>
                    2. É reservado exclusivamente ao DOUTOR123 o direito de
                    modificar o Acordo, bem como interromper ou suspender o
                    acesso ao Site a qualquer tempo, sem necessidade de prévio
                    aviso.
                </p>
                <p>
                    3. O Usuário deverá se manter atualizado em relação às
                    modificações referentes ao Acordo, acessando-o
                    periodicamente por meio do Site.
                </p>
                <p>
                    4. É reservado exclusivamente ao DOUTOR123 o direito de
                    impedir o acesso do Usuário ao Site, a qualquer tempo e sem
                    prévia notificação, provisória ou definitivamente, caso
                    sejam violados os termos e condições do Acordo, outras
                    instruções de uso presentes no Site e/ou norma legal
                    aplicável, sem prejuízo da responsabilidade do Usuário
                    decorrente da respectiva violação. Da mesma forma, consiste
                    em direito irrenunciável do Usuário cancelar o seu
                    cadastramento no Site a qualquer tempo e sem qualquer custo.
                </p>
                <p>
                    5. O nome de usuário e a senha de acesso ao Site são de uso
                    exclusivo do Usuário e beneficiários, quando aplicável, que
                    se compromete a manter tais informações como confidenciais
                    e, consequentemente, impedir que terceiros acessem o Site
                    utilizando tais informações.
                </p>
                <div>
                    <p>
                        5.1. O Usuário deverá informar imediatamente o DOUTOR123
                        em caso de extravio das informações ou de acesso de
                        terceiros não autorizados, bem como sobre qualquer outra
                        suspeita ou conhecimento acerca de falha de segurança do
                        Site.
                    </p>
                </div>
                <p>
                    6. O Usuário deverá manter suas informações cadastrais
                    fornecidas ao Site sempre atualizadas, completas,
                    fidedignas, exatas e verdadeiras, responsabilizando-se
                    integralmente pelo conteúdo de tais informações.
                </p>

                <h2>Escopo</h2>

                <p>
                    7. O Site não tem por objeto a publicidade e prestação de
                    serviços médicos diretos; tão somente consiste em ferramenta
                    de intermediação entre o Usuário, profissionais da saúde,
                    consultórios, clínicas, laboratórios e hospitais nele
                    cadastrados (em conjunto, os “Prestadores” ou,
                    individualmente, o “Prestador”), a ser utilizada
                    estritamente para o agendamento e pagamento de consultas,
                    exames e outros atendimentos de saúde. Ademais, o Site
                    disponibiliza tecnologia para consultas virtuais, na
                    modalidade de telemedicina. Dessa forma, o Site
                    disponibilizará ao Usuário informações gerais a respeito dos
                    Prestadores, bem como funcionalidades de agendamento e
                    intermediação de pagamentos por consultas, exames e outros
                    atendimentos na área da saúde, e também funcionalidades que
                    viabilizam atendimentos na modalidade de telemedicina.
                </p>
                <p>
                    8. O Doutor123, na qualidade de mero intermediador de
                    serviços, jamais será responsabilizado por quaisquer fatos
                    ou atos resultantes direta ou indiretamente das contratações
                    de serviços intermediadas por meio do Site, incluindo, mas
                    sem se limitar a: erro médico, negligência médica, erros em
                    exames, implicações tributárias envolvendo os serviços de
                    saúde prestados, descumprimento de normas de órgãos de
                    classe, má qualidade de atendimento, insuficiência de
                    informações, bem como danos de qualquer natureza causados
                    por Prestadores, inclusive danos morais e lucros cessantes.
                </p>
                <div>
                    <p>
                        8.1. Ainda que o DOUTOR13, por mera liberalidade, venha
                        a configurar alertas com recomendações gerais ao Usuário
                        antes de consultas na modalidade de telemedicina, é
                        sempre obrigação única e exclusiva dos Prestadores
                        cumprirem as normas aplicáveis a esses atendimentos
                        determinadas pelos competentes órgão de classe, tais
                        como: impossibilidade de atendimento em telemedicina
                        dependendo da natureza da situação do paciente, etc.
                    </p>
                </div>
                <p>
                    9. O DOUTOR123 jamais será responsável perante o Usuário
                    pelas informações e avaliações constantes no Site acerca dos
                    Prestadores, e nem, tampouco, pelo grau de experiência
                    profissional e qualificação deles. O DOUTOR123 apenas se
                    compromete a envidar seus melhores esforços para credenciar
                    Prestadores aptos a executar atividades médicas por meio de
                    validação de CRM ativo e outras informações profissionais
                    informadas no cadastro dos Prestadores. Da mesma forma,
                    jamais poderá ser responsabilizado pelas consequências
                    decorrentes do descumprimento das obrigações relacionadas às
                    consultas agendadas por meio do Site, incluindo cancelamento
                    e atrasos.
                </p>
                <p>
                    10. O DOUTOR123 não é responsável pela definição e variação
                    de preços de consultas, exames e demais serviços de saúde,
                    sendo essa atribuição responsabilidade exclusiva dos
                    Prestadores. O Site tão somente define a taxa de
                    intermediação cobrada do Usuário por atendimento agendado,
                    quando aplicável.
                </p>
                <p>
                    11. O Usuário concorda e reconhece que, pelos mais diversos
                    fatores, e principalmente por se tratar de um ambiente
                    eletrônico e virtual condicionado ao pleno acesso à Internet
                    por parte do Usuário e dos Prestadores, o Site poderá
                    apresentar falhas, defeitos, incorreções e/ou interrupções,
                    razão pela qual o DOUTOR123 não garante o pleno
                    funcionamento e a plena disponibilidade do site, não se
                    responsabilizando por eventuais danos causados ao usuário em
                    decorrência de tal circunstância, seja de que natureza for,
                    inclusive danos decorrentes de eventual cancelamento de
                    consultas por falhas do Site. O DOUTOR123 tão somente se
                    compromete a adotar boas práticas de Tecnologia da
                    Informação e envidar seus melhores esforços para sanar
                    qualquer anormalidade no funcionamento do Site dentro da
                    menor brevidade possível.
                </p>
                <p>
                    12. O Usuário é exclusivamente responsável, perante o
                    DOUTOR123 e/ou terceiros, por todas as práticas diretas e
                    indiretas envolvendo o uso do Site, incluindo o pagamento
                    por consultas, exames e outros atendimentos de saúde.
                </p>

                <h2>
                    Instruções gerais para agendamento de consultas, exames e
                    outros atendimentos de saúde
                </h2>

                <p>
                    3. O agendamento de atendimentos por meio do Site seguirá as
                    seguintes etapas consecutivas:
                </p>

                <ul>
                    <li>
                        <p>
                            - Uma vez cadastrado, o Usuário deverá realizar o
                            login, utilizando o e-mail e senha de acesso;
                        </p>
                    </li>
                    <li>
                        <p>
                            - O Usuário buscará os Prestadores, informando a
                            especialidade e região próxima à qual deseja
                            encontrá-los; se o usuário estiver buscando exames,
                            informará critérios para cotação, tais como:
                            localização e prioridade (melhor preço, distância ou
                            período);
                        </p>
                    </li>
                    <li>
                        <p>
                            - Na hipótese de exame, o Usuário deverá enviar o
                            arquivo digitalizado ou foto legível do pedido de
                            exame correspondente, selecionando um dos meios de
                            transmissão de arquivos disponibilizados pelo Site;
                        </p>
                    </li>
                    <li>
                        <p>
                            - Com base na respectiva pesquisa, o Site
                            apresentará uma lista de Prestadores cadastrados,
                            indicando suas respectivas disponibilidades de
                            agenda, sendo o critério de ordenamento dos
                            Prestadores definido exclusivamente pelo Site;
                        </p>
                    </li>
                    <li>
                        <p>
                            - O Usuário escolherá o Prestador e, em se tratando
                            de atendimento agendado, o horário de agenda,
                            disponibilizada pelo Prestador, que desejar,
                            passando à tela de fornecimento de dados;
                        </p>
                    </li>
                    <li>
                        <p>
                            - O Usuário será encaminhado para a tela de
                            pagamento;
                        </p>
                    </li>
                    <li>
                        <p>
                            - O Usuário pagará o valor do atendimento
                            estabelecido pelo Prestador, acrescido da taxa de
                            intermediação, referente à utilização do Site,
                            quando aplicável. Se o Usuário optar por realizar o
                            pagamento por boleto bancário, só poderá agendar o
                            atendimento a partir de 5 (cinco) dias úteis após a
                            data de agendamento no Site Doutor123.
                        </p>
                    </li>
                    <li>
                        <p>
                            - O agendamento ou a disponibilização de cada
                            atendimento só será confirmado mediante comprovação
                            de pagamento; caso não haja pagamento confirmado, o
                            agendamento será automaticamente cancelado, sem
                            qualquer prejuízo ao Usuário;
                        </p>
                    </li>
                    <li>
                        <p>
                            - A confirmação do agendamento e o código de
                            identificação do atendimento serão enviados para o
                            Usuário por e-mail e/ou SMS.
                        </p>
                    </li>
                </ul>

                <h2>
                    Reagendamento ou cancelamento de consultas, exames e outros
                    atendimentos de saúde
                </h2>

                <p>
                    14. O Usuário poderá reagendar e/ou desmarcar
                    definitivamente o atendimento – consulta, exame ou outro
                    atendimento de saúde - por meio do Site, em até 48 (quarenta
                    e oito) horas antes da respectiva data agendada.
                </p>

                <div>
                    <p>
                        14.1. Na hipótese de desistência com até 48 (quarenta e
                        oito) horas de antecedência da respectiva data agendada,
                        será estornado ao Usuário o valor do atendimento, no
                        prazo máximo de 5 (cinco) dias úteis do pedido de
                        estorno. Em nenhuma hipótese haverá estorno da taxa de
                        intermediação paga em favor do Site, já que o simples
                        agendamento por meio do Site enseja a efetivação da
                        prestação dos serviços nele disponibilizados, e a
                        incidência da referida taxa de intermediação consiste em
                        contraprestação devida para a cobertura dos custos e das
                        despesas de administração e manutenção do Site.
                    </p>
                    <div>
                        <p>
                            14.1.1 . Na hipótese de estorno do valor do
                            atendimento – consulta, exame ou outro atendimento
                            de saúde - pago pelo Usuário mediante boleto
                            bancário, o Usuário deverá informar seus dados
                            bancários para que o estorno seja concluído.
                        </p>
                    </div>
                    <p>
                        14.2. Caso a desistência ocorra após o prazo mínimo de
                        48 (quarenta e oito) horas antes de cada atendimento
                        agendado, o Usuário perderá o direito ao estorno do
                        valor do atendimento e só poderá reagendar o atendimento
                        com o consentimento do Prestador.
                    </p>
                </div>

                <p>
                    15. Na hipótese de atendimentos disponibilizados por
                    Prestadores sem necessidade de agendamento, o Usuário poderá
                    cancelá-los por meio do Site em até 7 (sete) dias após a
                    respectiva compra. Nesse caso, será estornado ao Usuário o
                    valor do atendimento, no prazo máximo de 5 (cinco) dias
                    úteis a partir da solicitação de cancelamento do Usuário, e
                    em nenhuma hipótese haverá estorno da respectiva taxa de
                    intermediação paga em favor do Site, já que o simples
                    agendamento do exame por meio do Site enseja a efetiva
                    prestação dos serviços nele disponibilizados, e a referida
                    taxa de intermediação consiste em contraprestação devida
                    para a cobertura dos custos e das despesas de administração
                    e manutenção do Site.
                </p>
                <p>
                    16. Se o Prestador não tiver disponibilidade para realizar o
                    atendimento agendado, deverá acessar o Site dentro da menor
                    brevidade possível a fim de informar tal indisponibilidade.
                    Nesse caso, o DOUTOR123 entrará em contato com o Usuário
                    para reagendamento da data ou cancelamento do atendimento
                    mediante reembolso do valor do atendimento sem o estorno da
                    taxa de intermediação paga em favor do Site, já que o
                    simples agendamento por meio do Site enseja a efetivação da
                    prestação dos serviços nele disponibilizados.
                </p>

                <h2>
                    Reagendamento ou cancelamento de consultas, exames e outros
                    atendimentos de saúde
                </h2>

                <p>
                    17. O Usuário deverá comparecer ao estabelecimento do
                    Prestador sempre munido de documento de identidade, pedido
                    de exame (quando aplicável) e código de confirmação do
                    atendimento enviado por e-mail e/ou SMS.
                </p>

                <div>
                    <p>
                        17.1. Na data do atendimento agendado, o Usuário deverá
                        comparecer ao estabelecimento do Prestador com
                        antecedência mínima de 30 (trinta) minutos. Nos
                        atendimentos na modalidade de telemedicina, Usuário
                        deverá, no horário agendado, clicar em link de acesso
                        para a sala virtual de consulta e, antes do início da
                        consulta, informar ao Prestador o respectivo código
                        token.
                    </p>
                    <p>
                        17.2. Caso o Usuário tenha perdido o código de
                        confirmação, poderá fornecer o seu CPF para o Prestador,
                        e este poderá consultar através dos e-mails de
                        confirmação encaminhados pelo DOUTOR123 e por meio da
                        sua área logada no Site.
                    </p>
                    <p>
                        17.3. Todos e quaisquer exames não agendáveis deverão
                        ser realizados dentro do prazo de validade estabelecidos
                        por cada Prestador.
                    </p>
                    <p>
                        17.4. Na hipótese de realização de exames que ensejem
                        preparo específico (ex.: jejum), o Usuário será
                        integralmente responsável por se informar sobre tal
                        preparo e realizá-lo. Por mera liberalidade, o DOUTOR123
                        tão somente se compromete a envidar seus melhores
                        esforços para enviar comunicação prévia compartilhada
                        pelo Prestador ao Usuário para informá-lo e lembrá-lo
                        sobre o respectivo preparo.
                    </p>
                </div>
                <p>
                    18. Após cada atendimento, o Usuário terá a possibilidade de
                    avaliar o Prestador acessando o Site, devendo abster-se de
                    linguagem agressiva, desrespeitosa, discriminatória,
                    indecorosa, injuriosa, caluniosa, difamatória e/ou de
                    veicular opiniões, que contenha dados subliminares, que
                    atente contra a ordem pública e informações que possam
                    caracterizar violações a quaisquer direitos de terceiros
                    (incluindo direitos à honra e de propriedade intelectual),
                    bem como condutas criminosas de qualquer natureza, ou
                    quaisquer outros tipos de declarações que não correspondam
                    limitadamente a avaliação de consulta ou exame.
                </p>

                <div>
                    <p>
                        18.1. As avaliações envolvendo os Prestadores poderão
                        ser publicadas no Site a exclusivo critério do
                        DOUTOR123, podendo ser removidas por este a qualquer
                        tempo sem anuência do Usuário.
                    </p>
                    <p>
                        18.2. O Usuário expressamente concorda e reconhece que o
                        DOUTOR123 não tem nenhuma responsabilidade em relação às
                        avaliações dos Prestadores realizadas por outros
                        usuários do Site, e que essas avaliações não devem ser
                        consideradas divulgações de caráter publicitário.
                    </p>
                </div>

                <h2>
                    Pagamento pelos serviços contratados no site e taxa de
                    intermediação
                </h2>

                <p>
                    19. A remuneração pela contratação do Prestador será paga
                    eletronicamente pelo Usuário por meio da plataforma de
                    empresa de meios de pagamento contratada.
                </p>
                <p>
                    20. Para poder utilizar os serviços da empresa de meio de
                    pagamentos contratada, o Usuário deverá previamente aceitar
                    seus termos e condições de uso, os quais poderão ser
                    modificados esporadicamente pelo mesmo, sendo obrigação do
                    Usuário atualizar-se quanto às modificações que ocorrerem em
                    tais termos e condições no momento da efetivação do
                    pagamento.
                </p>
                <p>
                    21. O Usuário concorda que o DOUTOR123 poderá cobrar uma
                    taxa de intermediação sobre o preço de cada consulta ou
                    exame agendado por meio do Site, a qual estará incluída no
                    valor total cobrado por cada atendimento e sempre informada
                    no momento do pagamento pelo Usuário.
                </p>
                <p>
                    22. O Usuário concorda em pagar por todos e quaisquer
                    serviços intermediados por meio do Site utilizando seu nome,
                    cartão de crédito ou outras formas de pagamento. Todos os
                    pagamentos feitos através de cartão de crédito estarão
                    sujeitos à autorização e aprovação da administradora do
                    cartão.
                </p>
                <p>
                    23. O DOUTOR123 jamais será responsável por possíveis
                    interrupções nos serviços de pagamentos de empresas
                    contratadas, nem pelas falhas e/ou danos e prejuízos que o
                    uso serviços possam gerar ao Usuário.
                </p>

                <div>
                    <p>
                        23.1. Toda discrepância, erro, omissão e/ou
                        inconveniente referente ao sistema, forma e/ou
                        instituição de pagamento será de exclusiva
                        responsabilidade do Usuário e das instituições de
                        pagamento, não cabendo imputação alguma ao DOUTOR123. Ao
                        optar por utilizar o serviço de controle de pagamentos,
                        o DOUTOR123 não tem como evitar ocasionais falhas das
                        referidas instituições e dos sistemas bancários e/ou
                        administradoras de cartão de crédito.
                    </p>
                    <p>
                        23.2. O DOUTOR123 não se responsabiliza por falhas e/ou
                        danos e prejuízos causados pelo uso dos serviços do site
                        da empresa de meio de pagamentos contratada. Caso
                        ocorram problemas devidos ao seu uso, o Usuário deverá
                        informar o DOUTOR123 pelos meios previstos neste Acordo,
                        não gerando obrigação para que o DOUTOR123 resolva tais
                        problemas.
                    </p>
                </div>

                <h2>Propriedade intelectual</h2>
                <p>
                    24. O Usuário concorda que todo o conteúdo e todas as
                    funcionalidades disponíveis no Site são, e continuarão
                    sendo, de propriedade exclusiva do DOUTOR123, seus
                    anunciantes e/ou licenciantes, e que os mesmos se encontram
                    protegidos como direitos autorais, marcas, segredos
                    comerciais e outros direitos referentes a propriedade
                    intelectual e/ou industrial previstos na Legislação
                    Brasileira.
                </p>
                <div>
                    <p>
                        24.1. O conteúdo do Site, incluindo textos, imagens,
                        áudio e/ou vídeos, não poderá ser distribuído,
                        modificado, alterado, transmitido, reutilizado ou
                        reenviado a terceiros pelo Usuário, seja para qual
                        finalidade for, sem a prévia e expressa autorização do
                        DOUTOR123 por escrito.
                    </p>
                    <p>
                        24.2. É expressamente vedado ao Usuário utilizar os
                        dados ou o conteúdo do Site ou respectivos micro sites
                        para criar compilações e bases de dados de qualquer tipo
                        sem a permissão por escrito do DOUTOR123. Sendo assim, é
                        proibido o uso do conteúdo ou materiais do Site e
                        respectivos micro sites para qualquer propósito que não
                        esteja expressamente autorizado neste Acordo.
                    </p>
                </div>

                <h2>Política de privacidade e dados pessoais</h2>
                <p>
                    25. O DOUTOR123 tem por princípio empenhar-se ao máximo e
                    adotar todas as medidas técnicas e jurídicas possíveis para
                    proteger a privacidade do Usuário e os dados Pessoais deste,
                    assim entendidos como quaisquer informações relacionadas ao
                    Usuário cuja coleta e armazenamento seja estritamente
                    necessária para a realização das atividades do Site (os
                    “Dados Pessoais”).
                </p>
                <div>
                    <p>
                        25.1. Todos os tratamentos de Dados Pessoais realizados
                        pelo DOUTOR123 são fundamentados nas bases legais da Lei
                        n. 13.709/2018 (“LGPD”). Isto é: o DOUTOR123 não realiza
                        nenhum tratamento de Dados Pessoais sem uma
                        justificativa legal e uma finalidade que não esteja
                        claramente descrita neste instrumento.
                    </p>
                </div>

                <p>
                    26. O Usuário concorda e reconhece que o DOUTOR123 poderá
                    tratar Dados Pessoais que forem necessários para o seu
                    cadastramento no Site e realização das atividades inerentes
                    ao funcionamento do Site, tais como: 1. validação de
                    cadastro e identificação do Usuário quando do uso do Site
                    (envio de SMS e/ou e-mail); <br /> 2. verificação de
                    autenticidade das informações e documentos fornecidos;
                    <br /> 3. detecção de eventuais fraudes; <br /> 4. estudo de
                    perfil do Usuário, eventualmente mediante o uso de
                    tecnologias como Facebook Pixel, Google Analytics e
                    Amplitude, para fins de análise de dados e estatísticas, com
                    o objetivo de otimizar o funcionamento do Site, desenvolver
                    melhores produtos, bem como disponibilizar ofertas,
                    campanhas informativas/publicitárias e serviços que, na
                    medida do possível, atendam às necessidades de cada Usuário
                    de forma personalizada; <br /> 5. comunicação com o Usuário;{" "}
                    <br /> 6. agendamento de atendimentos e exames; e <br />
                    7. notificação ao Usuário sobre alterações no Site e/ou
                    referentes aos serviços ou produtos de parceiros do
                    DOUTOR123.
                </p>

                <div>
                    <p>
                        26.1. O DOUTOR123 não realiza operações de tratamento
                        com dados bancários, dados financeiros ou meios de
                        pagamento do Usuário. Tais dados serão tratatos única e
                        exclusivamente pela plataforma de empresa de meios de
                        pagamento contratada, sem ingerência do DOUTOR123.
                    </p>
                </div>
                <p>
                    27. O Usuário ainda está ciente de que serão coletados Dados
                    Pessoais tão somente através de sua navegação no Site, tais
                    como: endereço de protocolo de internet - IP, registro de
                    acesso, geolocalização do endereço IP, tipo do navegador
                    utilizado, versão do sistema operacional,
                    modelo/características do aparelho utilizado para o acesso,
                    banda de internet, operadora, páginas visitadas, data e hora
                    de cada visita, perfil de uso e cookies.
                </p>
                <div>
                    <p>
                        27.1. Cookies – entendendo. Cookies são pequenos
                        arquivos enviados pela página visitada e armazenados no
                        disco rígido do computador do Usuário, ocupando pouco
                        espaço, com a principal função de mapear preferências do
                        Usuário e acompanhar o seu comportamento online. Cookies
                        permitem uma experiência personalizada para cada Usuário
                        e evitam a digitação de login e senha a cada novo acesso
                        do Usuário ao Site.
                    </p>

                    <div>
                        <p>
                            27.1.1. Os cookies não armazenam Dados Pessoais sem
                            que o Usuário os tenha fornecido e não coletam
                            informações registradas no dispositivo do Usuário.
                            Os Cookies de desempenho e de publicidade podem ser
                            ativados ou inativados pelo Titular, dependendo de
                            seu Consentimento para operar. Contudo, caso o
                            Usuário configure seu dispositivo para recusar
                            cookies, o bom funcionamento do Site pode ser
                            prejudicado.
                        </p>
                    </div>
                    <p>
                        27.2. Com fundamento na base legal do legítimo
                        interesse, os Dados Pessoais obtidos através da
                        navegação do Usuário no Site serão utilizados com o
                        objetivo de aprimorar/otimizar serviços disponibilizados
                        no Site e possibilitar que o Usuário tenha uma melhor
                        experiência de navegação, assim como para finalidades de
                        marketing, a fim de que o Usuário tenha acesso a ofertas
                        de serviços que possam ser do seu interesse.{" "}
                    </p>

                    <p>
                        28. Armazenamento e Guarda das Informações Coletadas. Os
                        Dados Pessoais coletados quando do acesso ou utilização
                        do Site pelo Usuário são armazenados sob as mais rígidas
                        práticas de segurança da informação. Todos os cuidados
                        imagináveis e são tomados para garantir que os Dados
                        Pessoais sejam tratados de forma segura a confidencial.
                    </p>

                    <div>
                        <p>
                            28.1. O Site utiliza o Certificado SSL que comprova
                            boas práticas para um ambiente seguro, por meio de
                            criptografia de ponta a ponta, para as informações
                            que são transitadas, e que serão armazenadas por
                            provedor de serviços de nuvem que rigorosamente
                            segue as melhores práticas para proteção dos seus
                            dados pessoais, incluindo a legislação brasileira de
                            proteção de dados pessoais (Lei no 13.709/2018).
                        </p>
                        <p>
                            28.2. O DOUTOR123 usa sistemas de segurança
                            compatíveis com os melhores padrões técnicos de
                            mercado para proteger Dados Pessoais contra acessos
                            desautorizados, mas não pode ser responsabilizado
                            por problemas de força maior, como ataques de
                            hackers que razoavelmente não poderiam ser
                            previstos.
                        </p>
                    </div>
                </div>
                <p>
                    29. Compartilhamento de Dados Pessoais. O Usuário autoriza
                    que Dados Pessoais sejam compartilhados com parceiros e/ou
                    fornecedores do DOUTOR123 que também estão sujeitos a esta
                    Política, e que contribuam com a prestação dos serviços
                    necessários ao funcionamento do Site, como:
                </p>
                <p>
                    1. empresas que prestam serviços de contabilidade, TI,
                    marketing, comunicação, checagem de dados, validação de
                    documentos, analíticos e jurídicos, incluindo Google,
                    Amplitude e empresas de atendimento ao cliente; <br /> 2.
                    subcontratados; <br />
                    3. provedores de serviços de nuvem de primeira linha.
                </p>
                <div>
                    <p>
                        29.1. Somente em circunstâncias justificáveis os Dados
                        Pessoais serão compartilhados com parceiros e/ou
                        fornecedores do DOUTOR123, e, principalmente, apenas
                        serão compartilhados Dados Pessoais que tais parceiros
                        e/ou fornecedores estritamente precisem acessar para o
                        desempenho das suas respectivas atribuições, sempre de
                        forma proporcional, cuidadosa e com a finalidade de
                        apoio às atividades do Site.
                    </p>
                    <p>
                        29.2. O Usuário entende que, em casos excepcionais e
                        fora do controle do DOUTOR123, este poderá ser obrigado
                        a disponibilizar seus Dados Pessoais a autoridades
                        públicas, se isso for exigido para o cumprimento de
                        determinações obrigações legais ou decisões judiciais.
                    </p>
                    <p>
                        29.3. Os Dados Pessoais poderão ser mantidos em
                        dispositivos localizados fora do Território Brasileiro,
                        especificamente mediante compartilhamento com provedores
                        de serviços de nuvem de primeira linha, situados em
                        países que oferecem um nível de proteção equivalente
                        àquele assegurado pela legislação brasileira.{" "}
                    </p>
                </div>
                <p>
                    30. O DOUTOR123 poderá também tratar Dados Pessoais para
                    torná-los impessoais, anonimizados e não identificáveis,
                    seja combinando-os com outras informações sobre outras
                    pessoas, seja através da omissão de alguma das suas
                    características (como nome), dentre outras possibilidades. O
                    DOUTOR123 poderá utilizar ou compartilhar com terceiros os
                    referidos dados anonimizados como entender mais conveniente,
                    e principalmente com profissionais da área de saúde, para
                    finalidades estatísticas, educacionais e de pesquisa, em
                    benefício da comunidade médica e da população em geral.
                </p>
                <p>
                    31. Para as pesquisas por inteligência artificial
                    disponibilizadas na ferramenta Triagem de Sintomas (a
                    “Ferramenta”), o DOUTOR123 poderá coletar alguns dados de
                    saúde e/ou envolvendo certas características pessoais do
                    Usuário, principalmente referentes aos sintomas relatados
                    por ele quando do uso da Ferramenta, mas que também podem
                    incluir: altura, peso, idade, problemas de saúde
                    pré-existentes, países que frequentou nos últimos meses,
                    dentre outros. Contudo, esses dados serão utilizados pelo
                    DOUTOR123 unicamente para finalidades estatísticas,
                    educacionais e de pesquisa, e, ainda que combinados com
                    outros dados, serão sempre anonimizados. Ou seja, nenhum
                    colaborador do DOUTOR123 e/ou terceiro descobrirá que os
                    referidos dados dizem respeito a você.{" "}
                </p>
                <p>
                    32. Comunicações. A comunicação entre o Prestador e o
                    DOUTOR123 será preferencialmente realizada através de
                    e-mail, incluindo eventual alteração nas suas informações
                    cadastrais (incluindo endereço de e-mail e número de
                    celular).
                </p>

                <div>
                    <p>
                        32.1. O DOUTOR123 poderá contatar o Usuário,
                        preferencialmente por meio de e-mail, para coletar
                        depoimentos e realizar pesquisa de satisfação acerca da
                        experiência de utilização do Site, sempre com o objetivo
                        de melhorar os serviços nele oferecidos. Essas
                        informações poderão ser armazenadas e publicadas no
                        Site. Se ocorrer uma publicação com o seu nome e você
                        desejar que seja excluída, pedimos-lhe por gentileza que
                        avise o DOUTOR123 por e-mail.
                    </p>
                    <p>
                        32. 2. Caso o Usuário não queira receber determinadas
                        mensagens, poderá desabilitar o recebimento delas na
                        funcionalidade “alterar cadastro” (p. ex.: dicas de
                        saúde, newsletters, campanhas de conscientização e
                        recomendações).
                    </p>
                    <p>
                        32.3. O DOUTOR123 ainda poderá realizar contatos
                        telefônicos com o Usuário para resolver algum problema.
                    </p>
                </div>

                <p>
                    33. Sites de terceiros. No Site há links e funcionalidades
                    de terceiros que direcionam o Usuário para sites fora do
                    controle do DOUTOR123, como links de anunciantes,
                    patrocinadores e parceiros (como, por exemplo, fornecedores
                    de aplicações de telemedicina). O Usuário reconhece que, ao
                    clicar em qualquer um desses links, os sites para os quais
                    for direcionado não são controlados pelo DOUTOR123. Nesses
                    casos, outros termos de uso e política de privacidade serão
                    aplicáveis, o que exclui a responsabilidade do DOUTOR123.
                </p>
                <div>
                    <p>
                        33.1. O Usuário concorda que alguns desses sites de
                        terceiros poderão compartilhar Dados Pessoais com o
                        DOUTOR123, sempre com o objetivo de aprimorar serviços
                        disponibilizados no Site e possibilitar que o Usuário
                        tenha uma melhor experiência de navegação, assim como
                        acesso a ofertas de produtos e serviços, bem como
                        campanhas informativas que possam ser do interesse do
                        Usuário.
                    </p>
                </div>
                <p>
                    34. Todas as comunicações no âmbito das consultas realizadas
                    na modalidade de telemedicina serão criptografadas, ou seja,
                    o conteúdo dessas comunicações somente poderá ser acessado
                    pelo respectivo Prestador e o Usuário, sem qualquer acesso
                    por parte do DOUTOR123.
                </p>
                <p>
                    35. Os Dados Pessoais serão armazenados e conservados
                    durante o tempo de vigência da relação contratual envolvendo
                    os serviços do Site e, após o término desses serviços,
                    durante os períodos de retenção de dados legalmente
                    exigíveis, de forma estritamente necessária, tais como:
                    prazo de 6 (seis) meses de obrigatoriedade de guarda dos
                    registros de acesso ao Site - art. 15, da Lei 12.965/2014 –
                    Marco Civil da Internet; e prazos prescricionais para
                    ajuizamento de ação penal, civil ou trabalhista, assim como,
                    para o exercício do direito de defesa em processo judicial
                    de qualquer natureza. Na ausência desses parâmetros
                    temporais, o tempo de retenção corresponderá a 5 (cinco)
                    anos.
                </p>
                <div>
                    <p>
                        35.1. Dados Pessoais coletados com base no Consentimento
                        do Titular – cujo tratamento pode ser interrompido a
                        qualquer tempo mediante opção do Usuário, tais como
                        Cookies de publicidade, ficarão armazenados até
                        revogação do Consentimento para a finalidade a que se
                        destinam ou, ainda, até o encerramento da conta do
                        Usuário, que implica a revogação do Consentimento do
                        Usuário.
                    </p>
                    <p>
                        35.2. Dados Pessoais armazenados para finalidades
                        associadas ao exercício regular de direitos, como
                        mensagens de atendimento trocadas entre o Usuário e o
                        DOUTOR123, poderão ser armazenados enquanto houver risco
                        de conflito ou litígio, sendo que seu apagamento somente
                        ocorrerá após o transcurso de eventual prazo
                        prescricional ou decadencial.
                    </p>
                </div>
                <p>
                    36. Como titular de Dados Pessoais, o Usuário tem o direito
                    de exercer os seguintes direitos:
                </p>
                <ul>
                    <li>
                        <p>
                            • Direito de confirmação da existência de
                            Tratamento: o Titular pode questionar se seus Dados
                            Pessoais já foram ou se são atualmente tratados pelo
                            DOUTOR123;
                        </p>
                    </li>
                    <li>
                        <p>
                            • Direito de acesso: o Titular pode solicitar
                            informações sobre como o DOUTOR123 trata ou tratou
                            seus Dados Pessoais e requerer acesso aos dados que
                            possuímos;
                        </p>
                    </li>
                    <li>
                        <p>
                            • Direito de correção: o Titular pode solicitar a
                            correção de seus Dados Pessoais, caso estejam
                            errados, imprecisos, incompletos ou desatualizados;
                        </p>
                    </li>
                    <li>
                        <p>
                            • Direito de “Anonimização”, bloqueio ou eliminação:
                            o Titular pode solicitar a Anonimização, o bloqueio
                            ou a eliminação dos dados que considerar excessivos.
                            Neste caso, todavia, a solicitação será avaliada
                            previamente pela nossa equipe de privacidade, para
                            assegurar que os serviços propiciados pelo Site não
                            sejam prejudicados, nem que dados essenciais ao
                            cumprimento de obrigações legais ou a eventual
                            defesa de direitos e interesses do DOUTOR123 ou de
                            terceiros sejam perdidos.
                        </p>
                    </li>
                    <li>
                        <p>
                            • Direito de retirar o seu consentimento: quando o
                            tratamento de dados tiver por base o Consentimento,
                            o Titular tem o direito de, a qualquer momento,
                            revogá-lo, mediante declaração expressa.
                        </p>
                    </li>
                    <li>
                        <p>
                            • Direito à portabilidade: o Titular pode solicitar
                            a portabilidade de seus dados para outras
                            organizações, ressalvados os segredos comercial,
                            industrial e eventuais direitos de propriedade
                            intelectual do DOUTOR123 ou de terceiros.
                        </p>
                    </li>
                    <li>
                        <p>
                            • Direito a informações sobre compartilhamento: o
                            Titular pode solicitar informações sobre terceiros
                            com as quais realizamos o compartilhamento de seus
                            dados.
                        </p>
                    </li>
                </ul>
            </main>

            <div className={styled["images_right"]}>
                <img
                    src="/images/medico-amarelo.png"
                    alt="Médico amarelo de bigode roxo"
                />
                <img
                    src="/images/Enfermeira-amarela.png"
                    alt="Cliente e infermeiras compartilhado informações pela internet"
                />
            </div>
        </div>
    );
}
