import { Heading } from "../../../../ui/Heading";
import { Text } from "../../../../ui/Text";
import styles from "./ModalForm.module.scss";
import { FigureTriade } from "../FigureTriade";
import { Button } from "../../../../ui/Button";
import { IconX } from "../../../../icons/IconX";
import P from "prop-types";
import { useState } from "react";

export const ModalForm = ({ onSubmit }) => {
    const [isLoading, setIsLoading] = useState(false);

    function handleSubmit(e) {
        setIsLoading(true);
        onSubmit(e).finally(() => {
            setIsLoading(false);
        });
    }
    return (
        <div className={styles["modal-form"]}>
            <Heading tag="h5">
                Você está a um passo de conhecer um novo jeito de cuidar da
                saúde
            </Heading>
            <Text color="white" tag="p" type="text" size="3">
                Preencha os dados abaixo e indique o benefício para sua empresa
            </Text>
            <div className={styles["form-data"]}>
                <FigureTriade></FigureTriade>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="nome-completo" className="form-label">
                            Seu nome completo
                        </label>
                        <input
                            required
                            type="text"
                            className="form-control"
                            id="nome-completo"
                        ></input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="empresa-email" className="form-label">
                            Seu email da empresa
                        </label>
                        <input
                            required
                            type="email"
                            className="form-control"
                            id="empresa-email"
                        ></input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="nome-empresa" className="form-label">
                            Nome da empresa
                        </label>
                        <input
                            required
                            type="text"
                            className="form-control"
                            id="nome-empresa"
                        ></input>
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="nome-contato-empresa"
                            className="form-label"
                        >
                            Nome do contato da empresa
                        </label>
                        <input
                            required
                            type="text"
                            className="form-control"
                            id="nome-contato-empresa"
                        ></input>
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="contato-empresa-email"
                            className="form-label"
                        >
                            Email do contato da empresa{" "}
                        </label>
                        <input
                            required
                            type="email"
                            className="form-control"
                            id="contato-empresa-email"
                        ></input>
                    </div>
                    <Button
                        color="primary"
                        text={
                            isLoading ? (
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">
                                        Loading...
                                    </span>
                                </div>
                            ) : (
                                "Enviar"
                            )
                        }
                        small={false}
                        onClick={() => {}}
                        type="submit"
                    ></Button>
                </form>
            </div>
        </div>
    );
};

ModalForm.defaultProps = {
    onSubmit: () => {},
};

ModalForm.propTypes = {
    onSubmit: P.funb,
};
