import styles from "./Icons.module.scss";

export const IconTelemedicina = () => (
    <i className={styles["icons-telemedicina"]}>
        <img
            alt="doutor123 telemedicina"
            src="/icons/icon-telemedicina.png"
        ></img>
    </i>
);
