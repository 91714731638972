import React from "react";
import styles from "./SectionDivider.module.scss";
function SectionDivider(props) {
    return (
        <section className={styles["section-divider"]}>
            {props.children}
        </section>
    );
}

export default SectionDivider;
