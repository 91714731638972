import styles from "./FormularioVemFazerParte.module.scss";
import P from "prop-types";

import { useRef, useState } from "react";
import { useFormik } from "formik";

import {
    formatCpf,
    validateCpf,
    validateLengthCpf,
} from "../../../utils/cpfUtils";
import { formatCnpj, validateLengthCnpj } from "../../../utils/cnpjUtil";
import { validateEmail } from "../../../utils/emailUtil";
import { formatPhone, validatePhone } from "../../../utils/celularUtil";

import { toast } from "react-toastify";
import { CustomScrollbar } from "../../common/CustomScrollbar";
import {
    Button,
    Fieldset,
    Heading,
    Input,
    Text,
    TextArea,
    TextError,
} from "../../ui";
import { criarLead } from "../../../service/lead";

export const FormularioVemFazerParte = ({ 
    handlerSubmit,
    origin
}) => {
    const [maskCpnjAndCpf, setMaskCpnjAndCpf] = useState("");
    const [maskPhone, setMaskPhone] = useState("");

    const formRef = useRef(null);

    const validateAndFormatCpnjCpf = (documento) => {
        const value = documento || "";
        const inputValue = value?.replace(/\D/g, "");

        if (validateLengthCpf(inputValue)) {
            validateCpfInput(inputValue);
            return true;
        } else if (validateLengthCnpj(inputValue)) {
            validateCnpj(inputValue);
            return true;
        } else {
            setMaskCpnjAndCpf(inputValue);
            return false;
        }
    };

    const validateAndFormtPhone = (phone) => {
        const value = phone || "";
        const inputValue = value.replace(/\D/g, "");

        if (!validatePhone(inputValue)) {
            setMaskPhone(formatPhone(inputValue));
            return true;
        } else {
            setMaskPhone(inputValue);
            return false;
        }
    };

    const validateCpfInput = (cpf) => {
        const cleanedCpf = cpf.replace(/\D/g, "");

        if (validateCpf(cleanedCpf)) {
            setMaskCpnjAndCpf(formatCpf(cleanedCpf));
        } else {
            setMaskCpnjAndCpf(cleanedCpf);
        }
    };

    const validateCnpj = (cnpj) => {
        const cleanedCnpj = cnpj.replace(/\D/g, "");

        if (validateLengthCnpj(cleanedCnpj)) {
            setMaskCpnjAndCpf(formatCnpj(cleanedCnpj));
        } else {
            setMaskCpnjAndCpf(cleanedCnpj);
        }
    };

    const validateCharactersForm = (value) => {
        const input = value || "";

        return input.length >= 4;
    };

    const formik = useFormik({
        initialValues: {
            nomeSobrenome: "",
            informacoes: "",
            cpfCnpj: "",
            email: "",
            celular: "",
        },
        initialTouched: {
            nomeSobrenome: false,
            informacoes: false,
            cpfCnpj: false,
            email: false,
            celular: false,
        },
        validate: (values) => {
            const { nomeSobrenome, cpfCnpj, email, celular, informacoes } =
                values;

            let errors = {};
            if (!validateCharactersForm(nomeSobrenome)) {
                errors.nomeSobrenome = "Infome um valor valido";
            }

            if (!validateCharactersForm(informacoes)) {
                errors.informacoes = "Infome um valor valido";
            }

            if (!validateAndFormatCpnjCpf(cpfCnpj)) {
                errors.cpfCnpj = "Infome um valor valido";
            }

            if (!validateEmail(email)) {
                errors.email = "Infome um valor valido";
            }

            if (!validateAndFormtPhone(celular)) {
                errors.celular = "Infome um valor valido";
            }

            return errors;
        },
        onSubmit: async (values, { setSubmitting }) => {
            const clinica = {
                email: values.email,
                company_name: values.email,
                contact_name: values.nomeSobrenome,
                origin: (origin) ? origin : "pagina-sou-profissional-saude",
                description: values.informacoes,
                phonenumber: values.celular,
            };

            const loading = toast.loading("Carregando...");
            await criarLead(clinica)
                .then((response) => {
                    let { status } = response;

                    status = status || response.response || "";
                    if (status === 200 || status === 201) {
                        handlerSubmit();
                    }
                })
                .catch((error) => {
                    toast.error("Erro não esperado!");
                })
                .finally(() => {
                    toast.dismiss(loading);
                    setSubmitting(true);
                });
        },
    });

    return (
        <CustomScrollbar>
            <form
                className={styles.formulario_vem_fazer_parte}
                onSubmit={formik.handleSubmit}
                ref={formRef}
            >
                <article
                    className={styles.formulario_vem_fazer_parte__article_title}
                >
                    <Heading tag="h5" color="white">
                        Dados pessoais e da clínica:
                    </Heading>
                </article>

                <div className={styles.formulario_vem_fazer_parte__body}>
                    <Fieldset>
                        <Text tag="small" color="white">
                            Nome e sobrenome -{" "}
                            <Text tag="em" color="white">
                                obrigatório
                            </Text>
                        </Text>
                        <Input
                            name="nomeSobrenome"
                            id="nomeSobrenome"
                            placeholder="Nome e sobrenome"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={
                                formik.errors?.nomeSobrenome &&
                                (formik.touched.nomeSobrenome ?? false)
                            }
                            isValid={
                                !formik.errors?.nomeSobrenome &&
                                (formik.touched.nomeSobrenome ?? false)
                            }
                        />
                        {
                            <TextError>
                                {formik.errors?.nomeSobrenome &&
                                (formik.touched.nomeSobrenome ?? false)
                                    ? formik.errors.nomeSobrenome
                                    : null}
                            </TextError>
                        }
                    </Fieldset>

                    <Fieldset>
                        <Text tag="small" color="white">
                            E-mail -{" "}
                            <Text tag="em" color="white">
                                obrigatório
                            </Text>
                        </Text>
                        <Input
                            name="email"
                            id="email"
                            placeholder="email@email.com"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={
                                formik.errors?.email &&
                                (formik.touched.email ?? false)
                            }
                            isValid={
                                !formik.errors?.email &&
                                (formik.touched.email ?? false)
                            }
                        />
                        {
                            <TextError>
                                {formik.errors?.email &&
                                (formik.touched.email ?? false)
                                    ? formik.errors.email
                                    : null}
                            </TextError>
                        }
                    </Fieldset>

                    <Fieldset>
                        <Text tag="small" color="white">
                            Celular (com DDD) -{" "}
                            <Text tag="em" color="white">
                                obrigatório
                            </Text>
                        </Text>
                        <Input
                            name="celular"
                            id="celular"
                            placeholder="(00) 00000-0000"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={maskPhone || ""}
                            isInvalid={
                                formik.errors?.celular &&
                                (formik.touched.celular ?? false)
                            }
                            isValid={
                                !formik.errors?.celular &&
                                (formik.touched.celular ?? false)
                            }
                        />
                        {
                            <TextError>
                                {formik.errors?.celular &&
                                (formik.touched.celular ?? false)
                                    ? formik.errors.celular
                                    : null}
                            </TextError>
                        }
                    </Fieldset>

                    <Fieldset>
                        <Text tag="small" color="white">
                            CPF ou CNPJ -{" "}
                            <Text tag="em" color="white">
                                obrigatório
                            </Text>
                        </Text>
                        <Input
                            name="cpfCnpj"
                            id="cpfCnpj"
                            placeholder="000.000.000-00"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={maskCpnjAndCpf || ""}
                            isInvalid={
                                formik.errors?.cpfCnpj &&
                                (formik.touched.cpfCnpj ?? false)
                            }
                            isValid={
                                !formik.errors?.cpfCnpj &&
                                (formik.touched.cpfCnpj ?? false)
                            }
                        />
                        {
                            <TextError>
                                {formik.errors?.cpfCnpj &&
                                (formik.touched.cpfCnpj ?? false)
                                    ? formik.errors.cpfCnpj
                                    : null}
                            </TextError>
                        }
                    </Fieldset>

                    <Fieldset>
                        <Text tag="small" color="white">
                            Infomações adicionais: nome da clinica, site e
                            telefone -{" "}
                            <Text tag="em" color="white">
                                obrigatório
                            </Text>
                        </Text>
                        <TextArea
                            id="informacoes"
                            name="informacoes"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={
                                formik.errors?.informacoes &&
                                (formik.touched.informacoes ?? false)
                            }
                            isValid={
                                !formik.errors?.informacoes &&
                                (formik.touched.informacoes ?? false)
                            }
                        ></TextArea>
                        {
                            <TextError>
                                {formik.errors?.informacoes &&
                                (formik.touched.informacoes ?? false)
                                    ? formik.errors.informacoes
                                    : null}
                            </TextError>
                        }
                    </Fieldset>
                </div>

                <div className={styles.formulario_vem_fazer_parte__footer}>
                    <Button
                        text="Continuar"
                        color="primary"
                        type="submit"
                        small={false}
                        className={
                            styles.formulario_vem_fazer_parte__footer__button
                        }
                        disabled={!formik.isValid}
                    />
                </div>
            </form>
        </CustomScrollbar>
    );
};

CustomScrollbar.propTypes = {
    handlerSubmit: P.func,
};
