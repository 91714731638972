import styles from "./SectionMain.module.scss";

import { Heading } from "../../ui/Heading";
import { FigureDownload } from "../../ui/Figures/FigureDownload";
import { Text } from "../../ui/Text";
import { FigureImageCapa } from "./FigureImageCapa";
import { SectionCadastro } from "../SectionCadastro";

export const SectionMain = () => (
    <section className={`${styles["section-main"]} container`}>
        <div className={styles["section-main__container"]}>
            <div className={styles["section-main__container_content"]}>
                <Heading
                    tag="h2"
                    color="secondary"
                    className={
                        styles["section-main__container_content--heanding"]
                    }
                >
                    A assinatura de saúde da sua
                    <span>{" >>> "}</span> empresa.
                </Heading>
                <div
                    className={
                        styles["section-main__container_content--figure"]
                    }
                >
                    <FigureImageCapa></FigureImageCapa>
                </div>
            </div>

            <div className={styles["section-main__container--group-card"]}>
                <div className={styles["section-main__container--card"]}>
                    <div
                        className={
                            styles["section-main__container--card-subtitle"]
                        }
                    >
                        <span>
                            <Text
                                tag="span"
                                color="secondary"
                                type="title"
                                className=""
                                size="1"
                            >
                                Saúde de verdade, por um mundo com colabs mais
                                <b> saudáveis</b> e <b>felizes</b>.
                            </Text>

                            <FigureDownload></FigureDownload>
                        </span>
                    </div>
                    <div
                        className={`${styles["section-main__container--card-figure"]}`}
                    >
                        <FigureImageCapa></FigureImageCapa>
                    </div>
                </div>
            </div>
        </div>

        <SectionCadastro />
    </section>
);
