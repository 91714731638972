import { Footer } from "../../components/common/Footer";
import { Navbar } from "../../components/common/Navbar";
import styles from "./MainLayout.module.scss";
import P from "prop-types";

export const MainLayout = ({ children }) => {
    return (
        <>
            <Navbar />
            {children}
            <Footer />
        </>
    );
};

MainLayout.propTypes = {
    children: P.any,
};
