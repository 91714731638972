import styles from "./Icons.module.scss";

export const IconServicoSaude = () => (
    <i className={styles["icons"]}>
        <img
            alt="doutor123 atividade fisica"
            src="/icons/icon-servico-saude.png"
        ></img>
    </i>
);
