import styles from "./TextError.module.scss";
import P from "prop-types";

export const TextError = ({ children, className }) => {
    const defaultClassName = [className, styles.text_error].join(" ");

    return <small className={defaultClassName}>{children}</small>;
};

TextError.propTypes = {
    className: P.string,
    children: P.any,
};
