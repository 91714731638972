import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import P from "prop-types";
import styles from './Question.module.scss';
import { Button, Logo, Text } from '../../ui';
import api from '../../../service/axios';

const validationSchema = Yup.object().shape({
    recommendation: Yup.number().required('Este campo é obrigatório'),
    experience: Yup.number().min(1, 'Este campo é obrigatório').required('Este campo é obrigatório'),
    platformExperience: Yup.number().min(1, 'Este campo é obrigatório').required('Este campo é obrigatório'),
    feedback: Yup.string()
});

const Messages = {
    EXPERIENCE_FEEDBACK: "Queremos saber sua opinião. Como você avalia sua última experiência {variavel}?",
    EXAMME: "com os exames realizados",
    VACINNE: "com a aplicação de vacinas",
    CONSULTATION_FEEDBACK: "Queremos saber sua opinião. Como você avalia sua última experiência com sua última consulta - {specialty}?"
};

const CartType = {
    exames: 'exame',
    vacina: 'vacina',
    consulta: 'consultation'
}

const Question = ({
    itemsCart,
    id,
    setRenderComponent,
    service
}) => {
    const initialValues = {
        recommendation: -1,
        experience: 0,
        platformExperience: 0,
        feedback: ''
    };

    const handleSubmit = (values) => {
        const body = {
            service_score: values.experience,
            application_score: values.platformExperience,
            nps: values.recommendation,
            comments: values.feedback
        }

        api.patch(`/rating/${id}`, body).then(
            (response) => {
                const { status, } = response;
                if (status === 200) {
                    setRenderComponent('submit')
                }
            }
        )
    };

    const interpolateMessage = (message, cart, service = null) => {
        if (service) {
            return Messages
                .CONSULTATION_FEEDBACK
                .replace('{specialty}', service.map(s => s?.name)?.join(', ') || '');
        }

        let variavel = '';

        switch (cart?.type) {
            case CartType['exames']:
                variavel = Messages.EXAMME;
                break;
            case CartType['vacina']:
                variavel = Messages.VACINNE;
                break;
            default:
                variavel = '';
        }

        return message.replace('{variavel}', variavel);
    };

    const MessageQuestionComponent = ({ itemsCart, service = [] }) => {
        const consultation = [
            { 
                type: CartType['consulta'], 
                validation: !!(service.find(item => item?.type === CartType['consulta'])?.type || false) 
            },
            { 
                type: CartType['vacina'], 
                validation: !!(service.find(item => item?.type === CartType['vacina'])?.type || false) 
            },
            { 
                type: CartType['exames'], 
                validation: !!(service.find(item => item?.type === CartType['exames'])?.type || false) 
            },
        ]
        
        let message = '';
        if (consultation[0]?.validation 
            && (consultation[1]?.validation 
                || consultation[2]?.validation)) {
            message = interpolateMessage(Messages.EXPERIENCE_FEEDBACK, null, service);
        }
console.log(service)
        if (consultation[0]?.validation) {
            message = interpolateMessage(Messages.CONSULTATION_FEEDBACK, null, service);
        }

        if (consultation[1]?.validation || consultation[2]?.validation) {
            message = interpolateMessage(Messages.EXPERIENCE_FEEDBACK, service[0], null);
        }

        return (
            <Text tag="strong" size="2" type="text">
                <div dangerouslySetInnerHTML={{ __html: message }} />
            </Text>
        );
    };

    return (
        <div className={styles.rating_container}>
            <div className={styles.rating_container__header}>
                <Logo
                    className={styles.rating_container__header__logo}
                    purple
                />
            </div>

            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >

                {({ values, setFieldValue, isValid }) => (
                    <Form className={styles.rating_container__content_card}>
                        <div className={styles.rating_container__content_card__question}>
                            <MessageQuestionComponent
                                itemsCart={itemsCart || []}
                                service={service || null}
                            />

                            <div className={styles.rating_container__content_card__question__feedback_stars}>
                                {[...Array(5)].map((_, i) => (
                                    <span
                                        key={i}
                                        className={`${styles.star} ${i < values.experience ? styles.filled : ''}`}
                                        onClick={() => setFieldValue('experience', i + 1)}
                                    >
                                        &#x2605;
                                    </span>
                                ))}
                            </div>
                        </div>

                        <div className={styles.rating_container__content_card__question}>
                            <Text tag="strong" size="2" type="text">
                                Como você avalia a sua experiência na plataforma Doutor123? <span className='text-danger'>*</span>
                            </Text>

                            <div className={styles.rating_container__content_card__question__feedback_stars}>
                                {[...Array(5)].map((_, i) => (
                                    <span
                                        key={i}
                                        className={`${styles.star} ${i < values.platformExperience ? styles.filled : ''}`}
                                        onClick={() => setFieldValue('platformExperience', i + 1)}
                                    >
                                        &#x2605;
                                    </span>
                                ))}
                            </div>
                        </div>

                        <div className={styles.rating_container__content_card__question}>
                            <Text tag="strong" size="2" type="text">
                                Em uma escala de 0 a 10, o quanto você recomendaria o Doutor123 para um amigo ou familiar? <span className='text-danger'>*</span>
                            </Text>

                            <div className={styles.rating_container__content_card__question__scale}>
                                {[...Array(11)].map((_, i) => (
                                    <div className={styles.rating_container__content_card__question__scale__content} key={i}>
                                        <span
                                            className={`${styles.dot} ${i === values.recommendation ? styles.selected : ''}`}
                                            onClick={() => setFieldValue('recommendation', i)}>
                                            <div className={styles.rating_container__content_card__question__scale__content__icon_mobile}>{i}</div>
                                        </span>
                                        <div className={styles.rating_container__content_card__question__scale__content__icon_desktop}>{i}</div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className={styles.rating_container__content_card__question}>
                            <Text tag="strong" size="2" type="text">
                                Se desejar, nos conte um pouco mais sobre sua experiência:
                            </Text>
                            <Field
                                as="textarea"
                                id="feedback"
                                name="feedback"
                                placeholder="Digite aqui a sua experiência com o Doutor123."
                            />
                        </div>

                        <div className={styles.rating_container__content_card__button}>
                            {(!isValid) && (
                                <small className='text-danger mb-2'>
                                    *Pergunta obrigatória.
                                </small>
                            )}

                            <Button
                                disabled={!isValid}
                                text="Enviar feedback"
                                color="secondary-purple"
                                type="submit"
                                small={true}
                            />
                        </div>
                    </Form>
                )}

            </Formik>
        </div>
    );
}

export default Question;

Question.defaultProps = {
    itemsCart: [],
    patient: {},
    consultation: null
};

Question.propTypes = {
    itemsCart: P.oneOfType([]),
    patient: P.oneOfType({}),
    consultation: P.shape({
        specialty: P.string.isRequired
    })
};