import api from "./axios";
import { toast } from "react-toastify";

async function sendSimulationData({
    licenses,
    services,
    phoneNumber,
    email,
    contactName,
    companyName,
    requestContact,
    origin,
}) {
    const response = await api.post("simulation/", {
        licenses,
        services,
        phonenumber: phoneNumber,
        email,
        contact_name: contactName,
        company_name: companyName,
        request_contact: requestContact,
        origin,
    });

    if (response.status === 200 || response.status === 201) {
        const totalAmount = (response.data.total_amount / 100).toLocaleString(
            "pt-BR",
            {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
            },
        );

        const data = {
            ...response.data,
            total_amount: totalAmount,
        };

        return data;
    }

    toast(
        "Ocorreu um erro ao enviar os dados da simulação. Tente novamente mais tarde.",
        {
            type: "error",
            autoClose: true,
            position: toast.POSITION.TOP_CENTER,
        },
    );
    return null;
}

export default sendSimulationData;
