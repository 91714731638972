import React, { useState } from "react";
import { Input, Button, Checkbox, Heading, Text, Modal } from "../../../ui";
import styles from "./ModalSimulacao.module.scss";

function ModalSimulacao(props) {
    const [check, setCheck] = useState(true);

    const [name, setName] = useState({
        value: "",
        error: false,
    });

    const [company, setCompany] = useState({
        value: "",
        error: false,
    });

    const [email, setEmail] = useState({
        value: "",
        error: false,
    });

    const [phone, setPhone] = useState({
        value: "",
        error: false,
    });

    const initialState = () => {
        setName({ value: "", error: false });
        setCompany({ value: "", error: false });
        setEmail({ value: "", error: false });
        setPhone({ value: "", error: false });
    };

    const handlerForm = (e) => {
        e.preventDefault();
        const nameRegex = /^[a-zA-Z]{2,}(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
        const emailRegex = /^([\w.*-]+@([\w-]+\.)+[\w-]{2,4})?$/;
        const companyRegex = /^[a-zA-Z\u00C0-\u00FF0-9\s]+$/;

        let isFormValid = true;

        if (!nameRegex.test(name.value)) {
            setName({ value: name.value, error: "Insira um nome válido!" });
            isFormValid = false;
        }

        if (!emailRegex.test(email.value)) {
            setEmail({ value: email.value, error: "Insira um email válido!" });
            isFormValid = false;
        }

        if (!companyRegex.test(company.value)) {
            setCompany({
                value: company.value,
                error: "Insira um nome de empresa válido!",
            });
            isFormValid = false;
        }

        if (phone.value.length < 8) {
            setPhone({
                value: phone.value,
                error: "Insira um número de telefone válido!",
            });
            isFormValid = false;
        }

        if (isFormValid) {
            const form = {
                name: name.value,
                company: company.value,
                email: email.value,
                phone: phone.value,
                check: check,
            };

            props.onOk(form);
        }
    };

    const closeModal = () => {
        initialState();
        props.onClose();
    };

    return (
        <Modal show={props.show} onClose={() => closeModal()}>

            <div className={styles["custom-modal__content__header"]}>
                <Heading tag="h4" color='white' className="mb-2">
                    Estamos quase lá!
                </Heading>
                <Text tag="small" color="white" type="text" size="2">
                    Você está a um passo de conhecer um novo jeito de cuidar da
                    saúde.
                    <strong> Preencha os dados abaixo</strong> para visualizar a
                    sua simulação.
                </Text>
            </div>

            <div className={styles["custom-modal__content__body"]}>
                <img
                    src="images/img-healthtech.png"
                    alt="Formulario de Simulação"
                />
                <form
                    onSubmit={(e) => handlerForm(e)}
                    className={styles["custom-modal__content__body__form"]}
                >

                    <label>Seu Nome</label>
                    <Input
                        name="nome"
                        id="nome"
                        placeholder="Digite aqui o seu nome"
                        value={name.value}
                        error={name.error}
                        onChange={(e) =>
                            setName({ value: e.target.value, error: false })
                        }
                    ></Input>
                    <label>Nome da Empresa</label>
                    <Input
                        name="nome-da-empresa"
                        id="nome-da-empresa"
                        placeholder="Digite aqui o seu nome"
                        error={company.error}
                        value={company.value}
                        onChange={(e) =>
                            setCompany({ value: e.target.value, error: false })
                        }
                    ></Input>
                    <label>E-mail Corporativo</label>
                    <Input
                        name="email"
                        id="email"
                        placeholder="Digite aqui o seu e-mail"
                        error={email.error}
                        value={email.value}
                        onChange={(e) =>
                            setEmail({ value: e.target.value, error: false })
                        }
                    />
                    <label>Telefone</label>
                    <Input
                        name="telefone"
                        id="telefone"
                        placeholder="Digite um telefone para contato"
                        error={phone.error}
                        value={phone.value}
                        onChange={(e) =>
                            setPhone({ value: e.target.value, error: false })
                        }
                    />

                    <div className={styles["check"]}>
                        <Checkbox
                            name="check"
                            id="check"
                            checked={check}
                            onChange={() => setCheck(!check)}
                        />
                        Desejo receber contato comercial
                    </div>
                    <Button
                        loading={props?.loading}
                        color="primary"
                        text={(props.textButton) ? props.textButton : "Ver simulação"}
                        type="submit"
                        small={false}
                    ></Button>
                </form>

            </div>
        </Modal>
    );
}

export default ModalSimulacao;
