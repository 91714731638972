import styles from "./Footer.module.scss";

import { FigureSocialMedia } from "../../ui/Figures/FigureSocialMedia";
import { Logo } from "../../ui/Logo";
import { Text } from "../../ui/Text";
import { Link } from "react-router-dom";
import { FigureDownload } from "../../ui/Figures/FigureDownload";

const socialMedias = [
    { social: "instagram", link: "https://www.instagram.com/doutor_123/" },
    {
        social: "facebook",
        link: "https://pt-br.facebook.com/Doutor123Oficial/",
    },
    { social: "linkedin", link: "https://www.linkedin.com/company/doutor123/" },
    { social: "tiktok", link: "http://www.tiktok.com/@doutor123oficial" },
    { social: "youtube", link: "https://youtube.com/@doutor1237" },
];

export const Footer = () => (
    <footer className={`${styles["footer"]} container-fluid`}>
        <Logo className={styles["logo"]}></Logo>
        <ul className={`nav ${styles["footer__container-links"]}`}>
            {/* <li className="nav-item">
                <a onClick={() => window.open("http://blog.doutor123.com.br")}>
                    <Text tag="span" color="white" type="title" size="2">
                        Blog123
                    </Text>
                </a>
            </li> */}
            <li className="nav-item">
                <a
                    onClick={() =>
                        window.open(
                            "https://wa.me//send?phone=5511984887198&text=Ol%C3%A1,%20pode%20me%20ajudar?",
                        )
                    }
                >
                    <Text tag="span" color="white" type="title" size="2">
                        Contato
                    </Text>
                </a>
            </li>
            <li className="nav-item">
                <Link to="/termos-de-uso" href="#">
                    <Text tag="span" color="white" type="title" size="2">
                        Termo de uso
                    </Text>
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/politicas-de-privacidade" href="#">
                    <Text tag="span" color="white" type="title" size="2">
                        Politica de privacidade
                    </Text>
                </Link>
            </li>
        </ul>
        <div className={styles["app-store-links"]}>
            <FigureDownload />
        </div>
        <ul className={`nav ${styles["footer__container-social-medias"]}`}>
            {socialMedias.map((socialMedia) => (
                <li className="nav-item" key={socialMedia.social}>
                    <a onClick={() => window.open(socialMedia.link)}>
                        <FigureSocialMedia
                            socialMedia={socialMedia.social}
                        ></FigureSocialMedia>
                    </a>
                </li>
            ))}
        </ul>
    </footer>
);
