import { Heading } from "../../../../ui/Heading";
import { Text } from "../../../../ui/Text";
import styles from "./ModalSubmitted.module.scss";
import { FigureConsultaPresencial } from "../FigureConsultaPresencial";

export const ModalSubmitted = () => (
    <div className={styles["modal-submitted"]}>
        {Array.from({ length: 3 }).map((_, index) => (
            <Heading tag="h3" key={index}>
                Saudável do seu jeito!
            </Heading>
        ))}
        <Text color="white" tag="p" type="text" size="1">
            Feito! Sua indicação foi enviada.
            <br />
            Esperamos trazer boas notícias em breve.
        </Text>
        <FigureConsultaPresencial></FigureConsultaPresencial>
    </div>
);
