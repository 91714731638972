import styles from "./ModalRecompensaEmpresa.module.scss";
import P from "prop-types";
import { useState } from "react";
import { ModalForm } from "./ModalForm";
import { ModalSubmitted } from "./ModalSubmitted";
import { IconX } from "../../../icons/IconX";
import { appConfig } from "../../../../config";

export const ModalRecompensaEmpresa = ({ onClose }) => {
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();

        const form = Array.from(e.target.elements);
        const [
            nomeCompleto,
            empresaEmail,
            nomeEmpresa,
            nomeContatoEmpresa,
            contatoEmpresaEmail,
        ] = form.map((element) => element.value);

        return fetch(appConfig.apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: contatoEmpresaEmail,
                contact_name: nomeContatoEmpresa,
                company_name: nomeEmpresa,
                origin: `indique sua empresa / origem: ${nomeCompleto} - ${empresaEmail}`,
                resquet_contact: "True",
            }),
        }).finally(() => setHasSubmitted(true));
    };

    return (
        <div className={styles["modal-container"]}>
            <div className={styles["modal-body"]}>
                <button className={styles["close-button"]} onClick={onClose}>
                    <IconX></IconX>
                </button>
                {hasSubmitted ? (
                    <ModalSubmitted></ModalSubmitted>
                ) : (
                    <ModalForm onSubmit={onSubmit}></ModalForm>
                )}
            </div>
        </div>
    );
};

ModalRecompensaEmpresa.defaultProps = {
    onClose: () => {},
};

ModalRecompensaEmpresa.propTypes = {
    onClose: P.func,
};
