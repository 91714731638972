import styles from "./Collapse.module.scss";
import P from "prop-types";

import { useState } from "react";
import { Heading } from "../Heading";

export const Collapse = ({ className, children, title }) => {
    const [open, setOpen] = useState(false);
    const toggle = () => {
        setOpen(!open);
    };

    return (
        <div
            className={`${styles["collapse"]} ${className} ${open ? styles["collapse-background"] : ""}`}
        >
            <div className={styles["collapse-header"]} onClick={toggle}>
                <Heading tag="h5" color="secondary" className="m-0">
                    {title}
                </Heading>
                <img
                    src="/icons/icon-arrow.svg"
                    className={open ? styles["img-360"] : ""}
                />
            </div>

            {open && <div className={styles["collapse-body"]}>{children}</div>}
        </div>
    );
};

Collapse.propTypes = {
    className: P.string,
    children: P.any,
    title: P.any,
};

Collapse.defaultProps = {
    className: "",
};
