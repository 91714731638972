import React from "react";
import styles from "./Checkbox.module.scss";

export function Checkbox(props) {
    return (
        <label className={styles["form-control"]}>
            <input
                type="checkbox"
                name="checkbox"
                checked={props.checked}
                onChange={props.onChange}
                disabled={props.disabled}
            />
            {props.children}
        </label>
    );
}
