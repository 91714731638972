import styles from "./SectionGerencionamento.module.scss";

import { FigureImagePrintCelular } from "./FigureImagePrintCelular";
import { Text } from "../../ui/Text";
import { Heading } from "../../ui/Heading";
import { FigureIconMental } from "../../ui/Figures/FigureIconMental";
import { FigureIconServicoSaude } from "../../ui/Figures/FigureIconServicoSaude";
import { FigureIconSaudeFisica } from "../../ui/Figures/FigureIconSaudeFisica";

export const SectionGerencionamento = () => (
    <section className={`${styles["section-gerencionamento"]} container`}>
        <FigureImagePrintCelular></FigureImagePrintCelular>

        <div className={styles["section-gerencionamento--container"]}>
            <div
                className={
                    styles["section-gerencionamento--container__content"]
                }
            >
                <Heading
                    tag="h2"
                    color="secondary"
                    className={
                        styles[
                            "section-gerencionamento--container__content-heading"
                        ]
                    }
                >
                    Gerencie <span>todos</span> os benefícios de saúde de seus
                    colaboradores em um só lugar.
                </Heading>

                <div
                    className={
                        styles[
                            "section-gerencionamento--container__content_card-icons"
                        ]
                    }
                >
                    <FigureIconServicoSaude>Social</FigureIconServicoSaude>
                    <FigureIconSaudeFisica>Fisico</FigureIconSaudeFisica>
                    <FigureIconMental>Mental</FigureIconMental>
                </div>

                <div
                    className={
                        styles[
                            "section-gerencionamento--container__content_sub-title"
                        ]
                    }
                >
                    <Heading
                        tag="h4"
                        color="secondary"
                        className={`${styles["section-gerencionamento--container__content_sub-title_heading"]} m-0`}
                    >
                        O que queremos dizer com todos?
                    </Heading>
                    <Text
                        type="title"
                        tag="p"
                        size="2"
                        color="secondary"
                        className={
                            styles[
                                "section-gerencionamento--container__content_sub-title_paragraph"
                            ]
                        }
                    >
                        Unimos tudo que auxilia na <span>saúde física, </span>
                        <span>mental e social</span> prezando pelo direito de
                        todos alcançarem o completo bem-estar.
                    </Text>
                </div>
            </div>
        </div>
    </section>
);
