import styles from "./Heading.module.scss";
import P from "prop-types";

export const Heading = ({ children, className, tag, color }) => {
    const Component = tag;
    const classColor = selectionColor(color);

    return (
        <Component
            className={`${styles[tag]} ${styles[classColor]} ${className}`}
        >
            {children}
        </Component>
    );
};

Heading.propTypes = {
    tag: P.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
    color: P.oneOf(["default", "primary", "secondary", "white"]),
    className: P.string,
    children: P.any,
};

Heading.defaultProps = {
    tag: "h1",
    className: "",
};

const selectionColor = (color) => {
    let select = ["default", "primary", "secondary", "white"];
    let varievel = color || "default";

    return `heading__color-${select.filter((c) => c == varievel)}`;
};
