import styles from "./Text.module.scss";
import P from "prop-types";

export const Text = ({
    children,
    className,
    tag,
    color,
    type,
    size,
    style,
}) => {
    const selectionColor = (color) => {
        let select = [
            "default",
            "primary",
            "secondary",
            "white",
            "secondary-support",
        ];
        let varievel = color || "default";

        return styles[`text__color-${select.filter((c) => c == varievel)}`];
    };

    const selectionTypeAndSize = (size, type) => {
        switch (type) {
            case "title":
                return styles["sub-title-" + size];
            case "text":
                return styles["body-text-" + size];
            default:
                return "";
        }
    };

    const validationTagToClass = (tag) => {
        switch (tag) {
            case "small":
                return styles["small"];
            default:
                return "";
        }
    };

    const assembleCssClass = [
        selectionColor(color),
        selectionTypeAndSize(size, type),
        validationTagToClass(tag),
        className,
    ].join(" ");

    const Component = tag;
    return (
        <Component style={style} className={assembleCssClass}>
            {children}
        </Component>
    );
};

Text.defaultProps = {
    tag: "p",
    className: "",
    color: "default",
    size: "1",
};

Text.propTypes = {
    type: P.oneOf(["title", "text"]),
    tag: P.oneOf(["p", "span", "small", "em", "strong"]),
    size: P.oneOf(["0", "1", "2", "3", "4"]),
    color: P.oneOf([
        "default",
        "primary",
        "secondary",
        "white",
        "secondary-support",
    ]),
    className: P.string,
    children: P.any,
};
