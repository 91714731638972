import styles from "./FigureIconMental.module.scss";
import P from "prop-types";

import { IconSaudeMental } from "../../../icons/IconSaudeMental";
import { Text } from "../../Text";

export const FigureIconMental = ({ children, className, column }) => (
    <figure
        className={`${styles["figure-icon-mental"]} ${className} ${styles[ValidColumn(column)]}`}
    >
        <IconSaudeMental></IconSaudeMental>
        <Text tag="small" type="text" size="2" color="primary">
            <b>{children}</b>
        </Text>
    </figure>
);

FigureIconMental.propTypes = {
    className: P.string,
    children: P.string,
    column: P.bool,
};

FigureIconMental.defaultProps = {
    className: "",
    column: true,
};

const ValidColumn = (column) => (column ? "column" : "row");
