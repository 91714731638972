import React from "react";
import styles from "./VantagensCard.module.scss";
import { Heading, Text } from "../../../ui";

function VantagensCard(props) {
    return (
        <li className={styles["vantagens-card"]}>
            <img src={props.image} alt={props.description} />
            <div className={styles["vantagens-card__content"]}>
                <Text type="title" tag="strong">
                    {props.title}
                </Text>
                <Text>{props.description}</Text>
            </div>
        </li>
    );
}

export default VantagensCard;
