import { SectionGerencionamento } from "../../components/home/SectionGerencionamento";
import { SectionMain } from "../../components/home/SectionMain";
import { SectionOhQueOferecemos } from "../../components/home/SectionOhQueOferecemos";
import { SectionSimulePacote } from "../../components/home/SectionSimulePacote";
import { SectionNossosParceiros } from "../../components/home/SectionNossosParceiros";
import { SectionSaude } from "../../components/home/SectionSaude";
import { SectionDuvidasFrequentes } from "../../components/home/SectionDuvidasFrequentes";

export default function Home() {
    return (
        <>
            <SectionMain />
            <SectionGerencionamento />
            <SectionOhQueOferecemos />
            <SectionNossosParceiros />
            <SectionSimulePacote page="pagina home" />
            <SectionDuvidasFrequentes />
            <SectionSaude />
        </>
    );
}
