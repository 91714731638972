import React from 'react';
import styles from './Answered.module.scss';
import { Heading, Logo, Text, Button } from '../../ui';
import { appConfig } from '../../../config';

const Answered = () => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Logo
                        className={styles.header__logo}
                        purple
                    />

                </div>

                <img
                    src="/images/img-healthtech.png"
                    alt="doutor123 resultados"
                />
                <div className={styles.text}>
                    <Heading tag='h4'>
                        Você já respondeu essa pesquisa.
                    </Heading>
                    <Text 
                        type="text"
                        tag="p"  
                        size="2">
                        Verificamos em nossa base de dados que já recebemos o seu feedback,
                        não é necessário responder novamente.
                    </Text>

                </div>
                
                <div className={styles.content__button}>
                    <Button
                        onClick={() => (window.location.href = appConfig.appUrl)}
                        small={true}
                        color="secondary-purple"
                        text="Acessar aplicativo"
                    />
                </div>
            </div>
        </div>
    );
};

export default Answered;