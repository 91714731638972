import { Text } from "../../../ui/Text";
import styles from "./DropdownMenu.module.scss";
import P from "prop-types";
import { appConfig } from "../../../../config";
import { NavLink } from "react-router-dom";

const menuOptions = [
    {
        name: "Saúde pra você",
        icon: "icon-user",
        to: "/saude-pra-voce",
    },
    {
        name: "Para empresas",
        icon: "icon-shopping",
        to: "/para-empresas",
    },
    {
        name: "Para Profissionais da Saúde",
        icon: "icon-medical",
        to: "/para-profissionais",
    },
    {
        name: "Acessar aplicativo",
        icon: "icon-platform-access",
        click: () => (window.location.href = appConfig.appUrl),
    },
];

export const DropdownMenu = ({ className }) => (
    <div className={`${styles["dropdown-menu"]} ${className}`}>
        <ul className={`nav ${styles["footer__container-NavLinks"]}`}>
            {menuOptions.map((option, index) => (
                <li className="nav-item" key={index}>
                    <NavLink
                        to={
                            option.to
                                ? option.to
                                : `javascript: ${() => option.click}`
                        }
                        onClick={option?.click}
                    >
                        <Text tag="p" color="secondary" type="title" size="5">
                            {option.name}{" "}
                            <img
                                src={`icons/${option.icon}.png`}
                                alt={option.i}
                            />
                        </Text>
                    </NavLink>
                </li>
            ))}
        </ul>
    </div>
);

DropdownMenu.defaultProps = {
    className: "",
};

DropdownMenu.propTypes = {
    className: P.string,
};
