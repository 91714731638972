import styles from "./FigureImageAlongando.module.scss";
import P from "prop-types";

export const FigureImageAlongando = ({ className }) => (
    <figure className={`${styles["figure__image-capa"]} ${className}`}>
        <img alt="alongando" src="/images/img-alongando.png"></img>
    </figure>
);

FigureImageAlongando.defaultProps = {
    className: "",
};

FigureImageAlongando.propTypes = {
    className: P.string,
};
