import styles from "./SectionDuvidasFrequentes.module.scss";
import { Collapse } from "../../ui/Collapse";
import { Heading } from "../../ui/Heading";
import { Text } from "../../ui/Text";

export const SectionDuvidasFrequentes = () => {
    return (
        <section
            className={`${styles["section-duvidas-frequentes"]} container`}
        >
            <div className={styles["section-duvidas-frequentes__container"]}>
                <div
                    className={`${styles["section-duvidas-frequentes__container__content-collapse"]} col-12`}
                >
                    <Heading tag="h2" color="secondary" className="mb-3">
                        Dúvidas Frequentes
                    </Heading>

                    <Collapse title="Como funciona a assinatura para empresas?">
                        <Text tag="p" color="secondary" size="2" type="text">
                            O Doutor123 está disponível para empresas com
                            assinatura a partir de 2 vidas. A empresa pode optar
                            por contratar apenas a licença base, onde a
                            plataforma é disponibilizada aos colaboradores
                            incluindo funcionalidades como acesso à rede de
                            clínicas e laboratórios com preços acessíveis,
                            análise de sintomas com inteligência artificial,
                            descontos em medicamentos, área saúde e upgrade de
                            pacotes, podendo contratar individualmente serviços
                            de saúde por recorrência, para ele ou até 3
                            dependentes.
                        </Text>

                        <Text tag="p" color="secondary" size="2" type="text">
                            Além da licença, o Doutor123 também oferece opções
                            de intermediação de serviços disponíveis
                            exclusivamente para assinatura da empresa, como
                            benefícios de saúde física, wellness, saúde mental,
                            serviços médicos, seguros e planos de saúde. Quer
                            saber mais e montar seu benefício customizado? Fale
                            com nosso time.
                        </Text>
                    </Collapse>

                    <Collapse title="O produto está disponível para pessoa física?">
                        <Text tag="p" color="secondary" size="2" type="text">
                            Não. O colaborador pode fazer compras e upgrades
                            dentro do aplicativo, porém, o aplicativo só está
                            disponível para pacientes a partir de uma licença
                            básica concedida pela empresa.
                        </Text>
                    </Collapse>

                    <Collapse title="Meu colaborador pode estender o benefício para dependentes?">
                        <Text tag="p" color="secondary" size="2" type="text">
                            Sim. A empresa pode custear pacotes para
                            dependentes. Caso a empresa ofereça licença apenas
                            para o colaborador, ele poderá individualmente
                            contratar pacotes e serviços para ele e/ou até 3
                            dependentes.
                        </Text>
                    </Collapse>

                    <Collapse title="O Doutor123 é uma corretora, operadora, seguradora ou plano de saúde?">
                        <Text tag="p" color="secondary" size="2" type="text">
                            Não. Nenhuma das opções citadas acima. O Doutor123 é
                            uma tecnologia que facilita a contratação e gestão
                            de benefícios de saúde para empresas. Somos um
                            intermediador de serviços, que utiliza tecnologia
                            para oferecer uma experiência incrível para o
                            colaborador e para o RH, com funcionalidades
                            práticas, intuitivas e integradas, facilitando o
                            acesso à serviços e a manipulação de dados.
                        </Text>
                    </Collapse>

                    <Collapse title="Em qual região o Doutor123 atua?">
                        <Text tag="p" color="secondary" size="2" type="text">
                            O Doutor123 atende o Brasil inteiro. Com tecnologia
                            e uma rede de parceiros com mais de 1.200 pontos de
                            atendimento e 4.100 profissionais espalhados pelo
                            país, o benefício atende as necessidades presenciais
                            ou por telemedicina em todo o território nacional.
                        </Text>
                    </Collapse>

                    <Collapse title="É possível distribuir os serviços com a identidade da minha empresa?">
                        <Text tag="p" color="secondary" size="2" type="text">
                            Sim. O Doutor123 oferece soluções white label, onde
                            você oferece a mesma experiência, porém com a
                            identidade visual da sua marca, e também soluções
                            para integração de funcionalidades por API. Nossas
                            parcerias são customizadas, assim desenhamos
                            produtos de acordo com o perfil de cada público,
                            levando em conta a necessidade da sua marca. Quer
                            saber mais? Fale com nosso time
                        </Text>
                    </Collapse>
                </div>

                {/* <figure className={`${styles['section-duvidas-frequentes__container__figure']} col-lg-3`}>
                    <img src="/images/img-medico-faq.png" />
                </figure> */}
            </div>
        </section>
    );
};
