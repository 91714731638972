import React from "react";
import VantagensCard from "./VantagensCard";
import styles from "./SectionVantagens.module.scss";

function SectionVantagens() {
    const cards = [
        {
            id: 1,
            title: "Retenção de talentos",
            description:
                "Ofereça aos seus colaboradores uma experiência completa e abrangente de saúde e adquira uma vantagem competitiva. O resultado? Colaboradores mais felizes, mais saudáveis e mais engajados na missão da sua empresa.",
            image: "/images/img_vantagens_1.png",
        },
        {
            id: 2,
            title: "Produtividade que faz diferença.",
            description:
                "Clareza mental, comunicação, motivação, consistência e confiança. O colaborador saudável, que se sente confortável e consegue equilibrar a rotina de trabalho com a vida alcança novos níveis de produtividade. Absenteísmo, sinistralidade, níveis de stress... é como dizem: menos é mais!",
            image: "/images/img_vantagens_2.png",
        },
        {
            id: 3,
            title: "Transforme sua cultura organizacional",
            description:
                " Crie uma rede de suporte fundada em uma estratégia de saúde corporativa baseada em dados. Análise dados e estatísticas anonimizadas sobre o comportamento da jornada de saúde dos seus colaboradores no nosso dashboard corporativo.",
            image: "/images/img_vantagens_3.png",
        },
    ];

    return (
        <ul className={styles["section-vantagens"]}>
            {cards.map((card) => (
                <VantagensCard
                    key={card.id}
                    title={card.title}
                    description={card.description}
                    image={card.image}
                />
            ))}
        </ul>
    );
}

export default SectionVantagens;
