import P from "prop-types";
import { useState, useEffect } from "react";

export const Layout = ({ children }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => setLoading(false), 350);
    }, []);

    return <>{loading ? <div></div> : <>{children}</>}</>;
};

Layout.propTypes = {
    children: P.any,
};
