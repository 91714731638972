import styles from "./FigureConsultaPresencial.module.scss";

export const FigureConsultaPresencial = () => (
    <figure className={styles["figure__image-capa"]}>
        <img
            alt="colacoradores startup"
            src="/images/img-consulta-presencial.png"
        ></img>
    </figure>
);
