import styles from "./SectionMain.module.scss";

import { useRef } from "react";
import { Heading, Text } from "../../ui";

export const SectionMain = () => {
    const background = `url(${process.env.PUBLIC_URL + "/images/img-capa-rede.png"})`;

    const click = useRef(null);

    return (
        <section className={styles.section_main_rede} id="#">
            <div
                className={styles.section_main_rede__container}
                style={{ backgroundImage: background }}
            >
                <div className={styles.section_main_rede__container__title}>
                    <Heading tag="h2" color="white">
                        Amplie sua presença para oferecer mais
                        <Text tag="span" color="primary" size="0" type="text">
                            <br />
                            {" >>> "}
                        </Text>
                        saúde.
                    </Heading>
                </div>

                <div className={styles.section_main_rede__container__body}>
                    <Text tag="span" color="white" size="1" type="title">
                        Fazer parte da rede credenciada do Doutor123 é fazer
                        parte da transformação e inovação do sistema de saúde
                        brasileiro. Mais tecnologia para um atendimento mais
                        eficiente e humanizado.
                    </Text>
                </div>

                <div
                    ref={click}
                    onClick={() => {
                        window.location = "#article_faca_parte";
                    }}
                    className={styles.section_main_rede__container__footer}
                >
                    <Text tag="span" color="primary" size="2" type="title">
                        Quero fazer parte!
                    </Text>

                    <i>
                        <img src="/icons/icon-arrows-down.png" alt="" />
                    </i>
                </div>
            </div>
        </section>
    );
};
