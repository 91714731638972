import styles from "./FigureIOS.module.scss";

export const FigureIOS = () => (
    <figure className={styles["figure__image-capa"]}>
        <a
            href="https://apps.apple.com/br/app/doutor123-seu-app-de-sa%C3%BAde/id6443489191?l=en"
            target="_blank"
            rel="noreferrer"
        >
            <img alt="colacoradores startup" src="/images/img-ios-2.png"></img>
        </a>
    </figure>
);
