import styles from "./SectionCadastro.module.scss";
import { Text } from "../../ui";

export const SectionCadastro = () => {
    const text = "Olá, pode me ajudar?";
    const url = `https://wa.me/+5511984887198?text=${text}`;

    const redirect = () => {
        window.location.href = url;
    };

    return (
        <div className={styles["section-cadastro__container"]}>
            <a href="#" onClick={redirect}>
                <Text tag="span" size="3" type="title" color="secondary">
                    Sua primeira vez aqui? Fale com a nossa equipe de
                    atendimento para validar o seu acesso.
                </Text>
            </a>
        </div>
    );
};
