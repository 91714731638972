import styles from "./FigureIconSaudeFisica.module.scss";
import P from "prop-types";

import { IconAtividadeFisica } from "../../../icons/IconAtividadeFisica";
import { Text } from "../../Text";

export const FigureIconSaudeFisica = ({ children, className, column }) => (
    <figure
        className={`${styles["figure-icon-saude-fisica"]} ${className} ${styles[ValidColumn(column)]}`}
    >
        <IconAtividadeFisica></IconAtividadeFisica>
        <Text tag="small" type="text" size="2" color="secondary">
            <b>{children}</b>
        </Text>
    </figure>
);

FigureIconSaudeFisica.propTypes = {
    className: P.string,
    children: P.string,
    column: P.bool,
};

FigureIconSaudeFisica.defaultProps = {
    className: "",
    column: true,
};

const ValidColumn = (column) => (column ? "column" : "row");
