import React from "react";
import { Heading, Text } from "../../ui/";
import { IconArrowsDown } from "../../icons/IconArrowsDown";

import styles from "./SectionMainEmpresas.module.scss";

const CabeNoBolso = (props) => {
    return (
        <section className={styles["section-main-empresas__action"]}>
            <p>E cabe no bolso!</p>
            <button onClick={props.simulate}>
                Simular pacote
                <span>
                    <IconArrowsDown></IconArrowsDown>
                </span>
            </button>
        </section>
    );
};

function SectionMainEmpresas(props) {
    return (
        <>
            <section className={styles["section-main-empresas"]}>
                <div className={styles["section-main-empresas__container"]}>
                    <Heading tag="h2" color="secondary">
                        Eleve a performance da sua empresa com{" "}
                        <span>&gt;&gt;&gt;</span> saúde
                    </Heading>

                    <Text type="text" size="4">
                        Desenvolva estratégias de saúde combinando acesso a
                        serviços de bem-estar com análise de dados para se
                        conectar com seus colaboradores e valorizar o seu ativo
                        mais precioso: <b>as pessoas</b>.
                    </Text>

                    <CabeNoBolso simulate={props.simulate} />
                </div>

                <div className={styles["section-main-empresas__figure"]}>
                    <CabeNoBolso simulate={props.simulate} />
                </div>
            </section>
        </>
    );
}

export default SectionMainEmpresas;
