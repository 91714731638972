import styles from "./SectionPacotes.module.scss";
import { Heading } from "../../ui/Heading";
import { Text } from "../../ui/Text";
import { data } from "./data";
import { useState } from "react";
import { Button } from "../../ui/Button";
import { ModalRecompensaEmpresa } from "./ModalRecompensaEmpresa";
import { PacoteCard } from "./PacoteCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper";

export const SectionPacotes = () => {
    const [isAnual, setIsAnual] = useState(false);
    const [showModal, setShowModal] = useState(false);

    return (
        <section className={`${styles["section-pacotes"]} container`}>
            <div>
                <Heading tag="h3" color="secondary">
                    Pacotes para assinatura dentro do aplicativo para cuidar de
                    você e de quem você ama
                </Heading>
                <Heading tag="h5" color="secondary">
                    Selecione um plano:
                </Heading>
                <Text
                    color="secondary"
                    tag="p"
                    type="text"
                    size="1"
                    className={styles["select-plan-text"]}
                >
                    Escolha o pacote Doutor123 que mais se adequa às suas
                    necessidades. Você pode contratar até licenças ao seu plano.
                    😉
                </Text>
            </div>
            <div className={styles["select-plan"]}>
                <div className={styles["select-plan-switch"]}>
                    <Text
                        color="secondary"
                        tag="p"
                        type="text"
                        size="1"
                        className={`${styles["anual-plan-text"]} ${!isAnual ? styles["selected"] : ""}`}
                    >
                        Anual
                    </Text>
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            checked={isAnual}
                            onChange={({ target: { checked } }) =>
                                setIsAnual(checked)
                            }
                        />
                    </div>
                    <Text
                        color="secondary"
                        tag="p"
                        type="text"
                        size="1"
                        className={`${styles["semester-plan-text"]} ${isAnual ? styles["selected"] : ""}`}
                    >
                        Semestral
                    </Text>
                </div>
            </div>
            <div className={styles["plans-cards"]}>
                <Swiper
                    spaceBetween={8}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }}
                    // modules={[Autoplay]}
                    breakpoints={{
                        767.98: {
                            slidesPerView: 2,
                            spaceBetween: 8,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 8,
                        },
                        1440: {
                            slidesPerView: 4,
                            spaceBetween: 8,
                        },
                    }}
                >
                    {data.map((plan, index) => (
                        <SwiperSlide key={index}>
                            <PacoteCard
                                {...plan}
                                isAnual={isAnual}
                            ></PacoteCard>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className={styles["action-buttons"]}>
                <Button
                    onClick={() =>
                        document
                            .getElementById("comece-jornada")
                            .scrollIntoView()
                    }
                    color="secondary-purple"
                    className={`${styles["acess-app-button"]}`}
                    small={false}
                    text="Baixe o app e saiba mais"
                ></Button>
                <Button
                    onClick={() => setShowModal(true)}
                    color="purple"
                    className={`${styles["acess-app-button"]}`}
                    small={false}
                    text="Quero recomendar para a minha empresa"
                ></Button>
            </div>
            {showModal && (
                <ModalRecompensaEmpresa
                    onClose={() => setShowModal(false)}
                ></ModalRecompensaEmpresa>
            )}
        </section>
    );
};
