import styles from "./SectionMain.module.scss";

import { Heading } from "../../ui/Heading";
import { FigureDownload } from "../../ui/Figures/FigureDownload";
import { Text } from "../../ui/Text";
import { FigureImageCapa } from "./FigureImageCapa";
import { FigureImageAlongando } from "./FigureImageAlongando";
import { appConfig } from "../../../config";

export const SectionMain = () => (
    <section className={`${styles["section-main"]} container-fluid`}>
        <div className={styles["section-section-main__container"]}>
            <Heading
                tag="h2"
                color="secondary"
                className={styles["section-main__container_content--heanding"]}
            >
                Você no controle
                <br />
                da sua <span>{" >>> "}</span> saúde.
            </Heading>
            <div className={styles["store-links"]}>
                <Text tag="span" color="secondary" type="title" size="1">
                    Como, quando e onde precisar. Mais do que um app, uma nova
                    forma de se relacionar com profissionais de saúde.
                </Text>
                <FigureDownload></FigureDownload>
            </div>
            <Text
                tag="small"
                color="secondary"
                type="title"
                size="1"
                className={styles["login-text"]}
            >
                Já é nosso cliente?
                <a href={appConfig.appUrl}>
                    <Text tag="small" color="secondary" type="title" size="1">
                        Faça login.
                    </Text>
                </a>
            </Text>
        </div>
        <div className={styles["section-main__container_content--figure"]}>
            <img
                className={styles["img-mains-logos"]}
                src="/images/saude-pra-voce/img-seciton-main-logos.png"
                alt=""
            />
            <FigureImageCapa></FigureImageCapa>
            <FigureImageAlongando
                className={styles["img-alongando"]}
            ></FigureImageAlongando>
        </div>
    </section>
);
