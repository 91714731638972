import styles from "./FigureImagePrintCelular.module.scss";

export const FigureImagePrintCelular = () => (
    <figure className={styles["figure-image-print-celular"]}>
        <img
            alt="aplication doutor123"
            src="/images/img-print-celular-app.png"
        ></img>
    </figure>
);
