import styles from "./FigureSocialMedia.module.scss";
import P from "prop-types";

export const FigureSocialMedia = ({ socialMedia }) => {
    return (
        <figure className={`${styles["figure-download"]}`}>
            <img src={`/images/img-${socialMedia}.png`}></img>
        </figure>
    );
};

FigureSocialMedia.propTypes = {
    socialMedia: P.string,
};

FigureSocialMedia.defaultProps = {
    socialMedia: "instagram",
};
