import styles from "./SectionComeceJornada.module.scss";
import { Heading } from "../../ui/Heading";
import { FigureAndroid } from "./FigureAndroid";
import { FigureIOS } from "./FigureIOS";
import { FigureColaboradoresStartup } from "./FigureColaboradoresStartup";

export const SectionComeceJornada = () => (
    <section className={`${styles["section-comece-jornada"]} container`}>
        <FigureColaboradoresStartup />

        <div id="comece-jornada">
            <Heading tag="h2" color="secondary">
                Venha começar a sua nova jornada de saúde!
            </Heading>
        </div>

        <Heading tag="h3" color="secondary">
            A vida pede equilíbrio.
        </Heading>

        <div className={styles["action-buttons"]}>
            <FigureAndroid></FigureAndroid>
            <FigureIOS></FigureIOS>
        </div>
    </section>
);
