import styles from "./TextArea.module.scss";
import P from "prop-types";

export const TextArea = ({
    id,
    name,
    onChange,
    onBlur,
    className,
    isValid,
    isInvalid,
}) => {
    const valid = isValid ? styles["is-valid"] : "";
    const invalid = isInvalid ? styles["is-invalid"] : "";

    const defaultClassName = [
        "form-control",
        className,
        styles.textarea,
        valid,
        invalid,
    ].join(" ");

    return (
        <textarea
            className={defaultClassName}
            name={name}
            id={id}
            rows="3"
            cols="500"
            onChange={onChange}
            onBlur={onBlur}
        />
    );
};

TextArea.defaultProps = {
    className: "",
};

TextArea.propTypes = {
    id: P.string.isRequired,
    name: P.string.isRequired,
    className: P.string,
    onChange: P.func,
    onBlur: P.func,
    isValid: P.bool,
    isInvalid: P.bool,
};
