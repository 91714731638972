import React from 'react';

import styles from './Successful.module.scss';
import { Button, Logo, Text } from '../../ui';
import { appConfig } from '../../../config';

const Successful = () => {

    const stylesScss = [
        styles.container,
        "container"
    ].join(' ');
    
    return (
        <div className={stylesScss}>
            <div className={styles.header}>
                <Logo 
                    className={styles.header__logo} 
                    purple 
                />
            </div>

            <div className={styles.content}>

                <figure>
                    <img src="/images/img-resultados.png" alt="Image" />
                </figure>

                <Text tag='h2'>
                    Obrigado pela sua participação, sua opinião é muito importante para nós!
                </Text>

                <div className={styles.content__button}>
                    <Button
                        onClick={() => (window.location.href = appConfig.appUrl)}
                        small={true}
                        color="secondary-purple"
                        text="Acessar aplicativo"
                    />
                </div>
            </div>
        </div>
    );
};

export default Successful;