import styles from "./Button.module.scss";
import P from "prop-types";

export const Button = ({
    color,
    small,
    text,
    disabled,
    onClick,
    type,
    className,
    loading
}) => (
    <div
        className={`${styles["content_button"]} ${small ? styles["button-sm"] : styles["button-100"]} `}
    >
        <button
            className={`${setClassName(color, className, loading).toString()}`}
            type={type}
            disabled={disabled}
            onClick={onClick}
        >
            {(loading) ? 
                <span class="spinner-border" role="status"/>
                : 
                <>{text}</>
            }
        </button>
    </div>
);
const setClassName = (color, className, loading) =>
    new String(`${styles[`${color}`]} ${className} ${loading ? styles.loading : ''} `);

Button.defaultProps = {
    disabled: false,
    color: P.oneOf([
        "default",
        "primary",
        "secondary",
        "purple",
        "secondary-purple",
    ]),
    type: "button",
    small: true,
    className: "",
    loading: false
};

Button.propTypes = {
    text: P.oneOfType([P.string.isRequired, P.element]),
    type: P.string.isRequired,
    onClick: P.func,
    className: P.string,
    color: P.string,
    small: P.bool,
    disabled: P.bool,
    loading: P.bool
};
