export const benefitList = [
    {
        text: (
            <>
                Cobertura em <span>+ de 100</span> cidades e 25 regiões
                metropolitanas.
            </>
        ),
        theme: "secondary",
    },
    {
        text: (
            <>
                <span>+ de 4.100</span> profissionais de saúde cadastrados.
            </>
        ),
        theme: "primary",
    },
    {
        text: (
            <>
                <span>+ de 3.000</span> tipos de exames em + de 1.200 pontos de
                atendimento.
            </>
        ),
        theme: "secondary",
    },
    {
        text: (
            <>
                Agendamentos de consultas presencial ou por vídeo
                <br />
                cobrindo <span>+ de 70</span> especialidades
            </>
        ),
        theme: "primary",
    },
];
