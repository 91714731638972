import styles from "./FigureAndroid.module.scss";

export const FigureAndroid = () => (
    <figure className={styles["figure__image-capa"]}>
        <a
            href="https://play.google.com/store/apps/details?id=br.com.flutter.doutor123"
            target="_blank"
            rel="noreferrer"
        >
            <img
                alt="colacoradores startup"
                src="/images/img-android-2.png"
            ></img>
        </a>
    </figure>
);
